export const workerConstants = {
	GET_WORKERS_BY_PIECEWORK: 'GET_WORKERS_BY_PIECEWORK',
	RECIEVE_WORKERS_BY_PIECEWORK: 'RECIEVE_WORKERS_BY_PIECEWORK',

	EDIT_WORKER_MODAL: 'EDIT_WORKER_MODAL',
	ADD_WORKERS_TO_PIECEWORK_MODAL: 'ADD_WORKERS_TO_PIECEWORK_MODAL',
	CREATE_PIECEWORK_WORKER: 'CREATE_PIECEWORK_WORKER',
	TOGGLE_ACTIVE_WORKER: 'TOOGLE_ACTIVE_WORKER',

	FILTER_WORKER_LIST: 'FILTER_WORKER_LIST',
	FILTER_IS_BORROWED: 'FILTER_IS_BORROWED',
	FILTER_SKILL_LEVEL: 'FILTER_SKILL_LEVEL',

	SET_SORT_ORDER: 'SET_SORT_ORDER',

	RESET_FILTERS: 'RESET_FILTERS',

	GET_MY_WORKERS: 'GET_MY_WORKERS',
	SAVE_WORKER: 'SAVE_WORKER',
	SAVED_WORKER: 'SAVED_WORKER',

	ADD_WORKER_TO_PIECEWORK: 'ADD_WORKER_TO_PIECEWORK',
	ADDED_WORKER_TO_PIECEWORK: 'ADDED_WORKER_TO_PIECEWORK',

	ON_WORKER_ERROR: 'ON_WORKER_ERROR'
};

import { pieceWorkConstants } from 'store/constants/PieceWorkConstants';
import { snackbarConstants } from 'store/constants/SnackbarConstants';

import { pieceWorkService } from 'store/services/PieceWorkService';
import { calculationService } from 'store/services/CalculationService';
import { commonService } from 'store/services/CommonService';
import { TimeRegistrationActions } from 'store/actions/TimeRegistrationActions';

export const pieceWorkActionCreators = {
	requestPieceWorkList: () => async (dispatch, getState) => {
		var pieceworks = [];
		dispatch({ type: pieceWorkConstants.REQUESSTPIECEWORKLIST });
		await pieceWorkService.getPieceWorkCompletedList().then(
			(response) => {
				response.data.forEach((d) => pieceworks.push(d))
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'FULLFØRTE AKKORDUNDERLAG', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
		dispatch({ type: pieceWorkConstants.REQUEST_MY_PIECEWORKLIST });
		await pieceWorkService.getMyPieceWorkList().then(
			(response) => {
				response.data.forEach((d) => pieceworks.push(d))
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Mina Akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
		dispatch({ type: pieceWorkConstants.RECIEVEPIECEWORKLIST, pieceworks });
	},
	requestMyPieceWorkList: () => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.REQUEST_MY_PIECEWORKLIST });
		pieceWorkService.getMyPieceWorkList().then(
			(response) => {
				let pieceworks = response.data;
				dispatch({ type: pieceWorkConstants.RECIEVE_MY_PIECEWORKLIST, pieceworks });
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Mina Akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	getMyPieceWorksWithPricelists: () => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.REQUEST_MY_PIECEWORKS_WITH_PRICELISTS });
		pieceWorkService.getMyPieceWorksWithPricelists().then(
			(response) => {
				let data = response.data;
				dispatch({ type: pieceWorkConstants.RECIEVE_MY_PIECEWORKS_WITH_PRICELISTS, payload: data });
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Mina Akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	requestPieceWork: (workId) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.REQUESTPIECEWORK, workId });
		pieceWorkService.getPieceWork(workId).then(
			(response) => {
				// if (piecework.status && piecework.status === 204) piecework = 0;
				let piecework = response.data[0];
				dispatch({ type: pieceWorkConstants.RECIEVEPIECEWORK, piecework });
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	savePieceWork: (pieceWork, files, filesToDelete = []) => async (dispatch, getState, { history }) => {

		dispatch({ type: pieceWorkConstants.SAVEPIECEWORK });
		let id = 0;
		try {
			id = await pieceWorkService.savePieceWork(pieceWork);
			id = id[0];
			dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag ble lagret' } });
		}
		catch (error) {
			commonService.handleResponseError(dispatch, error, 'Lagre akkordunderlag', {
				type: pieceWorkConstants.ON_PIECEWORK_ERROR
			});
		}

		for (const fileId of filesToDelete) {
			try {
				await pieceWorkService.deletePieceWorkFile(pieceWork.id, fileId);
				dispatch({ type: pieceWorkConstants.REMOVE_PIECEWORK_FILE, payload: fileId });
				dispatch({
					type: snackbarConstants.ALERT_INFO,
					payload: { message: 'Filen ble slettet' }
				});
			} catch (error) {
				commonService.handleResponseError(dispatch, error, 'Slette fil', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
			}
		};


		if (files && files.length > 0) {
			// files.forEach((file) => {
			for (const file of files) {
				try {
					await pieceWorkService.saveFileToPieceWork(id, file);
					dispatch({
						type: snackbarConstants.ALERT_INFO,
						payload: { message: 'Filen ble lagret' }
					});
				}
				catch (error) {
					commonService.handleResponseError(dispatch, error, 'Lagre fil', {
						type: pieceWorkConstants.ON_PIECEWORK_ERROR
					});
				}
			};
		}

		!pieceWork.id ? dispatch(pieceWorkActionCreators.requestMyPieceWorkList())
			: dispatch(pieceWorkActionCreators.requestPieceWork(id))

		dispatch({ type: pieceWorkConstants.SHOW_EDIT_PIECEWORK_MODAL });
	},
	showEditPieceWorkModal: (pieceWork) => ({ type: pieceWorkConstants.SHOW_EDIT_PIECEWORK_MODAL, pieceWork }),
	showShareModal: () => ({ type: pieceWorkConstants.SHOW_SHARE_MODAL }),
	newPieceWorkModal: () => ({ type: pieceWorkConstants.NEWPIECEWORKMODAL }),
	editPieceWorkNoteModal: (pieceWork) => ({ type: pieceWorkConstants.EDITPIECEWORKNOTEMODAL, pieceWork }),
	savePieceWorkNote: (pieceWork) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.SAVEPIECEWORK });
		pieceWorkService.savePieceWork(pieceWork).then(
			(response) => {
				let pieceworkServer = response[0];
				let pieceworkVersion = response[1];

				if (pieceworkVersion === 1)  {
					dispatch({ type: snackbarConstants.ALERT_INFO, payload: { 
						message: "Akkordtariffen ble endret i tariffoppgjøret i 2022. Disse tariffmessige endringene vil ikke føre til endringer i regnestykket, men endringene fører til at forutsetningene i akkordunderlag før 1. mai 2022 og etter 1. mai 2022 endres. \n\nSe utfyllende beskrivelse av endringene i arkivet." 
					} });
				};
				dispatch({ type: pieceWorkConstants.SAVEDPIECEWORK, pieceworkServer });
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag ble lagret' } });
				
				dispatch(pieceWorkActionCreators.requestPieceWork(pieceworkServer));
				dispatch({ type: pieceWorkConstants.EDITPIECEWORKNOTEMODAL });

				//Sync weeklist.
				dispatch(TimeRegistrationActions.getWeeks(pieceWork.startDate, pieceWork.endDate, pieceWork.id));
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Lagre akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		).finally(() => {

		});
	},

	savePieceWorkSalaryDate: (workId, salaryDate) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.SAVEPIECEWORK });
		pieceWorkService.setPieceWorkSalaryPayment(workId, salaryDate).then(
			(response) => {
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag ble lagret' } });
				dispatch(pieceWorkActionCreators.requestPieceWork(workId));
				dispatch({ type: pieceWorkConstants.EDITPIECEWORKNOTEMODAL });
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Lagre akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		).finally(() => {

		});
	},
	getPieceWorkResponsiblesByPieceWork: (workId) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.REQUESTPIECEWORKRESPONSIBLES, workId });
		pieceWorkService.getPieceWorkResponsibles(workId).then(
			(response) => {
				let pieceWorkResponsibles = (response.status && response.status === 204) ? [] : response.data;
				dispatch({
					type: pieceWorkConstants.RECIEVEPIECEWORKRESPONSIBLES,
					pieceWorkResponsibles: pieceWorkResponsibles.filter(responsible => !responsible.isShared)
				});
				dispatch({
					type: pieceWorkConstants.SET_SHARED_TO,
					payload: pieceWorkResponsibles
						.filter(responsible => responsible.isShared)
						.map(responsible => Object.assign({}, responsible, { id: responsible.userId }))
				});
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Akkordtaker', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	toggleEditPieceWorkResponsiblesModal: (pieceWorkResponsibles) => ({
		type: pieceWorkConstants.EDITPIECEWORKRESPONSIBLESMODAL,
		pieceWorkResponsibles
	}),
	savePieceWorkResponsibles: (workId, pieceWorkResponsibles) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.SAVEPIECEWORKRESPONSIBLES });
		pieceWorkService.savePieceWorkResponsibles(workId, pieceWorkResponsibles).then(
			() => {
				dispatch({ type: pieceWorkConstants.SAVEDPIECEWORKRESPONSIBLES });
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordtaker blev lagret' } });

				dispatch({ type: pieceWorkConstants.EDITPIECEWORKRESPONSIBLESMODAL });
				dispatch(pieceWorkActionCreators.getPieceWorkResponsiblesByPieceWork(workId));
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Lagre akkordtaker', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	removePieceWorkResponsibles: (workId, userId) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.REMOVE_PIECEWORK_RESPONSIBLES });
		pieceWorkService.removePieceWorkResponsibles(workId, userId).then(
			(response) => {
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordtaker slettet' } });
				dispatch(pieceWorkActionCreators.getPieceWorkResponsiblesByPieceWork(workId));
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Slett akkordtaker', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	saveContactPerson: (workId, pieceWorkResponsible) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.SAVECONTACTPERSON });
		pieceWorkService.saveContactPerson(pieceWorkResponsible).then(
			(response) => {
				dispatch({ type: pieceWorkConstants.SAVEDCONTACTPERSON });
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Kontaktperson blev lagret' } });
				dispatch(pieceWorkActionCreators.getPieceWorkResponsiblesByPieceWork(workId));
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Lagre kontaktperson', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	getPieceWorkFile: async (workId, fileId) => {
		return await pieceWorkService.getPieceWorkFile(workId, fileId);
	},
	deletePieceWork: (workId) => async (dispatch, getState, browserHistory) => {
		pieceWorkService.deletePieceWork(workId).then(
			(response) => {
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag slettet' } });
				dispatch(pieceWorkActionCreators.requestMyPieceWorkList());
				browserHistory.history.push('/akkordunderlag');
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Slett akkordunderlag', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	setPieceWorkShared: (workId, users, addUsers, removeUsers, addFederationRole, removeFederationRole) => async (dispatch, getState) => {
		try {
			let didUpdate = false;
			if (removeUsers.length > 0) {
				await pieceWorkService.removeSharePieceWork(workId, removeUsers);
				didUpdate = true;
			}
			if (addUsers.length > 0) {
				await pieceWorkService.sharePieceWork(workId, addUsers);
				didUpdate = true;
			}
			if (addFederationRole) {
				await pieceWorkService.toggleShareFederationPieceWork(workId, false);
				didUpdate = true;
			}
			if (didUpdate) {
				dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag lagret' } });
				let pieceWorkObj = await pieceWorkService.getPieceWork(workId);
				dispatch({ type: pieceWorkConstants.RECIEVEPIECEWORK, piecework: pieceWorkObj.data[0] });
				dispatch({ type: pieceWorkConstants.SET_SHARED_TO, payload: users });
			}
		}
		catch (e) {
			commonService.handleResponseError(dispatch, e, 'Del akkord', {
				type: pieceWorkConstants.ON_PIECEWORK_ERROR
			})
		}
	},
	setPieceWorkFederationShared: (workId, shareToFederation = false) => async (dispatch, getState) => {
		try {
			await pieceWorkService.toggleShareFederationPieceWork(workId, shareToFederation);
			dispatch({ type: pieceWorkConstants.TOGGLE_SHARED_TO_FEDERATION_ROLE });
			dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Akkordunderlag lagret' } });
			let pieceWorkObj = await pieceWorkService.getPieceWork(workId);
			dispatch({ type: pieceWorkConstants.RECIEVEPIECEWORK, piecework: pieceWorkObj.data[0] });
		}
		catch (e) {
			commonService.handleResponseError(dispatch, e, 'Del akkord', {
				type: pieceWorkConstants.ON_PIECEWORK_ERROR
			})
		}

	},
	getPieceWorkMetaData: (pieceWorkId) => async (dispatch, getState) => {
		pieceWorkService.getPieceWorkMetaData(pieceWorkId).then(
			(response) => {
				// let metadata = convertMetaDataFields(response.data[0]);
				dispatch({ type: pieceWorkConstants.SET_META_DATA_SELECTS, payload: response.data[0] });
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Metadata', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	getPieceWorkCalculation: (workId) => async (dispatch, getState) => {
		dispatch({ type: pieceWorkConstants.GET_PIECEWORK_CALCULATION });
		calculationService.getCalculationByPieceWorkId(workId).then(
			(response) => {
				let data = response.data[0];
				dispatch({ type: pieceWorkConstants.SET_PIECEWORK_CALCULATION, payload: data });
				if (data.groupedRows)
					dispatch({ type: pieceWorkConstants.SET_PIECEWORK_CALCULATION_ROWS, payload: data.groupedRows });

				// Get piecework to update piecework header values
				dispatch(pieceWorkActionCreators.requestPieceWork(workId));
			},
			(error) =>
				commonService.handleResponseError(dispatch, error, 'Fordeling', {
					type: pieceWorkConstants.ON_PIECEWORK_ERROR
				})
		);
	},
	getPieceWorkTimeSummaryFor: async (workId, workerIds) => {
		return pieceWorkService.getPieceWorkTimeSummaryFor(workId, workerIds);
	},
};

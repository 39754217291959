import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { reducer as reduxFormReducer } from "redux-form";

import * as PieceWork from "store/reducers/PieceWorkReducer";
import * as Worker from "store/reducers/WorkerReducer";

import * as UserInfo from "store/reducers/UserReducer";
import * as AuthUser from "store/reducers/AuthReducer";
import * as Snackbar from "store/reducers/SnackbarReducer";
import * as PriceList from "store/reducers/PriceListReducer";
import * as Tariff from "store/reducers/TariffReducer";
import * as Time from "store/reducers/TimeRegistrationReducer";
import * as Agreements from "store/reducers/AgreementsReducer";
import * as Requisitions from "store/reducers/RequisitionsReducer";
import * as WorkerRegistry from "store/reducers/WorkerRegistryReducer";

export default function configureStore(history, initialState) {
  const reducers = {
    form: reduxFormReducer,
    PieceWorkState: PieceWork.reducer,
    PriceListState: PriceList.PriceListReducer,
    WorkerState: Worker.reducer,
    TimeRegistrationState: Time.TimeRegistrationReducer,
    AgreementsState: Agreements.reducer,
    RequisitionsState: Requisitions.reducer,
    TariffState: Tariff.reducer,
    WorkerRegistryState: WorkerRegistry.reducer,
    User: UserInfo.reducer,
    AuthUser: AuthUser.AuthReducer,
    snackbar: Snackbar.snackbarReducer,
  };

  const middleware = [
    thunk.withExtraArgument({ history }),
    routerMiddleware(history),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const rootReducer = combineReducers({
    ...reducers,
    //routing: routerReducer
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';

import RouteResponsible from 'routes/RouteResponsible';
//import DefaultNoRoutes from 'routes/DefaultNoRoutes';

import RouteFederation from 'routes/RouteFederation';
import RouteClubDistrict from 'routes/RouteClubDistrict';
import RouteAdmin from 'routes/RouteAdmin';
import RouteEmployer from 'routes/RouteEmployer';

import { roles } from 'api/User/Roles';

const App = (props) => {
  const { user } = props;
  const [userRole, setUserRole] = useState(user.role);
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRoleCb = useCallback(setUserRole, []);
  useEffect(() => {
    if (typeof userRole !== 'undefined') setRoleCb(user.role);
  }, [user, setRoleCb, userRole]);

  

  // eslint-disable-next-line no-sparse-arrays
  const GA_analytics_cookie = (document.cookie.match(/^(?:.*;)?\s*akkord_consent\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1];
    if (GA_analytics_cookie === 'all') {
        const hostName = window && window.location && window.location.hostname;
        let googleAnalytics = 'UA-150367088-1';
        if (hostName === 'akkord-no-frontend-systest.azurewebsites.net') {
            googleAnalytics = 'UA-150367088-2';
        }
        ReactGA.initialize(googleAnalytics);
        ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const renderRoutes = () => {
    if (typeof userRole !== 'undefined') {
      if (userRole.includes(roles.FederationResponsible)) {
        return <RouteFederation />;
      }

      if (userRole.includes(roles.Admin)) {
        return <RouteAdmin />;
      }

      if (
        userRole.includes(roles.ClubResponsible) ||
        userRole.includes(roles.DistrictResponsible)
      ) {
        return <RouteClubDistrict />;
      }

      if (userRole.includes(roles.PieceWorkResponsible)) {
        return <RouteResponsible />;
      }

      if (userRole.includes(roles.Employer)) {
        return <RouteEmployer />;
      }
    }
  };

  return <>{renderRoutes()}</>;
};

export default App;


import { snackbarConstants } from 'store/constants/SnackbarConstants';
import FileTypeHelper from 'lib/helpers/FileTypeHelper';

export const commonService = {
	logoutUser,
	handleResponse,
	handleResponseError
};

function logoutUser() {
	//history.push('/logout');
}

function handleResponseError(dispatch, error, api = '', dispatchAction = null) {
	dispatch({
		type: snackbarConstants.ALERT_ERROR,
		payload: { message: api + '\n' + error }
	});
	if (dispatchAction !== null) dispatch(dispatchAction);
}

function resolveError(response) {
	return response.text()
		.then(text => {
			// { status: response.status, text: text }
			return Promise.reject(text)
		})
}


function resolveForbidden(response) {
	return response.text()
		.then(text => {
			// { status: response.status, text: text }
			return Promise.reject(text)
		})
}

function resolveNotAuthorize(response) {
	return response.text()
		.then(text => {
			// { status: response.status, text: text }
			logoutUser();
			return Promise.reject(text)
		})
}

function handleResponse(responseObject) {
	let response = null;
	response = responseObject;
	const contentType = response.headers.get('content-type');

	if (!response.ok) {
		if (response.status === 401) {
			//logoutUser();
			return resolveNotAuthorize(response);
		}

		if (response.status === 403) {
			return resolveForbidden(response);
		}

		if (response.status < 200 || response.status >= 300) {
			// const error = (response && response.message) || response.statusText;
			// return Promise.reject(error);
			return resolveError(response);
		}

	}
	if (response.status === 204) return response;

	if (contentType && contentType.indexOf(FileTypeHelper.json) !== -1) {
		return response.json();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.pdf) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.docx) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.xlsx) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.doc) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.xls) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.octetStream) !== -1) {
		return response.blob();
	}
	if (contentType && contentType.indexOf(FileTypeHelper.img) !== -1) {
		return response.blob();
	}
	else {
		return response.text();
	}
}

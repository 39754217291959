export const agreementsConstants = {
    GET_AGREEMENTS: 'GET_AGREEMENTS',
    SET_AGREEMENTS: 'SET_AGREEMENTS',

    SHOW_EDIT_AGREED_EXTENSIONS_MODAL: 'SHOW_EDIT_AGREED_EXTENSIONS_MODAL',
    SHOW_EDIT_HOURLY_RATE_MODAL: 'SHOW_EDIT_HOURLY_RATE_MODAL',
    SHOW_EDIT_LOCAL_ADVANCE_MODAL: 'SHOW_EDIT_LOCAL_ADVANCE_MODAL',

    SHOW_EDIT_OTHER_AGREEMENT_MODAL: 'SHOW_EDIT_OTHER_AGREEMENT_MODAL',
    NEW_OTHER_LOCAL_AGREEMENT: 'NEW_LOCAL_OTHER_AGREEMENT',
    GET_OTHER_AGREEMENTS: 'GET_OTHER_AGREEMENTS',
    SET_OTHER_AGREEMENTS: 'SET_OTHER_AGREEMENTS',
    LOADING_OTHER_AGREEMENTS_FILES: 'LOADING_OTHER_AGREEMENTS_FILES',
    LOADED_OTHER_AGREEMENTS_FILES: 'LOADED_OTHER_AGREEMENTS_FILES',

    SET_LOCAL_ADVANCES: 'SET_LOCAL_ADVANCES',
    SET_LOCAL_ADVANCE: 'SET_LOCAL_ADVANCE',

    GET_AGREED_EXTENSIONS: 'GET_AGREED_EXTENSIONS',
    SET_AGREED_EXTENSIONS: 'SET_AGREED_EXTENSIONS',

    GET_HOURLY_RATE: 'GET_HOURLY_RATE',
    SET_HOURLY_RATE: 'SET_HOURLY_RATE',

    GETA0507_LIST: 'GETA0507_LIST',
    SETA0507_LIST: 'SETA0507_LIST',
    SET_ARCHIVE_A0507_LIST: 'SET_ARCHIVE_A0507_LIST',
    SET_FILTERA0507_SELECT_LIST: 'SET_FILTERA0507_SELECT_LIST',
    SET_FILTER_PRICEROWS: 'SET_FILTER_PRICEROWS',
    SET_FILTERA0507_TYPE: 'SET_FILTERA0507_TYPE',
    SET_FILTER_0507_ADDONS: 'SET_FILTER_0507_ADDONS',
    GETA0507_ROWS: 'GETA0507_ROWS',
    SETA0507_ROWS: 'SETA0507_ROWS',
    SHOW_EDITA0507_ROWS_MODAL: 'SHOW_EDITA0507_ROWS_MODAL',
    SHOW_ADDA0507_FROM_ARCHIVE_MODAL: 'SHOW_ADDA0507_FROM_ARCHIVE_MODAL',
    SETA0507_ROW: 'SETA0507_ROW',
    ADDA0507_ROW_TO_LIST: 'ADDA0507_ROW_TO_LIST',
    UPDATEA0507_ROW_TO_LIST: 'UPDATEA0507_ROW_TO_LIST',

    SETA0507_META_DATA: 'SETA0507_META_DATA',
    SETA0507_META_DATA_SELECTS: 'SETA0507_META_DATA_SELECTS',
    SET_WEEKS: 'SET_WEEKS',
    GETA0507_AGREEMENT: 'GETA0507_AGREEMENT',
    SETA0507_AGREEMENT: 'SETA0507_AGREEMENT',

    GET_GROUPS_FOR_AGREEMENTS: 'GET_GROUPS_FOR_AGREEMENTS',
    SET_GROUPS_FOR_AGREEMENTS: 'SET_GROUPS_FOR_AGREEMENTS',
    SET_05_GROUPS_FOR_AGREEMENTS: 'SET_05_GROUPS_FOR_AGREEMENTS',
    SET_07_GROUPS_FOR_AGREEMENTS: 'SET_07_GROUPS_FOR_AGREEMENTS',

    SETTING_AGREEMENT_STATUS: 'SETTING_AGREEMENT_STATUS',
    SET_AGREEMENT_STATUS: 'SET_AGREEMENT_STATUS',

    SET_IS_LOADING: 'SET_IS_LOADING',

    SHOW_EDITA0507_AGREEMENT_MODAL: 'SHOW_EDITA0507_AGREEMENT_MODAL',

    ON_AGREEMENTS_ERROR: 'ON_AGREEMENTS_ERROR',



}

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';

import DragIcon from '@material-ui/icons/DragIndicator';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Container from 'components/layout/Container';

import CheckList from 'components/pieceWork/checkList/components/CheckList';
import FormList from 'components/pieceWork/checkList/components/FormList';
import { pieceWorkConstants } from 'store/constants/PieceWorkConstants';
import { pieceWorkService } from 'store/services/PieceWorkService';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    checkListContainer: {
        position: 'absolute',
        zIndex: '5000',
        boxShadow: '2px 2px 3px #999',
        borderRadius: '10px',
        '&:active': {
            cursor: 'grabbing'
        }
    },
    dragIcon: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        }
    },
    collapseExpandIcon: {
        marginLeft: '8px',
        marginRight: '8px',
        cursor: 'pointer'
    },
    checkListHeader: {
        backgroundColor: '#c30e15',
        padding: theme.spacing(1.5),
        display: 'flex',
        color: '#fff',

    },
    tabsRoot: {
        borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(4)
    },
    tabRoot: {
        minWidth: theme.spacing(9),
        padding: '6px 28px',
    },
    container: {
        margin: 0
    },
    infoText: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 4),
        display: 'table-caption'
    },
    tabWrapper: {
        display: 'table'
    },
    setupTab: {
        maxWidth: '276px'
    }
});

const Control = (props) => {
    const {
        classes,
        dispatch,
        checkListDone = false,
        checkListFormDone = false,
        pieceWork,
        workId
    } = props;
    const [collapseList, setCollapseList] = useState(false);
    const draggableComponent = useRef();
    const currentX = useRef(0);
    const currentY = useRef(0);
    const posX = useRef(0);
    const posY = useRef(0);
    const [dragState, setDragState] = useState({
        isDragging: false,
        translateX: 100,
        translateY: 10
    });

    //Checks if 'Kontroll' is complete without having to expand it
    const checkListStatus = useCallback(async () => {
        const list = await pieceWorkService.getPieceWorkCheckList(workId);
        if (!list.data.find((c) => !c.isConfirmed)) {
            dispatch({ type: pieceWorkConstants.CHECKLIST_DONE, payload: true })
        }
        if (pieceWork.setupIsConfirmed) {
            dispatch({ type: pieceWorkConstants.CHECKLIST_FORM_DONE, payload: true })
        }
    }, [dispatch, pieceWork, workId])

    useEffect(() => {
        checkListStatus()
    }, [checkListStatus])

    const [value, setValue] = useState(0);

    const [countCheckList, setCountCheckList] = useState(0);
    const [totalCheckList, setTotalCheckList] = useState(0);

    useEffect(() => {
        currentX.current = 0;
        currentY.current = 0;
    }, [dispatch])

    const handleMouseMove = useCallback(
        (event) => {
            const { pageX, pageY } = event;
            if (dragState.isDragging) {

                setDragState(prevState => ({
                    ...prevState,
                    translateX: pageX - posX.current,
                    translateY: pageY - posY.current
                }));
            }
        },
        [dragState.isDragging]
    );

    const handleMouseUp = useCallback((event) => {
        if (dragState.isDragging) {
            setDragState(prevState => ({
                ...prevState,
                isDragging: false
            }));
        }
    }, [dragState.isDragging]);

    const handleMouseDown = useCallback((event) => {
        const { pageX, pageY } = event;
        let shiftX = pageX - draggableComponent.current.getBoundingClientRect().left;
        let shiftY = pageY - draggableComponent.current.getBoundingClientRect().top;
        posX.current = shiftX;
        posY.current = shiftY;
        setDragState(prevState => ({
            ...prevState,
            isDragging: true
        }));
    }, []);

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleCollapse = () => {
        setCollapseList((value) => {
            return !value;
        })
    }

    const renderTabs = (
        <div style={collapseList ? { display: 'table' } : { display: 'none' }}
            className={classes.tabWrapper}>
            <Typography className={classes.infoText}>
                Før du kan sende inn akkordunderlaget til godkjenning må du markere alt i sjekklista,
                samt fylle inn innholdet i akkordinnleveringen
            </Typography>
            <Tabs className={classes.tabsRoot}
                value={value}
                onChange={handleTabChange}
            >
                <Tab
                    disableRipple
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    label={"Sjekkliste (" + countCheckList + "/" + totalCheckList + ")"}
                />
                <Tab
                    disableRipple
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    label={"Akkordinnlevering (" + (checkListFormDone ? "Klar)" : "Ikke klar)")}
                    className={classes.setupTab}
                />
            </Tabs>
            <Container className={classes.container}>
                <div style={value === 0 ? { display: 'block' } : { display: 'none' }}>
                    {<CheckList
                        setCountCheckList={setCountCheckList}
                        setTotalCheckList={setTotalCheckList} />}
                </div>
                <div style={value === 1 ? { display: 'block' } : { display: 'none' }}>
                    {<FormList tabIndex={value} />}
                </div>
            </Container>
        </div>
    );

    const renderCollapseIcon = collapseList ? (<ExpandLessIcon
        className={classes.collapseExpandIcon}
        onClick={toggleCollapse} />) : (<ExpandMoreIcon
            className={classes.collapseExpandIcon}
            onClick={toggleCollapse} />);

    return (
        <>
            <div
                className={classes.checkListContainer}

                style={{
                    transform: `translate(${dragState.translateX}px, ${dragState.translateY}px)`
                }}>
                <div
                    ref={draggableComponent}
                    className={classes.checkListHeader}>

                    <DragIcon
                        className={classes.dragIcon}
                        onMouseDown={handleMouseDown}
                    />
                    <Typography color="inherit" style={{ fontSize: '1.1rem', fontWeight: 'bold', margin: '0 16px', padding: '0 10px 0 10px' }}>
                        Kontroll
                    </Typography>
                    <Typography color="inherit" style={{ fontSize: '1.1rem', fontWeight: 'bold', marginLeft: 'auto', paddingRight: '16px' }}>
                        {checkListDone && checkListFormDone ? "Klar" : "Ikke klar"}
                    </Typography>
                    {renderCollapseIcon}
                </div>
                {renderTabs}

            </div >
        </>
    )
}

function mapStateToProps(state) {
    const { workId, isLoading, pieceWork, checkListDone = false, checkListFormDone = false } = state.PieceWorkState;
    return {
        isLoading,
        pieceWork,
        workId,
        checkListDone,
        checkListFormDone
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Control));




import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import withRouter from '../../routes/withRouter'
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { userConstants } from 'store/constants/UserConstants';

import { routes, formatBreadcrumb, breadcrumbTypes } from 'components/breadcrumbs/breadcrumbHelper';

const styles = (theme) => ({
    breadcrumbWrapper: {
        fontSize: theme.sizes.text.xs,
        fontWeight: 600,
    },
    breadcrumb: {
        color: theme.colors.primary,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbDivider: {
        margin: theme.spacing(0, 0.5),
    },
    breadcrumbActive: {
        color: theme.colors.text.lightGrey,
        fontSize: theme.sizes.text.xs,
        fontWeight: 600,
    },
});

export const Breadcrumbs = (props) => {
    const { classes, location, breadcrumbs, dispatch } = props;
    // const breadcrumbList = getBreadcrumbs({ ...props, ...props.breadcrumbs });
    // console.log('breadcrumbList ', breadcrumbList);


    useEffect(() => {
        // When page is loaded or pathname is changed, breadcrumb routes are checked for current route
        // If route is of type link, a breadcrumb for the route is added to the redux store
        // Routes of different types than link have dynamic names and are added to redux store in their respective pages
        const route = routes.find(route => route.path === location.pathname && route.breadcrumbType === breadcrumbTypes.link)
        if (route) {
            const breadcrumb = formatBreadcrumb({ name: route.name, path: route.path })
    
            dispatch({ type: userConstants.ADD_BREADCRUMB, payload: breadcrumb })
        }
    }, [dispatch, location.pathname]);

    return (
        <div>
            {breadcrumbs.map(({ path, name }, index) => {
                if (index !== breadcrumbs.length-1) {
                    return (<span key={path + index} className={classes.breadcrumbWrapper}>
                        <NavLink to={path} className={classes.breadcrumb}>
                            {name}
                        </NavLink>
                        {!!(index < breadcrumbs.length - 1) && <span className={classes.breadcrumbDivider}>/</span>}
                    </span>)
                }
                else {
                    return (
                        <span key={path + index} className={classes.breadcrumbWrapper}>
                            <Typography className={classes.breadcrumbActive} display="inline">
                                {name}
                            </Typography>
                        </span>
                    );
                }
            })}
        </div>
    );
};

function mapStateToProps(state) {
    const {
        breadcrumbs
    } = state.User;
    return {
        breadcrumbs
    };
}



export default connect(mapStateToProps)(withRouter(withStyles(styles)(Breadcrumbs)));

const sizes = {
    pageWidth: 1440,
    text: {
        xl: '1.5rem',
        lg: '1.125rem',
        md: '1rem',
        sm: '0.9375rem',
        xs: '0.9rem',
        xxs: '0.75rem',
    }
};

export default sizes;

import { requisitionTypes } from "api/Requisitions/RequisitionTypes";

import { requisitionsConstants } from "store/constants/RequisitionsConstants";

const initialState = {
  showEditRequisitionModal: false,
  showEditRequisitionRowModal: false,
  requisition: {},
  requisitionList: [],
  approvedRequisitionsList: [],
  filterRequisitionType: requisitionTypes.all,
  requisitionRowList: [],
  requisitionRow: {},
  filterPriceRows: "",
  metaDataStatusAndTypes: [],
  metaData: [],
  metaDataSelects: {
    factor: [],
    inv: [],
    inventorySupplement: [],
    height: [],
    conditions: [],
  },
  isLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case requisitionsConstants.SHOW_EDIT_REQUISITION_MODAL:
      return {
        ...state,
        showEditRequisitionModal: !state.showEditRequisitionModal,
        requisition: action.payload,
      };

    case requisitionsConstants.SHOW_EDIT_REQUISITION_ROW_MODAL:
      return {
        ...state,
        showEditRequisitionRowModal: !state.showEditRequisitionRowModal,
        requisitionRow: action.payload,
      };
    case requisitionsConstants.ADD_REQUISITION_ROW_TO_LIST:
      return {
        ...state,
        requisitionRowList: [action.payload, ...state.requisitionRowList],
      };
    case requisitionsConstants.UPDATE_REQUISITION_ROW_TO_LIST:
      const row = state.requisitionRowList.find(
        (p) => p.id === action.payload.id
      );
      return {
        ...state,
        requisitionRowList: [
          ...state.requisitionRowList.filter((r) => r.id !== row.id),
          { ...action.payload },
        ],
      };
    case requisitionsConstants.GET_REQUISITION_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case requisitionsConstants.SET_REQUISITION_LIST:
      return {
        ...state,
        requisitionList: action.payload,
        isLoading: false,
      };

    case requisitionsConstants.SET_APPROVED_REQUISITIONS_LIST:
      return {
        ...state,
        approvedRequisitionsList: action.payload,
        isLoading: false,
      };

    case requisitionsConstants.SET_FILTER_REQUISITION_TYPE:
      return {
        ...state,
        filterRequisitionType: action.payload,
      };

    case requisitionsConstants.SET_META_DATA_STATUS_AND_TYPES:
      return {
        ...state,
        metaDataStatusAndTypes: action.payload,
        isLoading: false,
      };

    case requisitionsConstants.GET_REQUISITION_ROWS:
      return {
        ...state,
        isLoading: true,
      };

    case requisitionsConstants.SET_REQUISITION_ROWS:
      return {
        ...state,
        requisitionRowList: action.payload,
        isLoading: false,
      };
    case requisitionsConstants.SET_FILTER_PRICEROWS:
      return {
        ...state,
        filterPriceRows: action.payload,
      };
    case requisitionsConstants.SETTING_REQUISITION_STATUS:
      return {
        ...state,
        isLoading: true,
      };

    case requisitionsConstants.SET_REQUISITION_STATUS:
      return {
        ...state,
        isLoading: false,
      };

    case requisitionsConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case requisitionsConstants.SET_REQUISITION_META_DATA_SELECTS:
      return {
        ...state,
        metaDataSelects: action.payload,
      };

    case requisitionsConstants.SET_REQUISITION_META_DATA:
      return {
        ...state,
        metaData: action.payload,
      };

    case requisitionsConstants.ON_REQUISITIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

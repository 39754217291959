import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import NumberHelpers from 'lib/helpers/NumberHelpers';

import PieceWorkStatusChip from 'components/pieceWork/statusChip/PieceWorkStatusChip';
import ShareInfo from 'components/common/ShareInfo';

const styles = theme => ({
    card: {
        width: '100%',
        borderRadius: '0',
        boxShadow: '0 1px 15px 1px rgba(69, 65, 78, 0.08)',
        transition: 'all 150ms ease-in-out',
        '&:hover': {
            transform: 'scale(1.02)',
            transition: 'all 150ms ease-in-out'
        }
    },
    testPieceWorkCard: {
        background: '#f3f7ff',
        border: '2px solid #01a2d940',
        width: '100%',
        borderRadius: '0',
        boxShadow: '0 1px 15px 1px rgba(69, 65, 78, 0.08)',
        transition: 'all 150ms ease-in-out',
        '&:hover': {
            transform: 'scale(1.02)',
            transition: 'all 150ms ease-in-out'
        }
    },    
    pieceWorkTestText: {
        color: '#ffffff',
        backgroundColor: '#01a2d940',
        fontSize: '12px',
        width: '85px',
        textAlign: 'center',
        fontWeight: '600',
        marginTop: '2px' 
        
    },
    cardHeader: {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(1),
        alignItems: 'flex-start',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    cardHeaderLarge: {
        minHeight: '120px',
    },
    cardContent: {
        paddingBottom: theme.spacing(2.5),
        '&:last-child': {
            paddingBottom: theme.spacing(0.5),
        }
    },
    pieceWorkTitle: {
        color: theme.colors.primary,
        overflowWrap: "anywhere"
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    listDivider: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.colors.borders.lightGrey,
    },
    keyValueTitle: {
        color: theme.colors.text.darkGrey,
        fontWeight: 'bold'
    },
    keyValue: {
        fontWeight: 'bold'
    },
    action: {
        margin: 0,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        }
    },
});

function PieceWorkOngoingCard(props) {
    const {
        classes,
        className,
        piecework,
        size,
        ...other
    } = props;


    const formatNumber = useCallback((number) => (number != null && NumberHelpers.formatNumber(number)), []);

    return (
        <Card className={classNames(className, piecework && piecework.isTest ? classes.testPieceWorkCard : classes.card)} {...other}>
            <CardHeader component={Link} to={'/akkordunderlag/' + piecework.id}
                title={piecework.name}
                titleTypographyProps={{ variant: 'h1', component: 'h2', className: classes.pieceWorkTitle }}
                subheader={<>
                    <Typography variant="subtitle1">{piecework.companyName}{piecework.companyCity && ', ' + piecework.companyCity}</Typography>
                    {piecework && piecework.isTest ? 
                        <Typography color="textSecondary" className={classes.pieceWorkTestText}>
                            TEST AKKORD
                        </Typography> : ''
                    }
                    {(piecework.sharedWith.length > 0 || piecework.toFederationResponsible) &&
                        <ShareInfo owner={piecework.owner} style={{ marginTop: 16 }} />
                    }
                </>
                }
                className={classNames(classes.cardHeader, {
                    [classes.cardHeaderLarge]: size === 'large'
                })
                }
                classes={{ action: classes.action }}
                action={
                    <PieceWorkStatusChip status={piecework.status} />
                }
            />
            <CardContent className={classes.cardContent}>
                <Typography variant="body2" component="div">
                    <div className={classes.listContainer}>
                        <Typography className={classes.keyValueTitle} gutterBottom>
                            Timer
                        </Typography>
                        <Typography className={classes.keyValue} color="textSecondary">
                            {formatNumber(piecework.hours || 0)}
                        </Typography>
                    </div>
                    <Divider className={classes.listDivider} />
                    <div className={classes.listContainer}>
                        <Typography className={classes.keyValueTitle} gutterBottom>
                            Akkord per time
                        </Typography>
                        <Typography className={classes.keyValue} color="textSecondary">
                            {formatNumber(piecework.calculatedAverageHourlySalary)} kr/t
                        </Typography>
                    </div>
                    <Divider className={classes.listDivider} />
                    <div className={classes.listContainer}>
                        <Typography className={classes.keyValueTitle} gutterBottom>
                            Sum prisliste
                        </Typography>
                        <Typography className={classes.keyValue} color="textSecondary">
                            {formatNumber(piecework.priceListSumExclMultiplier)}
                        </Typography>
                    </div>
                    <Divider className={classes.listDivider} />
                    <div className={classes.listContainer}>
                        <Typography className={classes.keyValueTitle} gutterBottom>
                            Fordeling
                        </Typography>
                        <Typography className={classes.keyValue} color="textSecondary">
                            {formatNumber(piecework.distribution || 0)}%
                        </Typography>
                    </div>
                </Typography>
            </CardContent>
        </Card>
    );
};

PieceWorkOngoingCard.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    piecework: PropTypes.object.isRequired,
    size: PropTypes.oneOf(['small', 'large']),
};

PieceWorkOngoingCard.defaultProps = {
    size: 'large'
}

export default withStyles(styles)(PieceWorkOngoingCard);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
       // margin: theme.spacing.unit * 2,
    },
});

function Loading(props) {
    const { classes, size = 40 } = props;
    return (
        <>
            <CircularProgress size={size} className={classes.progress} />
        </>
    );
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
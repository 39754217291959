import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DescriptionIcon from '@material-ui/icons/Description';

import { styles } from 'styles/layout/LayoutStyle';

import MessageListItem from 'components/menu/MessagesListItem';

const CommonResponsibleListItems = (props) => {

    const {
        classes,
        closeDrawerIfMobile,
    } = props;

    const ForwardedNavLink = forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>
            <div>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/start"
                    title="Forside"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="FORSIDE"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/akkordunderlag"
                    title="Akkordunderlag"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="AKKORDUNDERLAG"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/montorbank"
                    title="Montørbank"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <FolderSharedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="MONTØRBANK"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/maler"
                    title="Maler"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="MALER"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <MessageListItem closeDrawerIfMobile={closeDrawerIfMobile} />
            </div>

        </>
    )
}

export default withStyles(styles)(CommonResponsibleListItems);

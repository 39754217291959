import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter'

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import { roles } from 'api/User/Roles';
import { pieceWorkStatusConstant } from 'api/Status/PieceWorkStatus';

import { getIdentityServerUrlByHost } from 'lib/helpers/HostsHelper';

import { userConstants } from 'store/constants/UserConstants';

import useMenu from 'components/common/useMenu';
import VersionBanner from 'components/common/VersionBanner';
import { AuthContext } from 'components/idConnect/AuthContext';

import MenuDrawer from 'components/menu/menu';
import AdminListItems from 'components/menu/adminListItems';
import FederationListItems from 'components/menu/federationListItems';
import ClubDistrictListItems from 'components/menu/clubDistrictListItems';
import EmployerListItems from 'components/menu/employerListItems';
import MainListItems from 'components/menu/mainListItems';
import Control from 'components/pieceWork/checkList/Control';
import UserProfileModal from 'components/userProfile/modals/userProfileModal';

import Footer from 'components/layout/Footer';

import { styles } from 'styles/layout/LayoutStyle';

import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { routes } from 'components/breadcrumbs/breadcrumbHelper';



const Layout = (props) => {
	const {
		classes,
		UserInfo,
		showUserInfoModal,
		isMenuDrawerOpen,
		pieceWork,
		children,
		dispatch,
		location,
		...rest
	} = props;
	const { anchorEl,
		isMenuOpen,
		handleMenuClick,
		handleMenuClose } = useMenu();
	const name = UserInfo && UserInfo.firstName ? UserInfo.firstName + ' ' + UserInfo.lastName : '';
	const employerName = UserInfo && UserInfo.employerName;
	const initials = UserInfo && (UserInfo.firstName ? UserInfo.firstName.charAt(0) : '') + (UserInfo.lastName ? UserInfo.lastName.charAt(0) : '');

	const userManager = useContext(AuthContext);
	const handleDrawerOpen = useCallback(() => {
		dispatch({ type: userConstants.SET_MENU_OPEN });
		document.body.classList.toggle(classes.menuOpen);
	}, [dispatch, classes.menuOpen])
	const logOutUser = useCallback(() => {
		sessionStorage.clear();
		userManager.logout();
		localStorage.removeItem('userAuth');
	}, [userManager])

	const handleMenuCloseCb = useCallback(handleMenuClose, [handleMenuClose]);
	const isMenuOpenCb = useMemo(() => isMenuOpen, [isMenuOpen]);
	const handleMenuClickCb = useCallback(handleMenuClick, [handleMenuClick]);
	const anchorElCb = useMemo(() => anchorEl, [anchorEl])
	const openUserInfoModal = useCallback((e) => {
		dispatch({ type: userConstants.SHOW_USER_INFO_MODAL });
		handleMenuCloseCb(e);
	}, [dispatch, handleMenuCloseCb]);

	const changeEmail = () => {
		redirectToAccountManagement('ChangeEmail');
	}

	const changePassword = () => {
		redirectToAccountManagement('ChangePassword');
	}

	const redirectToAccountManagement = (page) => {
		let hostName = window && window.location && window.location.hostname;
		if (hostName) {
			let url = getIdentityServerUrlByHost(hostName);
			let returnUrl = process.env.NODE_ENV === 'development'
				? 'http://localhost:3000'
				: `https://${hostName}`;
			url += `/Identity/Account/Manage/${page}?returnUrl=${returnUrl}`;
			window.location.href = url;
		}
	}

	const getListItems = useCallback(() => {

		if (UserInfo && UserInfo.role
			&& UserInfo.role.includes(roles.Admin)
		) {
			return AdminListItems;
		}

		if (UserInfo && UserInfo.role
			&& UserInfo.role.includes(roles.FederationResponsible)
		) {
			return FederationListItems;
		}

		if (UserInfo && UserInfo.role
			&& UserInfo.role.includes(roles.Employer)
		) {
			return EmployerListItems;
		}

		if (UserInfo && UserInfo.role
			&& (UserInfo.role.includes(roles.ClubResponsible)
				|| UserInfo.role.includes(roles.DistrictResponsible))
		) {
			return ClubDistrictListItems;
		}

		return MainListItems;
	}, [UserInfo])


	const renderCheckList = pieceWork && pieceWork.status === pieceWorkStatusConstant.Control ? (<Control />) : (null);

	const renderUserInfoModal = showUserInfoModal ? <UserProfileModal /> : (null);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{renderCheckList}
			<AppBar
				position='absolute'
				className={classNames(classes.appBar, classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton onClick={handleDrawerOpen}>
						<MenuIcon className={classes.menuIcon} />
					</IconButton>
					
						<div className={classNames(classes.contentContainer, {
							[classes.contentContainerMenuOpen]: isMenuDrawerOpen,
						})}>
							<Hidden only="xs">
									<Breadcrumbs {...rest} routes={routes} />
							</Hidden>
						</div>
					<Typography component="p" variant="h3">
						{name}<br />
						<span style={{ fontSize: '0.75rem', display: 'block', textAlign: 'right', marginTop: 4 }} >
							{employerName}
						</span>
					</Typography>

					<Avatar className={classes.avatar}>{initials}</Avatar>
					<IconButton onClick={handleMenuClickCb}>
						<ArrowDownIcon />
					</IconButton>
					<Menu anchorEl={anchorElCb} open={isMenuOpenCb} onClose={handleMenuCloseCb} MenuListProps={{ disablePadding: true }}>
						<MenuItem onClick={openUserInfoModal}>Min informasjon</MenuItem>
						<MenuItem onClick={changeEmail}>Bytt e-post</MenuItem>
						<MenuItem onClick={changePassword}>Bytt passord</MenuItem>
						<MenuItem onClick={logOutUser}>Logg ut</MenuItem>
					</Menu>
				</Toolbar>
				<VersionBanner />
			</AppBar>
			<MenuDrawer MainListItems={getListItems()} handleDrawerOpen={handleDrawerOpen} />
			<main className={classes.content}>
				<div className={classes.mainContent}>
					{children}
				</div>
				<Footer />
			</main>
			{renderUserInfoModal}
		</div>
	);
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const {
		UserInfo,
		showUserInfoModal,
		isMenuOpen,
	} = state.User;
	const {
		pieceWork
	} = state.PieceWorkState;
	return {
		UserInfo,
		showUserInfoModal,
		isMenuDrawerOpen: isMenuOpen,
		pieceWork
	};
}

const checkEqual = (next, prev) => {
	return (
		prev.User.showUserInfoModal === next.User.showUserInfoModal &&
		prev.User.UserInfo && prev.User.UserInfo.role === next.User.UserInfo && next.User.UserInfo.role &&
		prev.PieceWorkState.pieceWork && prev.PieceWorkState.pieceWork.status === next.PieceWorkState.pieceWork && next.PieceWorkState.pieceWork.status
	);
}

export default connect(mapStateToProps, null, null, {
	areStatesEqual: checkEqual
})(withRouter(withStyles(styles)(Layout)));

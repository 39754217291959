export const workerRegistryConstants = {
    EDIT_WORKER_REGISTRY_WORKER_MODAL: 'EDIT_WORKER_REGISTRY_WORKER_MODAL',
    
	GET_WORKER_REGISTRY_WORKER_LIST: 'GET_WORKER_REGISTRY_WORKER_LIST',
	SET_WORKER_REGISTRY_WORKER_LIST: 'SET_WORKER_REGISTRY_WORKER_LIST',

	SAVE_WORKER_TO_REGISTRY: 'SAVE_WORKER_TO_REGISTRY',
	SAVED_WORKER_TO_REGISTRY: 'SAVED_WORKER_TO_REGISTRY',

	SET_FILTER_WORKER_REGISTRY_WORKER_LIST: 'SET_FILTER_WORKER_REGISTRY_WORKER_LIST',
	SET_FILTER_WORKERS_BY_STATUS: 'SET_FILTER_WORKERS_BY_STATUS',

	RESET_FILTERS: 'RESET_FILTERS',
    
	ON_WORKER_REGISTRY_ERROR: 'ON_WORKER_REGISTRY_ERROR',
};

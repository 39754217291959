import colors from 'lib/helpers/Colors';

export const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    headerWrapper: {
        marginBottom: theme.spacing(2.5),
    },
    dropContainer: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        borderRadius: theme.spacing(0.5),
    },
    cardContent: {
        padding: theme.spacing(2.5, 3.5, 3.5),
        '&:last-child': {
            paddingBottom: theme.spacing(3.5)
        },
    },
    cameraIcon: {
        marginLeft: 'auto',
        alignSelf: 'center',
        marginRight: theme.spacing(1.5),
        color: theme.colors.primary,
        cursor: 'pointer'
    },
    thumbNail: {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        borderRadius: '6px',
        border: `1px solid ${theme.colors.borders.lightGrey}`,
    },
    imgWrapper: {
        transition: 'all .1s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    removeIcon: {
        position: 'relative',
        float: 'right',
        top: '-8px',
        right: '12px',
        fontSize: '20px',
        border: '1px solid',
        background: theme.palette.common.white,
        borderRadius: '30px',
        cursor: 'pointer'

    },
    dropzoneContainer: {
        padding: '14px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    deleteImg: {
        float: 'right',
        '&::before': {
            content: '"×"'
        },

    },
    files: {
        '& :hover': {
            cursor: 'pointer',
            transform: 'scale(1.03)'
        }
    },
    filesContainer: {
        border: `1px solid ${colors.borders.lightGrey}`,
        borderRadius: 4,
        '&:hover': {
            borderColor: theme.colors.text.grey,
        },
        '&:focus, &:active': {
            borderWidth: 2,
            borderColor: theme.colors.primary,
            outline: 0,
        }
    },
    mail: {
        display: 'inline-flex',
        marginTop: theme.spacing(1),
        alignItems: 'center',
        color: theme.colors.icons.darkestGrey,
    },
    link: {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
        fontSize: theme.sizes.text.md,
        color: theme.colors.primary,
        ':&hover': {
            textDecoration: 'underline',
        }
    },
});




export const baseStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    wordBreak: 'break-all',
};
export const activeStyle = {
    borderColor: colors.primary,
    borderWidth: 2,
    backgroundColor: colors.background.grey,
};
export const rejectStyle = {
    borderStyle: 'solid',
    borderColor: colors.borders.lightGrey,
    backgroundColor: colors.background.grey,
};
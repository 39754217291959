import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Snackbar from '@material-ui/core/Snackbar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import actions from 'store/actions/SnackbarActions';
import { SnackbarContext } from 'components/common/snackBar/SnackbarContext';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
};

const styles = (theme) => ({
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	info: {
		backgroundColor: theme.palette.primary.dark
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: ['Source Sans Pro'],
		fontSize: 16,
		whiteSpace:'pre-wrap'
	}
});

const SnackbarProvider = (props) => {
	const initState = {
		snackbar: {
			showSuccess: props.showSuccess,
			showWarning: props.showWarning,
			showError: props.showError,
			showInfo: props.showInfo
		}
	};

	const [contextValue,] = useState(initState);


	// TODO: Calculate height dynamic. If message gets too long then this will fail
	const snackPos = [24, 84, 144, 204, 264, 324];

	const handleClose = (item, reason) => {
		props.dismiss(item.id);
		if (reason === 'clickaway') return;
	};

	const handleCallbackClick = (item) => {
		props.dismiss(item.id);
		if (item.handleCallback) item.handleCallback();
	};

	const { children, SnackbarProps = {}, classes } = props;

	return (
		<SnackbarContext.Provider value={contextValue}>
			{children}
			{props.snackbarList.map((item, x) => {
				let pos = snackPos[x];
				let Icon = variantIcon[item.type];
				let duration = item.action || item.type === 'error' || item.type === 'info' ? null : 3000;
				return (
					<Snackbar
						autoHideDuration={duration}
						{...SnackbarProps}
						key={item.id}
						open={true}
						onClose={() => handleClose(item)}
						style={{
							top: pos, zIndex: '10000', marginLeft:24
						}}
						variant={item.type || ''}
						anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					>
						<SnackbarContent
							className={classes[item.type]}
							message={
								<span className={classes.message} >
									<Icon className={classNames(classes.icon, classes.iconVariant)} />
									{item.message.text ? item.message.text : item.message || ''}
								</span>
							}
							action={
								item.action ||
								((item.type === 'error') && (
									<IconButton
										key="close"
										aria-label="Close"
										color="inherit"
										className={classes.close}
										onClick={() => handleCallbackClick(item)}
									>
										<CloseIcon className={classes.icon} />
									</IconButton>
								))
							}
						/>
					</Snackbar>
				);
			})}
		</SnackbarContext.Provider>
	);
};

SnackbarProvider.propTypes = {
	snackbar: PropTypes.object,
	children: PropTypes.node,
	SnackbarProps: PropTypes.object
};

export default connect(
	(state) => ({
		snackbarList: state.snackbar.snackList || null
	}),
	(dispatch) => ({
		showSuccess: (message, action, handleCallback) =>
			dispatch(actions.showSuccess({ message, action, handleCallback })),
		showWarning: (message, action, handleCallback) =>
			dispatch(actions.showWarning({ message, action, handleCallback })),
		showError: (message, action, handleCallback) =>
			dispatch(actions.showError({ message, action, handleCallback })),
		showInfo: (message, action, handleCallback) => dispatch(actions.showInfo({ message, action, handleCallback })),
		dismiss: (id) => dispatch(actions.dismiss({ id }))
	})
)(withStyles(styles)(SnackbarProvider));

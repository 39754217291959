import * as api from 'api/Api';
import { commonService } from './CommonService';

export const userService = {
	loginUser,
	searchUser,
	searchUserByRole,
	getUserSettings,
	updateEmailSetting,
	getMyProfileData,
	getMyUserProfile,
	getMyState
};

async function loginUser(username = '', password = '') {
	const opt = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(username)
	};

	//opt.headers['ACCEPT'] = 'application/json';
	opt.headers['Access-Control-Allow-Origin'] = '*';
	opt.headers = api.addJsonHeader(opt.headers);
	return await fetch(api.loginUserUrl, opt).then(commonService.handleResponse); //.then((response) => {
	//	localStorage.setItem('userAuth', JSON.stringify(response.access));
	//	return response;
	//});
}

async function searchUser(query) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	let url = api.getSearchUserUrl(query);
	return fetch(url, opt).then(commonService.handleResponse);
}

async function searchUserByRole(role) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	let url = api.getSearchUserByRoleUrl(role);
	return fetch(url, opt).then(commonService.handleResponse);
}

async function updateEmailSetting(receiveNotificationMail = false, notificationType) {
	const setBody = () => {
		if (notificationType === "suggestion") {
			return (JSON.stringify({ receiveSuggestionNotifications: receiveNotificationMail }))
		} else if (notificationType === "message") {
			return (JSON.stringify({ receiveMessageNotifications: receiveNotificationMail }))
		} else return null;
	}

	const opt = {
		method: 'PATCH',
		headers: api.authHeader(),
		body: setBody()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	return fetch(api.getUpdateUserSettingUrl, opt).then(commonService.handleResponse);
}

async function getUserSettings(id) {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
	opt.headers = api.addJsonHeader(opt.headers);
	return fetch(api.getUserUrl(id), opt).then(commonService.handleResponse);
}

async function getMyProfileData() {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
	opt.headers = api.addJsonHeader(opt.headers);
	return fetch(api.getMyProfileDataUrl, opt).then(commonService.handleResponse);
}

async function getMyUserProfile() {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
	opt.headers = api.addJsonHeader(opt.headers);
	return fetch(api.getMyUserProfileUrl(), opt).then(commonService.handleResponse);
}

async function getMyState() {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
	opt.headers = api.addJsonHeader(opt.headers);
	return fetch(api.getMyStateUrl, opt).then(commonService.handleResponse);
}

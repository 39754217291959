import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-f.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/youtube.svg';

import { ReactComponent as LogoAkkordportalen } from 'assets/img/Akkordportalen_logo_staende.svg';
import { ReactComponent as LogoElogIt } from 'assets/img/elogit_forbundet_logo_2016.svg';

const styles = theme => ({
    footer: {
        backgroundColor: theme.colors.background.grey,
        borderTop: `1px solid ${theme.palette.divider}`,
        color: theme.colors.text.darkestGrey
    },
    gridContainer: {
        padding: theme.spacing(1.5, 0),
    },
    gridItem: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    gridSocials: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    fabGridItem: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fab: {
        margin: theme.spacing(1),
        color: theme.colors.text.darkestGrey,
        fontSize: theme.sizes.text.md,
        border: `1px solid #dededf`,
        backgroundColor: '#f8f8f8'
    },
    fabDisabled: {
        backgroundColor: '#f8f8f8 !important',
        color: `${theme.colors.icons.darkestGrey} !important`,
    },
    logo: {
        height: 60,
        width: 'auto',
        margin: theme.spacing(1.5, 0),
        [theme.breakpoints.only('md')]: {
            height: 44,
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(3, 0),
        }
    },
    text: {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
        fontSize: theme.sizes.text.md,
        color: theme.colors.text.darkestGrey
    },
    link: {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
        fontSize: theme.sizes.text.md,
        color: theme.colors.primary,
        ':&hover': {
            textDecoration: 'underline',
        }
    },
    thirdPartyIcon: {
        height: theme.sizes.text.md,
    },
});

const Footer = (props) => {
    const { classes } = props;
    return (
        <footer
            className={classes.footer}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={2}
                    lg={3}
                    className={classes.gridItem}
                >
                    <LogoElogIt className={classes.logo} />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <Grid
                        className={classes.gridContainer}
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.gridItem}
                        >
                            <div>
                                <div>
                                    <Fab
                                        size="small"
                                        className={classes.fab}
                                        classes={{
                                            disabled: classes.fabDisabled
                                        }}
                                        disabled
                                    >
                                        <RoomIcon fontSize="inherit" />
                                    </Fab>
                                    <Typography
                                        component="span"
                                        display="inline"
                                        className={classes.text}
                                    >
                                        Torgata 12, 0181 Oslo

                                    </Typography>
                                </div>
                                <div>
                                    <Fab
                                        size="small"
                                        className={classes.fab}
                                        classes={{
                                            disabled: classes.fabDisabled
                                        }}
                                        disabled
                                    >
                                        <PhoneIcon fontSize="inherit" />
                                    </Fab>
                                    <Typography
                                        component="a"
                                        display="inline"
                                        href="tel:+47 23 06 34 00"
                                        className={classes.link}
                                    >
                                        +47 23 06 34 00
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.gridItem}
                        >
                            <div>
                                <div>
                                    <Fab
                                        size="small"
                                        className={classes.fab}
                                        classes={{
                                            disabled: classes.fabDisabled
                                        }}
                                        disabled
                                    >
                                        <EmailIcon fontSize="inherit" />
                                    </Fab>
                                    <Typography
                                        component="a"
                                        display="inline"
                                        href="mailto:akkord@elogit.no"
                                        className={classes.link}
                                    >
                                        akkord@elogit.no
                                    </Typography>
                                </div>
                                <div>
                                    <Fab
                                        size="small"
                                        className={classes.fab}
                                        classes={{
                                            disabled: classes.fabDisabled
                                        }}
                                        disabled
                                    >
                                        <InfoOutlinedIcon fontSize="inherit" />
                                    </Fab>
                                    <Typography
                                        component="a"
                                        display="inline"
                                        href="https://elogit.no/rettigheter-og-ressurser/akkord/"
                                        className={classes.link}
                                    >
                                        Verktøykasse
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.gridSocials}
                        >
                            <Fab
                                size="small"
                                className={classes.fab}
                                title="Facebook"
                                href="https://www.facebook.com/groups/akkordportalen/"
                                target="_blank"
                            >
                                <FacebookIcon className={classes.thirdPartyIcon} />
                            </Fab>
                            <Fab
                                size="small"
                                className={classes.fab}
                                title="Twitter"
                                href="https://twitter.com/ELogIT_no"
                                target="_blank"
                            >
                                <TwitterIcon className={classes.thirdPartyIcon} />
                            </Fab>
                            <Fab
                                size="small"
                                className={classes.fab}
                                title="YouTube"
                                href="https://www.youtube.com/channel/UC_hMNuzsZVcG6c0kN117bLA"
                                target="_blank"
                            >
                                <YouTubeIcon className={classes.thirdPartyIcon} />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    lg={3}
                    className={classes.gridItem}
                >
                    <LogoAkkordportalen
                        className={classes.logo}
                    />
                </Grid>
            </Grid>
        </footer>
    )
};

export default withStyles(styles)(React.memo(Footer))
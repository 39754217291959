import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { duration } from '@material-ui/core/styles/transitions';

const styles = theme => ({
    contentContainer: {
        width: '450px',
        maxWidth: '100%',
        left: 'auto !important'
    },
    contentContainerLarge: {
        width: 900,
    },
    paper: {
        margin: 0,
        width: '100%',
        height: '100%',
        borderRadius: '0'
    },
    paperScrollPaper: {
        maxHeight: '100%',
    },
    paperWidthSm: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 900,
        },
    },
});


const EditModal = (props) => {
    const { classes, children, large, ...other } = props;

	return (
        <Dialog 
            TransitionComponent={Slide}
            TransitionProps={{ 
                direction: 'left', 
                timeout: { enter: duration.enteringScreen, exit: duration.leavingScreen }, 
            }}
            classes={{ 
                paper: classes.paper, 
                paperScrollPaper: classes.paperScrollPaper,
                paperWidthSm: large && classes.paperWidthSm
            }}
            className={classNames(classes.contentContainer, {
                [classes.contentContainerLarge]: large
            })}
            {...other}
            >
                {children}
        </Dialog>
	);
};



export default withStyles(styles)(EditModal);

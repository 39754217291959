// import React from 'react';

// import { matchPath } from 'react-router';
// import { Route } from 'react-router-dom';
// import { userConstants } from 'store/constants/UserConstants';
// import BreadcrumbItem from 'components/breadcrumbs/BreadcrumbItem';
// import BreadcrumbLink from 'components/breadcrumbs/breadcrumbLink';


export const breadcrumbTypes = {
    pieceWork: 'PieceWork',
    news: 'News',
    // suggestion: 'Suggestion',
    template: 'Template',
    link: 'Link'
}

export const formatBreadcrumb = ({ id, type, name, path }) => {
    return {
        id: id,
        type: type,
        name: name,
        path: path
    }
}

// export const getBreadcrumbs = (props) => {
//     const { routes, location, breadcrumbs } = props;
//     const matches = [];
//     const { pathname } = location;

//     console.log('breads ', breadcrumbs)
//     console.log('pathname ', pathname);
//     let breads = breadcrumbs.length === 0 ? [{ path: pathname }] : breadcrumbs;
//     breads.map(x =>
//         x.path
//             .replace(/\/$/, '')
//             .split('/')
//             .reduce((previous, current) => {
//                 const pathSection = `${previous}/${current}`;
//                 const match = routes.find(({ matchOptions, path }) =>
//                     matchPath(pathSection, { ...(matchOptions || { exact: false }), path }));

//                 if (match) {
//                     console.log('matches')
//                     matches.push({
//                         component: <Route {...match} />,
//                         path: pathSection,
//                     });


//                 }

//                 return pathSection;
//             }));
//     //return breads
//     return matches;
// };

export const routes = [
    // { path: '/arbeidsgiver', render: () => 'Employers' },
    // { path: '/start', render: () => 'Test' },
    // { path: '/medlemmer', render: () => 'fsdfsd' },

    // { path: '/start', render: props => <BreadcrumbLink name="Forside" link="/start" {...props} /> },
    // { path: '/medlemmer', render: props => <BreadcrumbLink name="Medlemmer" link="/medlemmer" {...props} /> },
    // { path: '/akkordunderlag/:id', render: props => <BreadcrumbItem breadcrumbType={breadcrumbTypes.pieceWork} {...props} /> },
    // { path: '/akkordunderlag', render: props => <BreadcrumbLink name="Akkordunderlag" link="/akkordunderlag" {...props} /> },
    // { path: '/montorbank', render: props => <BreadcrumbLink name="Montørbank" link="/montorbank" {...props} /> },
    // { path: '/maler', render: props => <BreadcrumbLink name="Maler" link="/maler" {...props} /> },
    // { path: '/meldinger', render: props => <BreadcrumbLink name="Meldinger" link="/meldinger" {...props} /> },


    // Piecework, club, district and federation responsible, employer, admin
    { path: '/start', name: 'Forside', breadcrumbType: breadcrumbTypes.link },

    // Piecework, club, district and federation responsible
    { path: '/aktuelt/:id', breadcrumbType: breadcrumbTypes.news },
    { path: '/akkordunderlag/:id', breadcrumbType: breadcrumbTypes.pieceWork },
    { path: '/akkordunderlag', name: 'Akkordunderlag', breadcrumbType: breadcrumbTypes.link },
    { path: '/montorbank', name: 'Montørbank', breadcrumbType: breadcrumbTypes.link },
    { path: '/maler/:id', breadcrumbType: breadcrumbTypes.template },
    { path: '/maler', name: 'Maler', breadcrumbType: breadcrumbTypes.link },
    { path: '/meldinger', name: 'Meldinger', breadcrumbType: breadcrumbTypes.link },

    { path: '/arkiv', name: 'Arkiv', breadcrumbType: breadcrumbTypes.link },
    { path: '/papirkurv', name: 'Papirkurv', breadcrumbType: breadcrumbTypes.link },

    // Club and district responsible, employer
    { path: '/sok', name: 'Søk', breadcrumbType: breadcrumbTypes.link },

    // Federation responsible
    // { path: '/forslagskasse/:id', breadcrumbType: breadcrumbTypes.suggestion },
    { path: '/aktuelt', name: 'Aktuelt', breadcrumbType: breadcrumbTypes.link },
    { path: '/forslagskasse', name: 'Forslagskasse', breadcrumbType: breadcrumbTypes.link },
    { path: '/arbeidsgiver', name: 'Arbeidsgiver', breadcrumbType: breadcrumbTypes.link },
    { path: '/medlemmer', name: 'Medlemmer', breadcrumbType: breadcrumbTypes.link },

    // Federation responsible, admin
    { path: '/kontoadministrasjon', name: 'Kontoadministrasjon', breadcrumbType: breadcrumbTypes.link },

    // Employer
    { path: '/gjeldende', name: 'Gjeldende', breadcrumbType: breadcrumbTypes.link },
    { path: '/fullforte', name: 'Fullførte', breadcrumbType: breadcrumbTypes.link },

    // Admin
    { path: '/sentralesatser', name: 'Sentrale satser', breadcrumbType: breadcrumbTypes.link },
    { path: '/spesielletillegg', name: 'Spesielle tillegg', breadcrumbType: breadcrumbTypes.link },
    { path: '/sentralemaler/:id', breadcrumbType: breadcrumbTypes.template },
    { path: '/sentralemaler', name: 'Sentrale maler', breadcrumbType: breadcrumbTypes.link },
    { path: '/filbibliotek', name: 'Filbibliotek', breadcrumbType: breadcrumbTypes.link },

];


import * as api from 'lib/helpers/ApiHelper.js';
import { commonService } from 'store/services/CommonService';

const url = api.getUrl();

const getIngressNewsUrl = () => url + `/v1/news/allpublished?pageSize=5&orderBy=createdAt&desc=true`;
const getSingleNewsUrl = (id) => url + `/v1/news/${id}`;
const getAllNewsUrl = () => url + `/v1/news/all?pageSize=10000&orderBy=modifiedAt&desc=true`;
const getAllPublishedNewsUrl = () => url + `/v1/news/allpublished&orderBy=createdAt`;
const createNewsUrl = () => url + '/v1/news/save';
const removeNewsUrl = (id) => url + `/v1/news/remove/${id}`;

async function getIngressNews() {
    const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(getIngressNewsUrl(), opt).then(commonService.handleResponse);
}

async function getSingleNews(id) {
    const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(getSingleNewsUrl(id), opt).then(commonService.handleResponse);
}

async function getAllNews() {
    const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(getAllNewsUrl(), opt).then(commonService.handleResponse);
}

async function getAllPublishedNews() {
    const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(getAllPublishedNewsUrl(), opt).then(commonService.handleResponse);
}

async function createNews(obj) {
	const opt = {
		method: 'POST',
		headers: api.authHeader(),
		body: JSON.stringify(obj)
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(createNewsUrl(), opt).then(commonService.handleResponse);
}

async function updateNews(obj) {
	const opt = {
		method: 'PUT',
		headers: api.authHeader(),
		body: JSON.stringify(obj)
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(createNewsUrl(), opt).then(commonService.handleResponse);
}

async function removeNews(id) {
	const opt = {
		method: 'DELETE',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(removeNewsUrl(id), opt).then(commonService.handleResponse);
}

export const newsService = {
	getIngressNews,
	getSingleNews,
	getAllNews,
	getAllPublishedNews,
	createNews,
	updateNews,
	removeNews
}
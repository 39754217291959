import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';

import { styles } from 'styles/layout/LayoutStyle';

const MenuDrawer = (props) => {
	const {
		classes,
		isMenuOpen,
		UserInfo,
		handleDrawerOpen,
		MainListItems,
	} = props;

	const closeDrawerIfMobile = useCallback(() => {
		if (window.innerWidth < 600) {
			handleDrawerOpen();
		}
	}, [handleDrawerOpen]);

	return (
		<Drawer
			variant="permanent"
			classes={{
				paper: classNames(classes.drawerPaper, classes.customScrollBar, !isMenuOpen && classes.drawerPaperClose)
			}}
			open={isMenuOpen}
		>
			<div className={classes.toolbarIcon}>
				<IconButton
					onClick={handleDrawerOpen}
					className={classNames(
						classes.menuButton,
						classes.closeOpenBtn,
						{
							[classes.menuButtonHidden]: !isMenuOpen
						},
					)}
				>
					<ChevronLeftIcon className={classes.chevronLeftIcon} />
					<CloseIcon className={classes.closeIcon} />

				</IconButton>
				<IconButton
					color="inherit"
					aria-label="Open drawer"
					onClick={handleDrawerOpen}
					className={classNames(classes.menuButton, {
						[classes.menuButtonHidden]: isMenuOpen
					})}
				>
					<MenuIcon />
				</IconButton>
			</div>
			<Divider />
			<List className={classes.menuList}>
				<MainListItems closeDrawerIfMobile={closeDrawerIfMobile} userRole={UserInfo && UserInfo.role} />
			</List>
		</Drawer>
	);
};

function mapStateToProps(state) {
	const { isMenuOpen, UserInfo } = state.User;
	return {
		isMenuOpen,
		UserInfo
	};
}

export default connect(mapStateToProps, null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev.User.isMenuOpen === next.User.isMenuOpen &&
				prev.User.UserInfo && prev.User.UserInfo.role === next.User.UserInfo && next.User.UserInfo.role
			);
		}
	}
)(withStyles(styles)(MenuDrawer));

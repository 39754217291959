import { useState } from 'react';

const useForm = (callback) => {
	const [values, setValues] = useState({});

	const handleCallback = (event) => {
		if (event) event.preventDefault();
		callback();
	};

	const handleChange = (event) => {
		event.persist();
		setValues((values) => ({ ...values, [event.target.name]: event.target.value }));
	};

	const handleCheckboxChange = (event) => {
		event.persist();
		setValues((values) => ({ ...values, [event.target.name]: event.target.checked }));
	};

	const handleFiles = (files) => {
		if (files.length > 0) {
			// let setFiles = files.map((x) => x.name);
			setValues((values) => ({ ...values, files: files }));
		}
	};

	return {
		handleChange,
		handleCheckboxChange,
		handleFiles,
		handleCallback,
		values,
		setValues
	};
};

export default useForm;

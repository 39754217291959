import { getUrl } from 'lib/helpers/ApiHelper.js';

const url = getUrl();

export const fetchPieceWorkListUrl = url + `/v1/mywork/2`;
export const loginUserUrl = url + `/v1/authenticate`;
export const getSearchUserUrl = (query) =>
  url + `/v1/user/search?phrase=` + query;
export const getSearchPieceWorkResponsibleUrl = (query) =>
  url + `/v1/user/searchPieceWorkResponsibles?phrase=` + query;
export const getSearchUserByRoleUrl = (role) =>
  url + `/v1/user/searchbyrole?role=` + role;
export const getUpdateUserSettingUrl = url + `/v1/user/settings`;
export const getUserUrl = (id) => url + `/v1/user/${id}`;
export const getMyProfileDataUrl = url + '/v1/my/identity';
export const getMyUserProfileUrl = () => url + `/v1/my/profile`;
export const getMyStateUrl = url + `/v1/my/state`;
export const getFetchPieceWorkListUrl = (pieceWorkStatus) =>
  url + `/v1/my/work/${pieceWorkStatus}?orderby=modifiedat&desc=true`;
export const getMyPieceWorksWithPricelistsUrl = () =>
  url + `/v1/my/work/withpricelists`;
export const getFetchPieceWorkUrl = (workId) => url + `/v1/work/${workId}`;
export const getUpdatePieceWorkEmployerUrl = (workId, employerId) =>
  url + `/v1/work/${workId}/employer/${employerId}`;

export const getSearchPieceWorkUrl = () =>
  url + `/v1/work/search?pageSize=10000`;
export const getFetchSaveNewPieceWorkUrl = () => url + '/v1/work';
export const getFileUploadUrl = (workId) => url + `/v1/work/${workId}/files`;
export const getPieceWorkFileDownloadUrl = (workId, fileId) =>
  url + `/v1/work/${workId}/files/${fileId}`;
export const getPieceWorkMetaDataUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/metadata`;
export const getWorkersByPieceWorkUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/workers?pageSize=10000`;
export const savePieceWorkWorkerUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/worker`;
export const editPieceWorkWorkerUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/worker`;
export const deletePieceWorkWorkerUrl = (pieceWorkId, workerId) =>
  url + `/v1/work/${pieceWorkId}/worker/${workerId}`;
export const getAddWorkerToPieceWorkUrl = (pieceWorkId, workerId) =>
  url + `/v1/work/${pieceWorkId}/worker/${workerId}`;

export const getPieceWorkCheckListUrl = (workId) =>
  url + `/v1/pieceworksettlement/${workId}/checklist?pagesize=100`;
export const getPieceWorkCheckListFormUrl = (workId) =>
  url + `/v1/work/${workId}/setup`;
export const getSetPieceWorkStatusToOpenUrl = (workId) =>
  url + `/v1/work/${workId}/open`;
export const getSetPieceWorkStatusToClosedUrl = (workId) =>
  url + `/v1/work/${workId}/closed`;
export const getSetPieceWorkStatusToReopenedUrl = () => url + `/v1/work/reject`;
export const getSetPieceWorkStatusToReopenedAdminUrl = (workId, reason) =>
  url + `/v1/work/${workId}/reopened/admin?rejectedCause=${reason}`;
export const getSetPieceWorkStatusToSentInUrl = (workId, distributionId = 0) =>
  url + `/v1/work/${workId}/sentin?distributionId=${distributionId}`;
export const getCreateEmptyDistributionUrl = (workId) =>
  url + `/v1/work/${workId}/edition`;

export const getSetPieceWorkStatusToApprovedUrl = (workId) =>
  url + `/v1/work/${workId}/approved`;
export const getSetPieceWorkStatusToFinishedUrl = (workId) =>
  url + `/v1/work/${workId}/finished`;
export const getSetPieceWorkSalaryPaymentUrl = (workId, paymentDate) =>
  url + `/v1/work/${workId}/salarypayment?salaryPaymentAt=${paymentDate}`;

export const getSetCheckListItemConfirmedUrl = (checkListId, isConfirmed) =>
  url + `/v1/pieceworksettlement/${checkListId}/isconfirmed/${isConfirmed}`;
export const getSetCheckListFormItemConfirmedUrl = () => url + '/v1/work/setup';

export const getToggleWorkerStatusUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/workers/toggle`;
export const getToggleWorkerBorrowedUrl = (pieceWorkId, workerId) =>
  url + `/v1/work/${pieceWorkId}/workers/${workerId}/toggleborrowed`;
export const getChangeEmployerOnWorkerUrl = (workerId, employerId) =>
  url + `/v1/my/workers/${workerId}/employer/${employerId}`;
export const getFilterWorkerUrl = () => url + `/v1/worker/search`;
export const getDistrictListUrl = () => url + `/v1/employer/districts`;
export const getFilterPieceWorkWorkerUrl = (workId) =>
  url + `/v1/work/${workId}/workers/search`;
export const getFetchPieceWorkResponsiblesUrl = (workId) =>
  url + `/v1/work/${workId}/responsible?includeShared=true`;
export const savePieceWorkResponsiblesUrl = (workId) =>
  url + `/v1/work/${workId}/responsible`;
export const getToggleContactPersonUrl = (workId, userId) =>
  url + `/v1/work/${workId}/responsible/${userId}/togglecontactperson`;
export const removePieceWorkResponsiblesUrl = (workId, userId) =>
  url + `/v1/work/${workId}/responsible/${userId}`;

export const getSharePieceWorkUrl = (workId, userId) =>
  url + `/v1/work/${workId}/share`;
export const getRemoveSharePieceWorkUrl = (workId, userId) =>
  url + `/v1/work/${workId}/share`;
export const getToggleShareFederationPieceWorkUrl = (workId) =>
  url + `/v1/work/${workId}/share/federationresponsible`;

export const getCreateUpdatePriceListUrl = () => url + '/v1/pricelist';
export const getPriceListsByPieceWorkUrl = (pieceWorkId) =>
  url + `/v1/pricelist/bypiecework/${pieceWorkId}?pageSize=10000`;
export const getPriceListById = (priceListId) =>
  url + `/v1/pricelist/${priceListId}`;
export const getCopyPriceListUrl = (
  fromPriceListId,
  toPriceListId,
  pieceWorkStartDate
) =>
  url +
  `/v1/pricelist/${fromPriceListId}/copyto/${toPriceListId}?startdate=${pieceWorkStartDate}`;
export const deletePriceListById = (priceListId) =>
  url + `/v1/pricelist/${priceListId}`;
export const getAllPriceRowsByPricelistUrl = (priceListId) =>
  url +
  `/v1/pricelist/${priceListId}/rows?pageSize=10000&orderBy=createdat&desc=true`;
export const getSearchProductsUrl = () => url + '/v1/product/search';
export const getAddPriceRowUrl = (priceListId) =>
  url + `/v1/pricelist/${priceListId}`;
export const getDeletePriceRowUrl = (priceRowId) =>
  url + `/v1/pricelist/row/${priceRowId}`;
export const getPriceListFilesAndNamesUrl = (pieceWorkId) =>
  url + `/v1/pricelist/bypiecework/employer/${pieceWorkId}`;
export const getPriceListMetaDataUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/metadata`;

export const getPriceListFileUploadUrl = (priceListId) =>
  url + `/v1/pricelist/${priceListId}/files`;
export const getPriceListImageUrl = (priceListId, fileId) =>
  url + `/v1/pricelist/${priceListId}/files/${fileId}`;
export const getDeleteFileFromPieceWorkUrl = (pieceWorkId, fileId) =>
  url + `/v1/work/${pieceWorkId}/files/${fileId}`;
export const getDeletePieceWorkUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}`;

export const getPieceWorkTimeUrl = (workId) =>
  url + `/v1/work/${workId}/time?pageSize=10000`;
export const getPieceWorkTimeSummaryForUrl = (workId, workerIds) =>
  url + `/v1/work/${workId}/timesummaryfor?workers=${workerIds}&pageSize=10000`;
export const getPieceWorkTimeSummaryUrl = (workId) =>
  url + `/v1/work/${workId}/timesummary?pageSize=10000`;
export const getPieceWorkPriceListRowsUrl = (workId) =>
  url + `/v1/work/${workId}/pricelists/rows?pageSize=10000`;
export const getPieceWorkPriceListSummaryUrl = (workId) =>
  url +
  `/v1/work/${workId}/distribution/pricelistsummary?pageSize=10000&orderBy=groupAndPosition`;
export const getApprovedAgreementsByPieceWorkIdUrl = (workId) =>
  url + `/v1/work/${workId}/agreements/`;
export const getApprovedRequisitionsByPieceWorkIdUrl = (workId) =>
  url + `/v1/work/${workId}/requisitions/`;

export const getPartsUrl = () => url + `/v1/part?pageSize=10000`;
export const getGroupsByPartUrl = (partId, pieceWorkStartDate) =>
  url +
  `/v1/part/groups/${partId}?startdate=${pieceWorkStartDate}&pageSize=10000&orderBy=Identifier`;
export const getProductsByGroupUrl = (groupId, pieceWorkStartDate) =>
  url +
  `/v1/product/bygroup/${groupId}?startdate=${pieceWorkStartDate}&pageSize=10000`;

export const getSaveManyPriceListRowsUrl = (priceListId) =>
  url + `/v1/pricelist/${priceListId}/addproducts`;

export const getConfigurationUrl = () =>
  url + `/v1/admin/keywords/configuration`;

export const getWeeksUrl = (
  fromDate,
  toDate = '',
  workId,
  numberOfWeeks = null
) => {
  let weekUrl =
    url + `/v1/time/${workId}/weeks?from=${fromDate}&to=${toDate || ''}`;
  if (numberOfWeeks !== null) weekUrl += `&numberOfWeeks=${numberOfWeeks}`;
  return weekUrl;
};

export const getTimeForWorkersUrl = (workerIds, year, week, workId) =>
  url +
  `/v1/work/${workId}/time?workers=${workerIds}&year=${year}&week=${week}&pageSize=10000`;
//export const getTimeForAllWorkersUrl = (pieceWorkId, year, week) => url + `/v1/work/${pieceWorkId}time?year=${year}&week=${week}`;
export const getDeleteTimeRegistrationByIdUrl = (id) => url + `/v1/time/${id}`;
export const getAddTimeRegistrationUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/time`;
export const getTimeSummaryUrl = (
  pieceWorkId,
  fromYear,
  fromWeek,
  toYear,
  toWeek
) => {
  return (
    url +
    `/v1/work/${pieceWorkId}/timesummary?fromYear=${fromYear}&fromWeek=${fromWeek}&toYear=${toYear}&toWeek=${toWeek}&pageSize=10000`
  );
};

// Agreements
export const getAgreedExtensionsByPieceWorkIdUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/agreement/agreedextension`;
export const getHourlyRateByPieceWorkIdUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/hourlyrate`;
export const getSaveHourlyRateUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/hourlyrate`;
export const saveAgreedExtensions = () =>
  url + `/v1/work/agreement/agreedextension`;

export const getOtherAgreementsByPieceWorkIdUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/agreement/other`;
export const getSaveOtherAgreementUrl = () => url + `/v1/work/agreement/other`;
export const getDeleteOtherAgreementUrl = (agreementId) =>
  url + `/v1/work/agreement/other/${agreementId}`;
export const getOtherAgreementFileUploadUrl = (agreementId) =>
  url + `/v1/work/agreement/other/${agreementId}/files`;
export const getOtherAgreementFileUrl = (agreementId, fileId) =>
  url + `/v1/work/agreement/other/${agreementId}/files/${fileId}`;
export const getDeleteOtherAgreementFileUrl = (agreementId, fileId) =>
  url + `/v1/work/agreement/other/${agreementId}/files/${fileId}`;

export const getAllAgreementsUrl = (pieceWorkId, status) => {
  let returnUrl = url + `/v1/work/${pieceWorkId}/agreements?pageSize=10000`;
  if (status !== '') returnUrl += '&status=' + status;
  return returnUrl;
};
export const getApprovedAgreementsUrl = (pieceWorkId) =>
  url + `/v1/work/agreements/approved?pageSize=10000&orderBy=name`;
export const getAddAgreementsUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/agreements`;
export const getAgreementUrl = (agreementId) =>
  url + `/v1/pieceworkagreement/${agreementId}`;
export const getAddAgreementRowUrl = (agreementId) =>
  url + `/v1/pieceworkagreement/${agreementId}`;

export const getDeleteAgreementUrl = (agreementId, reason) =>
  url + `/v1/pieceworkagreement/${agreementId}?reason=${reason}`;

export const getEditStatusAgreementUrl = (agreementId, status, reason) =>
  url +
  `/v1/pieceworkagreement/${agreementId}/status?s=` +
  status +
  `&r=` +
  reason;

export const getActivateStatusAgreementUrl = (agreementId, transitionId) =>
  url + `/v1/pieceworkagreement/${agreementId}/status/${transitionId}/activate`;

export const getUpdateAgreementUrl = () => url + `/v1/pieceworkagreement`;
export const getUpdateAgreementRowUrl = () =>
  url + `/v1/pieceworkagreement/row`;
export const getDeleteAgreementRowUrl = (rowId) =>
  url + `/v1/pieceworkagreement/row/${rowId}`;
export const getAgreementRowsUrl = (pieceWorkId) =>
  url +
  `/v1/work/${pieceWorkId}/agreements/rows?pageSize=10000&orderBy=createdat&desc=false`;
export const getAgreementMetaDataUrl = () =>
  url + `/v1/pieceworkagreement/metadata`;
export const getGroupsForAgreementUrl = (agreementType) =>
  url +
  `/v1/group/foragreement?type=${agreementType}&pageSize=10000&orderBy=identifier`;
export const getSave0507AgreementUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/agreements`;
export const getAgreementFileUrl = (agreementId, fileId) =>
  url + `/v1/pieceworkagreement/${agreementId}/files/${fileId}`;

export const getAgreementWeeksUrl = (
  fromDate,
  toDate = '',
  numberOfWeeks = null
) => {
  let weekUrl = url + `/v1/time/weeks?from=${fromDate}&to=${toDate || ''}`;
  if (numberOfWeeks !== null) weekUrl += `&numberOfWeeks=${numberOfWeeks}`;
  return weekUrl;
};

export const getAllLocalAdvancesUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/agreement/advance`;
export const getLocalAdvanceByIdUrl = (advanceId) =>
  url + `/v1/work/agreement/advance/${advanceId}`;
export const getDeleteAdvanceByIdUrl = (advanceId) =>
  url + `/v1/work/agreement/advance/${advanceId}`;
export const getUploadFilesToAdvanceUrl = (advanceId) =>
  url + `/v1/work/agreement/advance/${advanceId}/files`;
export const getAdvanceFileUrl = (advanceId, fileId) =>
  url + `/v1/work/agreement/advance/${advanceId}/files/${fileId}`;
export const getDeleteAdvanceFileUrl = (advanceId, fileId) =>
  url + `/v1/work/agreement/advance/${advanceId}/files/${fileId}`;
export const getUpdateAdvanceUrl = () => url + `/v1/work/agreement/advance`;
export const getAddAdvanceUrl = () => url + `/v1/work/agreement/advance`;

//Requisitions
export const getRequisitionByIdUrl = (requisitionId) =>
  url + `/v1/pieceworkrequisition/${requisitionId}`;
export const getAddRequisitionUrl = (pieceWorkId) =>
  url + `/v1/work/${pieceWorkId}/requisitions`;
export const getAllRequisitionsUrl = (pieceWorkId, status) => {
  let returnUrl = url + `/v1/work/${pieceWorkId}/requisitions?pageSize=10000`;
  if (status !== '') returnUrl += '&status=' + status;
  return returnUrl;
};
export const getApprovedRequisitionsUrl = (pieceWorkId) =>
  url + `/v1/work/requisitions/approved?pageSize=10000&orderBy=name`;
export const getUpdateRequisitionUrl = () => url + `/v1/pieceworkrequisition`;

export const getEditRequisitionStatusUrl = (requisitionId, status, reason) =>
  url +
  `/v1/pieceworkrequisition/${requisitionId}/status?s=` +
  status +
  `&r=` +
  reason;

export const getActivateRequirementStatusUrl = (requisitionId, transitionId) =>
  url +
  `/v1/pieceworkrequisition/${requisitionId}/status/${transitionId}/activate`;

export const getDeleteRequisitionUrl = (requisitionId, reason) =>
  url + `/v1/pieceworkrequisition/${requisitionId}?reason=${reason}`;
export const getAddRequisitionRowUrl = (requisitionId) =>
  url + `/v1/pieceworkrequisition/${requisitionId}`;
export const getRequisitionRowsUrl = (workId) =>
  url +
  `/v1/work/${workId}/requisitions/rows?pageSize=10000&orderBy=createdAt&desc=true`;
export const getUpdateRequisitionRowUrl = () =>
  url + `/v1/pieceworkrequisition/row`;
export const getDeleteRequisitionRowUrl = (rowId) =>
  url + `/v1/pieceworkrequisition/row/${rowId}`;
export const getRequisitionFileUrl = (requisitionId, fileId) =>
  url + `/v1/pieceworkrequisition/${requisitionId}/files/${fileId}`;

//Files
export const getFileUrl = (fileId) => url + `/v1/files/${fileId}`;
export const getUpdateFileCommentUrl = (fileId) =>
  url + `/v1/files/${fileId}/comment`;
export const getDeleteFileUrl = (fileId) => url + `/v1/files/${fileId}`;

// Calculation
export const getCalculationByPieceWorkIdUrl = (workId) =>
  url + `/v1/calculation/bypieceworkid/${workId}?pageSize=10000`;
export const getCalcutionNumberOfPagesUrl = (
  distributionId,
  distributionType
) =>
  url +
  `/v1/work/distribution/${distributionId}/type/${distributionType}/nrPages`;

//OLD export const getCalculationReportPagesUrl = (workId) =>
//url + `/v1/work/${workId}/sentin/edition`;
export const getCalculationReportPagesUrl = (workId) =>
  url + `/v1/work/${workId}/edition/calculateNumberOfPages`;

export const getLatestDistributionUrl = (workId) =>
  url + `/v1/work/${workId}/distribution/latest`;
export const getUploadDistributionFileUrl = (
  distributionId,
  distributionType
) =>
  url +
  `/v1/work/distribution/${distributionId}/type/${distributionType}/files`;
export const getRowsForDistributionUrl = (distributionId) =>
  url + `/v1/work/distribution/${distributionId}/rows`;
export const getDistributionFileUrl = (rowId, fileId) =>
  url + `/v1/work/distribution/${rowId}/files/${fileId}`;
export const getDeleteDistributionRowUrl = (rowId) =>
  url + `/v1/work/distribution/row/${rowId}`;
export const getDistributionListUrl = (workId) =>
  url +
  `/v1/work/${workId}/distribution?pageSize=10000&orderBy=versionNumber&desc=true`;

// Worker registry
export const getWorkerRegistryUrl = () =>
  url + `/v1/my/workers?pageSize=10000&orderBy=firstName`;
export const getSaveWorkerToRegistryUrl = () => url + `/v1/my/workers`;
export const getDeleteWorkerFromRegistryUrl = (workerId) =>
  url + `/v1/my/workers/${workerId}`;
export const getIsMemberUrl = (membershipNumber) =>
  url + `/v1/registry/members/${membershipNumber}/ismember`;
export const getMyWorkerByMemberShipNumberUrl = (membershipNumber) =>
  url + `/v1/my/workers?membershipNumber=${membershipNumber}`;

export const getEmployerByIdUrl = (id) => url + `/v1/employer/${id}`;

// Admin
export const getSpecialAdditionsUrl = () =>
  url + `/v1/admin/specialadditions/all`;
export const getAllKeywordsUrl = (key) => url + `/v1/admin/keywords/all`;
export const getKeywordByKeyUrl = (key) =>
  url + `/v1/admin/keywords/key/${key}`;
export const getSaveKeywordUrl = () => url + `/v1/admin/keywords/save`;
export const getDeleteKeywordUrl = (keywordId) =>
  url + `/v1/admin/keywords/remove/${keywordId}`;

// Excel
export const getExcelFromListUrl = () => url + `/v1/work/search/export`;

//TODO: Do not use these two functions anymore, exists in api/apihelper. Refactor files that uses these methods.
export const authHeader = () => {
  let user = JSON.parse(localStorage.getItem('userAuth'));
  if (user) {
    return { Authorization: 'Bearer ' + user };
  } else {
    return {};
  }
};
export const addJsonHeader = (header) => {
  return {
    ...header,
    'content-type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  };
};

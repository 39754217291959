export const getUrl = () => {
  const hostName = window && window.location && window.location.hostname;
  if (process.env.NODE_ENV === "development") {
    return (
      process.env.REACT_APP_API_ENDPOINT ||
      window.location.protocol + "//localhost:5001"
    );
  }
  switch (hostName) {
    case "akkord.no":
      return "https://api.akkord.no";
    case "akkord-no-frontend-develop.azurewebsites.net":
      return "https://akkord-no-api-develop.azurewebsites.net";
    case "akkord-no-frontend-systest.azurewebsites.net":
      return "https://akkord-no-api-systest.azurewebsites.net";
    case "acctest.akkord.no":
      return "https://acctest-api.akkord.no";
    case "prodtest.akkord.no":
      return "https://prodtest-api.akkord.no";
    case "trening.akkord.no":
      return "https://trening-api.akkord.no";
    case "acctest.akkordportalen.no":
      return "https://acctest-api.akkord.no";
    case "prodtest.akkordportalen.no":
      return "https://prodtest-api.akkord.no";
    case "trening.akkordportalen.no":
      return "https://trening-api.akkord.no";
    case "akkord-no-frontend-acctest.azurewebsites.net":
      return "https://akkord-no-api-acctest.azurewebsites.net";
    default:
      return "https://api.akkord.no";
  }
};

export const authHeader = () => {
  let user = JSON.parse(localStorage.getItem("userAuth"));
  if (user) {
    return { Authorization: "Bearer " + user };
  } else {
    return {};
  }
};

export const addJsonHeader = (header) => {
  return {
    ...header,
    "content-type": "application/vnd.api+json",
    Accept: "application/vnd.api+json",
  };
};

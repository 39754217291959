import { userConstants } from 'store/constants/UserConstants';

const initialState = {
	isLoading: false,
	authorized: false,
	token: ''
};

export const AuthReducer = (state = initialState, action) => {

	if (action.type === userConstants.GETAUTHUSER) {
		return {
			...state,
			isLoading: false
		};
	}

	if (action.type === userConstants.SETAUTHUSER) {
		return {
			...state,
			authorized: true,
			isLoading: false,
			token: action.token
		};
	}



	return state;
};

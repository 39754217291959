export const styles = theme => ({
	modalContainer: {
		width: '450px',
		height: '100%',
	},
	header: {
		display: 'flex',
		borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
	},
	headerBlock: {
		display: 'block',
		borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
	},
	headerWide: {
		display: 'flex',
		borderBottom: `0`,
		flexWrap: 'wrap',
		paddingBottom: 0,
	},
	headerWidePadding: {
		display: 'flex',
		borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
		flexWrap: 'wrap',
	},
	headerWithContent: {
		display: 'flex',
		flexDirection: 'column',
		borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
		flexWrap: 'wrap',
		paddingBottom: 0,
	},
	headline: {
		color: theme.colors.text.darkGrey
	},
	headlineProgressSpinner: {
		marginLeft: theme.spacing(1)
	},
	bgWhite: {
		backgroundColor: theme.palette.common.white,
	},
	closeIcon: {
		fontSize: 20,
		color: theme.colors.icons.darkerGrey,
		marginLeft: 'auto',
		cursor: 'pointer',
	},
	fullWidthLightGreyContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: theme.spacing(-3),
		marginRight: theme.spacing(-3),
		padding: theme.spacing(1.5, 3),
		backgroundColor: theme.colors.background.lightGrey,
	},
	bold: {
		fontWeight: 600,
	},
	formContainer: {
		// width: '450px',
		padding: '30px',
		backgroundColor: '#fff',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	fieldContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%',
	},
	formPreview: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%',
		width: '60%',
    	margin: '0 auto',
    	marginTop: '50px',
	},
	employeeCard: {
		backgroundColor: theme.colors.background.lightGrey,
		// TODO: Standardize border colors?
		border: '2px solid #f8f8f8',
		width: '100%',
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	changeBtn: {
		marginLeft: 'auto',
		// backgroundColor: theme.colors.primary,
		// color: '#fff'
	},
	listContainer: {
		backgroundColor: theme.colors.background.lightGrey,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2.5),
	},
	listContainerFlexColumn: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.background.lightGrey,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2.5),
	},
	listItem: {
		marginBottom: theme.spacing(1)
	},
	listItemCloseIcon: {
		color: theme.colors.text.grey,
		fontSize: theme.sizes.text.sm,
	},
	listItemCloseTemplateModal: {
		color: theme.colors.text.grey,
		fontSize: theme.sizes.text.sm,
		marginLeft: theme.spacing(1)
	},
	textDarkGrey: {
		color: theme.colors.text.darkGrey,
	},
	selectedText: {
		fontSize: theme.sizes.text.xs,
		fontWeight: 'bold',
		color: theme.colors.text.darkGrey,
	},
	selectedCount: {
		fontSize: theme.sizes.text.xs,
		fontWeight: 'bold',
		color: theme.colors.text.darkestGrey,
	},
	formControl: {
		width: '100%',
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1)
	},
	icon: {
		color: theme.colors.icons.grey,
	},
	sectionTitle: {
		fontWeight: 600
	},
	selectedContainer: {
		paddingTop: theme.spacing(1.5),
		borderTop: `2px solid ${theme.colors.borders.lighterGrey}`,
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	flexColumnText: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: {
			height: '100%',
		}
	},
	textSmall: {
		fontSize: theme.sizes.text.sm,
	},
	textExtraSmall: {
		fontSize: theme.sizes.text.xs,
	},
	actionButtons: {
		display: 'flex',
		flex: 'none',
		marginTop: 'auto',
		marginBottom: 0,
		backgroundColor: theme.colors.background.lightGrey,
		padding: theme.spacing(3.5, 2.5),
		borderTop: `1px solid ${theme.colors.borders.darkGrey}`,
	},
	actionButtonsRight: {
		marginLeft: 'auto',
	},
	buttonMargin: {
		marginRight: theme.spacing(1.5),
	},
	addButton: {
		flex: '0 0 auto',
		marginLeft: theme.spacing(1.5),
	},
	removeButton: {
		flex: '0 0 auto',
		marginLeft: 12,
		backgroundColor: theme.colors.danger,
		borderColor: theme.colors.danger,
	},
	inputError: {
		borderColor: `${theme.colors.danger} !important`,
	},
	inputSuccess: {
		borderColor: `${theme.colors.status.green} !important`,
	},
	statusText: {
		display: 'inline-flex'
	},
	approvedRejectedDate: {
		marginRight: theme.spacing(1),
		flex: `0 0 ${theme.spacing(13)}px`,
	},
	topInfoContainer: {
		padding: theme.spacing(0, 0.5),
	},
	topInfoTitle: {
		fontWeight: 600,
		marginRight: theme.spacing(0.5),
	},
	topInfoContent: {
		display: 'inline-block',
		fontWeight: 600
	},
	templateRowName: {
		padding: theme.spacing(0.5)
	},
	markdownEditor: {
		marginTop: '5px',
		marginBottom: '8px',
		width: '100%',
	},
	markdownSource: {
		marginTop: '5px',
		marginBottom: '8px',
		border: '1px solid #dedfe0',
		padding: '10px',
		borderRadius: '5px',
		width: '100%',
		height: '100%',
		overflow: 'hidden'
	},
	tabsRoot: {
		borderBottom: `1px solid ${theme.colors.borders.darkGrey}`,
		marginBottom: -1,
	},
	bodyTitle: {
		transform: 'scale(0.75)',
		marginTop: '10px'
	},
	downloadReport: {
		marginTop: theme.spacing(2.5),
		display: 'inline-flex',
		textDecoration: 'none',
		color: 'inherit',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	fileCard: {
        marginBottom: theme.spacing(2),
        '&:last-child': {
            marginBottom: 0,
        }
	},
	fileCardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 1, 1, 2.5),
	},
	fabContainer: {
		display: 'block',
	},
	fab: {
        margin: theme.spacing(1),
        color: theme.colors.text.darkestGrey,
        fontSize: theme.sizes.text.md,
        border: `1px solid #dededf`,
        backgroundColor: '#f8f8f8'
	},
	fabLink: {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
        fontSize: theme.sizes.text.md,
        color: theme.colors.primary,
        ':&hover': {
            textDecoration: 'underline',
        }
	},
	fabDisabled: {
		backgroundColor: '#f8f8f8 !important',
		color: `${theme.colors.icons.darkestGrey} !important`,
	},
	infoLabel: {
		color: theme.colors.text.darkestGrey,
		fontSize: theme.sizes.text.sm,
		fontWeight: 600
	},
	warningIcon: {
		color: theme.colors.icons.yellow,
	},
	dateRangeIcon: {
		fontSize: '18px', 
		color:'#3e3e3e',
		cursor: 'pointer',
	},
	marginTop: {
		marginTop: '12px',
	}
});

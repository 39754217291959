import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';



import { styles } from 'styles/layout/LayoutStyle';

const SuperAdminListItems = (props) => {
    const {
        classes,
        closeDrawerIfMobile
    } = props;

    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <div>
            <ListItem
                button
                component={ForwardedNavLink}
                to="/AppVersion"
                title="Status"
                className={classes.menuListItem}
                activeclassname={classes.menuListItemActive}
            >
                <ListItemIcon className={classes.menuListItemIcon}>
                    <RemoveRedEyeOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Status"
                    primaryTypographyProps={{
                        classes: {
                            root: classes.menuListItemText,
                        }
                    }}
                />
            </ListItem>
            <ListItem
                button
                title="Configuration"
                component={ForwardedNavLink}
                to="/configuration"
                onClick={closeDrawerIfMobile}
                className={classes.menuListItem}
                activeclassname={classes.menuListItemActive}
            >
                <ListItemIcon className={classes.menuListItemIcon}>
                    <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Configuration"
                    primaryTypographyProps={{
                        classes: {
                            root: classes.menuListItemText,
                        }
                    }}
                />
            </ListItem>
            <ListItem
                button
                title="Systemtext"
                component={ForwardedNavLink}
                /* to="/systemtext" */
                to="/NotImplemented/Systemtext"
                onClick={closeDrawerIfMobile}
                className={classes.menuListItem}
                activeclassname={classes.menuListItemActive}
            >
                <ListItemIcon className={classes.menuListItemIcon}>
                    <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Systemtext"
                    primaryTypographyProps={{
                        classes: {
                            root: classes.menuListItemText,
                        }
                    }}
                />
            </ListItem>
        </div>
    );
};

export default withStyles(styles)(SuperAdminListItems);




import { useState } from 'react';

const useMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    return {
        anchorEl,
        isMenuOpen,
        handleMenuClick,
        handleMenuClose,
        setIsMenuOpen
    };
}

export default useMenu;

import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router';
import NotFound from 'routes/NotFound';
import RouteContainer from 'RouteContainer';
import Layout from 'components/layout/Layout';

const AsyncFederationStartPage = (
    lazy(() => (
        import(/* webpackChunkName: "FederationStartPage" */ 'pages/federation/FederationStartPage')
    ))
);

const AsyncMessages = (
    lazy(() => (
        import(/* webpackChunkName: "Messages" */ 'pages/messages/Messages')
    ))
);

const AsyncNews = (
    lazy(() => (
        import(/* webpackChunkName: "News" */ 'pages/news/News')
    ))
);

const AsyncSingleNews = (
    lazy(() => (
        import(/* webpackChunkName: "SingleNews" */ 'pages/news/SingleNews')
    ))
);

const AsyncSuggestions = (
    lazy(() => (
        import(/* webpackChunkName: "Suggestions" */ 'pages/suggestions/Suggestions')
    ))
);

const AsyncSuggestion = (
    lazy(() => (
        import(/* webpackChunkName: "Suggestion" */ 'pages/suggestions/Suggestion')
    ))
);

const AsyncEmployers = (
    lazy(() => (
        import(/* webpackChunkName: "EmployerList" */ 'pages/employerList/EmployerList')
    ))
);

const AsyncMembers = (
    lazy(() => (
        import(/* webpackChunkName: "Members" */ 'pages/members/Members')
    ))
);

const AsyncLibrary = (
    lazy(() => (
        import(/* webpackChunkName: "Library" */ 'pages/library/Library')
    ))
);

const AsyncTrashbin = (
    lazy(() => (
        import(/* webpackChunkName: "Trashbin" */ 'pages/trashbin/Trashbin')
    ))
);

const AsyncPieceWork = (
    lazy(() => (
        import(/* webpackChunkName: 'PieceWork' */ "pages/pieceWork/PieceWork")
    ))
);

const AsyncUserList = (
    lazy(() => (
        import(/* webpackChunkName: "UserListPage" */ 'pages/federation/UserListPage')
    ))
);

const RouteFederation = () => {
    return (
        <Layout> 
            <RouteContainer>
                <Route path="/start" element={<AsyncFederationStartPage/>} />
                <Route path="/akkordunderlag/:id" element={<AsyncPieceWork/>} />
                <Route exact path="/meldinger" element={<AsyncMessages/>} />
                <Route exact path="/aktuelt" element={<AsyncNews/>} />
                <Route path="/aktuelt/:id" element={<AsyncSingleNews/>} />
                <Route exact path="/forslagskasse" element={<AsyncSuggestions/>} />
                <Route path="/forslagskasse/:id" element={<AsyncSuggestion/>} />
                <Route path="/arbeidsgiver" element={<AsyncEmployers/>} />
                <Route path="/medlemmer" element={<AsyncMembers/>} />
                <Route path="/kontoadministrasjon" element={<AsyncUserList/>} />

                {/* fråga : i systest inloggad som jakob leder denna till start */}
                <Route path="/statistikk" element={<AsyncFederationStartPage/>} />

                <Route path="/arkiv" element={<AsyncLibrary/>} />
                <Route path="/papirkurv" element={<AsyncTrashbin/>} />
                
                <Route path="/" element={<Navigate to="/start" replace />}/>
                <Route path="*" element={<NotFound/>} />
            </RouteContainer>
        </Layout> 
    );
};

export default RouteFederation;
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router';
import NotFound from 'routes/NotFound';
import RouteContainer from 'RouteContainer';
import Layout from 'components/layout/Layout';

const AsyncAdminStartPage = (
    lazy(() => (
        import(/* webpackChunkName: "AdminStartPage" */ 'pages/admin/AdminStartPage')
    ))
);

const AsyncConfiguration = (
    lazy(() => (
        import(/* webpackChunkName: "ConfigurationPage" */ 'pages/admin/configuration/Configuration')
    ))
);

const AsyncAppVersion = (
    lazy(() => (
        import(/* webpackChunkName: "AppVersion" */ 'pages/admin/appVersion/AppVersion')
    ))
);

const AsyncCentralRates = (
    lazy(() => (
        import(/* webpackChunkName: "CentralRates" */ 'pages/admin/centralRates/CentralRates')
    ))
);

const AsyncSpecialAdditions = (
    lazy(() => (
        import(/* webpackChunkName: "SpecialAdditions" */ 'pages/admin/specialAdditions/SpecialAdditions')
    ))
);

const AsyncCentralTemplateList = (
    lazy(() => (
        import(/* webpackChunkName: "CentralTemplateList" */ 'pages/admin/CentralTemplateListPage')
    ))
);

const AsyncCentralTemplate = (
    lazy(() => (
        import(/* webpackChunkName: "CentralTemplate" */ 'pages/admin/CentralTemplatePage')
    ))
);

const AsyncFileLibrary = (
    lazy(() => (
        import(/* webpackChunkName: "FileLibrary" */ 'pages/admin/fileLibrary/FileLibrary')
    ))
);

const AsyncUserList = (
    lazy(() => (
        import(/* webpackChunkName: "UserListPage" */ 'pages/federation/UserListPage')
    ))
);

const AsyncJobList = (
    lazy(() => (
        import(/* webpackChunkName: "AdminJobListPage" */ 'pages/admin/AdminJobListPage')
    ))
);

const AsyncPieceWork = (
    lazy(() => (
        import(/* webpackChunkName: 'PieceWork' */ "pages/pieceWork/PieceWork")
    ))
);

const AsyncNotImplemented = (
    lazy(() => (
        import(/* webpackChunkName: "PageNotImplemented" */ 'pages/admin/AdminPageNotImplemented')
    ))
);

const RouteFederation = () => {

    return (
        <Layout> 
            <RouteContainer>
                <Route path="/start" element={<AsyncAdminStartPage/>} />
                <Route path="/AppVersion" element={<AsyncAppVersion/>} />
                <Route path="/configuration" element={<AsyncConfiguration/>} />
                <Route path="/sentralesatser" element={<AsyncCentralRates/>} />
                <Route path="/spesielletillegg" element={<AsyncSpecialAdditions/>} />
                <Route exact path="/sentralemaler" element={<AsyncCentralTemplateList/>} />
                <Route path="/sentralemaler/:id" element={<AsyncCentralTemplate/>} />
                <Route path="/filbibliotek" element={<AsyncFileLibrary/>} />
                <Route path="/kontoadministrasjon" element={<AsyncUserList/>} />
                <Route path="/akkordliste" element={<AsyncJobList/>} />
                <Route path="/akkordunderlag/:id" element={<AsyncPieceWork/>} />
                <Route path="/NotImplemented/:page" element={<AsyncNotImplemented/>} />

                <Route path="/" element={<Navigate to="/start" replace />}/>
                <Route path="*" element={<NotFound/>} />
            </RouteContainer>
        </Layout> 
    );

};

export default RouteFederation;
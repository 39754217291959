import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import useForm from 'components/common/useForm';

import { pieceWorkConstants } from 'store/constants/PieceWorkConstants';
import { pieceWorkService } from 'store/services/PieceWorkService';
import { snackbarConstants } from 'store/constants/SnackbarConstants';

import { pieceWorkActionCreators } from 'store/actions/PieceWorkActions';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    formListItem: {
        padding: '0 20px',
        fontSize: '1rem',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#fafafa',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        backgroundColor: theme.palette.background.paper
    },
});

const FormList = (props) => {

    const {
        classes,
        dispatch,
        pieceWork,
        workId,
        tabIndex,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const { values, handleChange, handleCheckboxChange, setValues } = useForm([]);
    const setValuesCb = useCallback(setValues, []);

    const getFormList = useCallback(async () => {
        try {
            const list = await pieceWorkService.getPieceWorkCheckListForm(workId);
            setValuesCb((values) => ({
                ...values,
                setupComment: list.data[0].setupComment,
                id: list.data[0].id,
                error: false,
                setupIsConfirmed: list.data[0].setupIsConfirmed
            }));
        }
        catch (error) {
            setValuesCb(values => (
                {
                    ...values,
                    error: true
                })
            )
        }

    }, [setValuesCb, workId])

    useEffect(() => {
        getFormList();
    }, [getFormList])

    const handleToggleForm = async (event) => {

        handleCheckboxChange(event);

        const myObject = {
            ...values,
            id: pieceWork.id,
            setupIsConfirmed: event.target.checked
        }
        try {
            setIsLoading(true);
            await pieceWorkService.setCheckListFormItemConfirmed(myObject);
            dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Sjekkliste ble lagret' } });
            if (myObject.setupIsConfirmed) {
                dispatch({ type: pieceWorkConstants.CHECKLIST_FORM_DONE, payload: true })
            }
            else {
                dispatch({ type: pieceWorkConstants.CHECKLIST_FORM_DONE, payload: false })
            }
        }
        catch (e) {
            dispatch({ type: snackbarConstants.ALERT_ERROR, payload: { message: '' + e + '' } });
            return false;
        }
        finally {
            dispatch(pieceWorkActionCreators.requestPieceWork(workId));
            setIsLoading(false);
        }
    };

    return (
        <>
            {!values.error ? (<form className={classes.form}>
                <List className={classes.root}>
                    <ListItem
                        role={undefined}
                        dense
                        disableRipple
                        disabled={isLoading}
                        button
                        className={classes.formListItem}
                        tabIndex={-1}
                    >
                        <TextField
                            required
                            id="setupComment"
                            name="setupComment"
                            autoComplete="off"
                            label="Kommentar"
                            value={values.setupComment || ''}
                            margin="normal"
                            variant="outlined"
                            multiline
                            fullWidth
                            minRows="4"
                            disabled={values.setupIsConfirmed}
                            onChange={handleChange}
                            key={tabIndex}
                        />
                    </ListItem>
                    <ListItem
                        role={undefined}
                        dense
                        disableRipple
                        disabled={isLoading}
                        button
                        className={classes.formListItem}
                        tabIndex={-1}
                    >
                        <ListItemIcon>
                            <Checkbox
                                key={values.setupIsConfirmed}
                                id="setupIsConfirmed"
                                name="setupIsConfirmed"
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={values.setupIsConfirmed}
                                onChange={(event) => handleToggleForm(event)}
                            />
                        </ListItemIcon>
                        <ListItemText style={{ padding: '0' }} primary={"Jeg er sikker på at informasjonen er riktig ved å klikke i ruten"} />
                    </ListItem>
                </List>
            </form>) : (null)}
        </>);
}

function mapStateToProps(state) {
    const { workId, isLoading, pieceWork } = state.PieceWorkState;
    return {
        isLoading,
        pieceWork,
        workId
    };
}

export default connect(mapStateToProps)(withStyles(styles)(FormList));
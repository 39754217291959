import React from 'react';

import CommonResponsibleListItems from 'components/menu/commonResponsibleListItems';
import CommonListItemsBottom from 'components/menu/commonListItemsBottom';

const MainListItems = (props) => {

    const { closeDrawerIfMobile } = props; 

    return (
        <>
            <CommonResponsibleListItems closeDrawerIfMobile={closeDrawerIfMobile} />

            <CommonListItemsBottom closeDrawerIfMobile={closeDrawerIfMobile} />

        </>
    )
}

export default MainListItems;

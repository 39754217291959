import { timeRegistrationConstants } from 'store/constants/TimeRegistrationConstants';

const initialState = {
	workers: [],
	showTimeRegistrationModal: false,
	isLoadingWeeks: false,
	detailsSelectedWorkerId: '',
	timeReportWeek: {},
	selectedWorkers: [],
	weeks: [],
	workerTimeList: [],
	filterSummaryList: '',
	timeSummary: [],
	totalTimeSummarized: []
};

export const TimeRegistrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case timeRegistrationConstants.GET_WORKERS:
			return {
				...state,
				isLoading: false
			};

		case timeRegistrationConstants.GET_WEEKS:
			return {
				...state,
				isLoading: false,
				weeks: action.payload
			};

		case timeRegistrationConstants.SHOW_TIME_REGISTRATION_MODAL:
			return {
				...state,
				showTimeRegistrationModal: !state.showTimeRegistrationModal,
				selectedWorkers: action.payload,
				timeReportWeek: action.week
			}


		case timeRegistrationConstants.GET_TIME_FOR_WORKERS:
			return {
				...state,
				workerTimeList: action.payload,
			}

		case timeRegistrationConstants.GET_TIME_SUMMARY:
			return {
				...state,
				timeSummary: action.payload[0].rows,
				totalTimeSummarized: action.payload[0],
			}

		case timeRegistrationConstants.SET_DETAILS_SELECTED_WORKER:
			return {
				...state,
				detailsSelectedWorkerId: action.payload
			}

		case timeRegistrationConstants.IS_LOADING:
			return {
				...state,
				isLoading: action.payload
			};
		case timeRegistrationConstants.SET_FILTER_TIME_SUMMARY:
			return {
				...state,
				filterSummaryList: action.payload
			};
		default:
			return state;
	}
};

import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router';
import NotFound from 'routes/NotFound';
import RouteContainer from 'RouteContainer';
import Startpage from 'pages/Startpage';
import Layout from 'components/layout/Layout';

const AsyncJobList = (
    lazy(() => (
        import(/* webpackChunkName: 'PieceWorkStartPage' */ "pages/pieceWork/PieceWorkStartPage")
    ))
);

const AsyncPieceWork = (
    lazy(() => (
        import(/* webpackChunkName: 'PieceWork' */ "pages/pieceWork/PieceWork")
    ))
);

const AsyncWorkerRegistry = (
    lazy(() => (
        import(/* webpackChunkName: 'WorkerRegistry' */ "pages/workerRegistry/WorkerRegistry")
    ))
);

const AsyncTemplateList = (
    lazy(() => (
        import(/* webpackChunkName: "TemplateList" */ 'pages/templates/TemplateList')
    ))
);

const AsyncTemplate = (
    lazy(() => (
        import(/* webpackChunkName: "Template" */ 'pages/templates/Template')
    ))
);

const AsyncMessages = (
    lazy(() => (
        import(/* webpackChunkName: "Messages" */ 'pages/messages/Messages')
    ))
);

const AsyncSearchPieceWork = (
    lazy(() => (
        import(/* webpackChunkName: "SearchPieceWorks" */ 'pages/clubDistrict/SearchPieceWork')
    ))
);

const AsyncSingleNews = (
    lazy(() => (
        import(/* webpackChunkName: "SingleNews" */ 'pages/news/SingleNews')
    ))
);

const AsyncLibrary = (
    lazy(() => (
        import(/* webpackChunkName: "Library" */ 'pages/library/Library')
    ))
);

const AsyncTrashbin = (
    lazy(() => (
        import(/* webpackChunkName: "Trashbin" */ 'pages/trashbin/Trashbin')
    ))
);

const RouteClubDistrict = () => {

    return (
        <Layout>
            <RouteContainer>
                <Route path="/start" element={<Startpage/>} />
                <Route exact path="/akkordunderlag" element={<AsyncJobList/>} />
                <Route exact path="/akkordunderlag/0" element={<AsyncJobList/>} />
                <Route path="/akkordunderlag/:id" element={<AsyncPieceWork/>} />
                <Route path="/montorbank" element={<AsyncWorkerRegistry/>} />
                <Route exact path="/maler" element={<AsyncTemplateList/>} />
                <Route path="/maler/:id" element={<AsyncTemplate/>} />
                <Route path="/meldinger" element={<AsyncMessages/>} />
                <Route path="/sok" element={<AsyncSearchPieceWork/>} />
                <Route path="/aktuelt/:id" element={<AsyncSingleNews/>} />
                <Route path="/arkiv" element={<AsyncLibrary/>} />
                <Route path="/papirkurv" element={<AsyncTrashbin/>} />

                <Route path="/" element={<Navigate to="/start" replace />}/>
                <Route path="*" element={<NotFound/>} />
            </RouteContainer>
        </Layout>
    );
};

export default RouteClubDistrict;

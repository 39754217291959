import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import colors from 'lib/helpers/Colors';
import sizes from 'lib/helpers/Sizes';

const theme = createTheme({
    palette: {
        accent: red,
        type: 'light',
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.success,
        },
        error: {
            main: colors.danger,
        },
        text: {
            primary: colors.text.grey,
            secondary: colors.text.darkestGrey
        }
    },
    typography: {
        fontFamily: [
            'Source Sans Pro'
        ],
        fontSize: 16,
        htmlFontSize: 16,
        h1: {
            fontSize: sizes.text.xl,
            fontWeight: '600',
            color: colors.text.darkestGrey,
        },
        h2: {
            fontSize: sizes.text.lg,
            fontWeight: '600',
            color: colors.text.darkestGrey,
        },
        h3: {
            fontSize: sizes.text.md,
            fontWeight: '600',
            color: colors.text.darkestGrey,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.38,
        },
        body2: {
            fontSize: '1rem',
            lineHeight: 1.38,
        },
        subtitle1: {
            color: colors.text.darkGrey,
            fontSize: sizes.text.xs,
            fontWeight: '600',
            lineHeight: 1.85,
        },
        subtitle2: {
            color: colors.text.highContrast,
            fontSize: sizes.text.xs,
            fontWeight: '600',
        },

    },
    colors: colors,
    sizes: sizes,
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                height: 48,
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.13)',
            },
            elevation2: {
                boxShadow: '0 1px 15px 1px rgba(69, 65, 78, 0.08)',
            },
        },
        MuiButton: {
            root: {
                fontSize: sizes.text.lg,
                fontWeight: '600',
                padding: '4px 24px',
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                backgroundColor: colors.buttons.grey,
                color: '#fff',
            },
            containedPrimary: {
                color: '#fff',
            },
            containedSecondary: {
                color: '#fff',
            },
            outlined: {
                backgroundColor: '#fff',
                color: colors.text.darkGrey,
                border: '2px solid ' + colors.borders.grey,
                '&:hover': {
                    backgroundColor: colors.background.grey,
                },
            },
        },
        MuiIconButton: {
            root: {
                color: colors.text.darkGrey,
                padding: 6,
                '&:hover': {
                    backgroundColor: "$labelcolor",
                    color: colors.text.darkestGrey
                }
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 12,
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: sizes.text.md,
                color: colors.text.grey,
            },
        },
        MuiFormHelperText: {
            contained: {
                margin: '8px 0 0',
            },
        },
        MuiInputBase: {
            root: {
                color: colors.text.darkestGrey,
                lineHeight: '1.2rem',
            },
            input: {
                fontSize: sizes.text.md,
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 16px) scale(1)',
                '&$marginDense': {
                    transform: 'translate(14px, 12px) scale(1)',
                }
            },
        },
        MuiOutlinedInput: {
            root: {
                '& $notchedOutline': {
                    borderColor: colors.borders.lightGrey,
                },
            },
            input: {
                padding: '14.5px 14px',
            },
            inputMarginDense: {
                paddingTop: '10.5px',
                paddingBottom: '10.5px',
            }
        },
        MuiTabs: {
            root: {
                textTransform: 'uppercase',
            },
            scrollButtons: {
                width: 40,
            },
            indicator: {
                backgroundColor: colors.brand.red,
            },
        },
        MuiTab: {
            root: {
                fontWeight: 'bold',
            },
            textColorInherit: {
                '&$selected': {
                    color: colors.text.darkestGrey,
                },
                '&:hover': {
                    color: colors.text.darkestGrey,
                    opacity: 1
                },
                '&:focus': {
                    color: colors.text.darkestGrey,
                }
            }
        },
        MuiFab: {
            root: {
                color: colors.icons.lightGrey,
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '2px solid ' + colors.borders.lightGrey,
                boxShadow: 'none',
            },
            secondary: {
                color: '#fff',
                border: 0,
            },
        },
        MuiCheckbox: {
            root: {
                color: colors.borders.darkGrey,
            },
        },
        MuiTableRow: {
            root: {
                '&$hover:hover': {
                    backgroundColor: colors.hover.lightBlue,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: sizes.text.xl,
            }
        },
        MuiTableCell: {
            head: {
                fontSize: sizes.text.xs,
                fontWeight: 600,
            },
            root: {
                borderColor: colors.borders.lightGrey,
                padding: 8,
                paddingLeft: 16,
            },
        },
        MuiMenuItem: {
            root: {
                borderBottom: '1px solid ' + colors.borders.lightGrey,
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: colors.background.grey,
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: sizes.text.xs,
                padding: '8px 12px',
            }
        },
        MuiTableSortLabel: {
            icon: {
                fontSize: sizes.text.sm,
            }
        }
    }
});

export default theme;

const locale = "nb-NO";

const formatNumber = (number) => {
  if (typeof number === "string" && !isNaN(parseFloat(number)))
    number = parseFloat(number);
  if (typeof number !== "number") return number;
  return number.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const formatMultiplierNumber = (number) => {
  if (typeof number === "string" && !isNaN(parseFloat(number)))
    number = parseFloat(number);
  if (typeof number !== "number") return number;
  return number.toLocaleString(locale, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

const NumberHelpers = {
  formatNumber,
  formatMultiplierNumber,
};

export default NumberHelpers;

import { tariffConstants } from 'store/constants/TariffConstants';

const initialState = {
	parts: [],
	groups: [],
	products: [],
	isLoading: false
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case tariffConstants.GET_PARTS:
			return {
				...state,
				isLoading: true,
			};

        case tariffConstants.SET_PARTS:
            return {
                ...state,
                isLoading: false,
                parts: action.payload,
			};

		case tariffConstants.GET_GROUPS:
			return {
				...state,
				isLoading: true,
			};

		case tariffConstants.SET_GROUPS:
			return {
				...state,
				isLoading: false,
				groups: action.payload,
			};
			
		case tariffConstants.GET_PRODUCTS:
			return {
				...state,
				isLoading: true,
			};

        case tariffConstants.SET_PRODUCTS:
            return {
                ...state,
                isLoading: false,
                products: action.payload,
            };

		default:
			return state;
	}
};

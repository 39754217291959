import { priceListConstants } from '../constants/PriceListConstants';

const initialState = {
	isLoading: false,
	isLoadingPriceLists: false,
	isLoadingPriceListImages: false,
	showQuickAddModal: false,
	showAddFromTariffModal: false,
	showAddFromPriceListModal: false,
	showAddFromTemplateModal: false,
	showAddFromCentralTemplateModal: false,
	showEditImageModal: false,
	imageIndex: 0,
	priceListRow: {},
	priceListRows: [],
	priceLists: [],
	priceList: {},
	priceListFiles: [],
	filterPriceRows: '',
	filterPriceRowAddons: false,
	filterPriceRowSpecialAddons: false,
	nextPriceListIndexAfterDelete: 0,
	metaData: [],
	metaDataSelects: { factor: [], inv: [], inventorySupplement: [], height: [], conditions: [] }
};

export const PriceListReducer = (state = initialState, action) => {
	switch (action.type) {
		case priceListConstants.GET_PRICEROWS:
			return {
				...state,
				isLoading: false
			};

		case priceListConstants.SHOW_QUICKADD_MODAL:
			return {
				...state,
				showQuickAddModal: !state.showQuickAddModal,
				priceListRow: action.payload
			};

		case priceListConstants.SHOW_ADD_FROM_TARIFF_MODAL:
			return {
				...state,
				showAddFromTariffModal: !state.showAddFromTariffModal,
			};

		case priceListConstants.SHOW_PRICELIST_MODAL:
			return {
				...state,
				showAddFromPriceListModal: !state.showAddFromPriceListModal,
				priceListRow: action.payload
			};

		case priceListConstants.SHOW_TEMPLATE_MODAL:
			return {
				...state,
				showAddFromTemplateModal: !state.showAddFromTemplateModal,
				priceListRow: action.payload
			};

		case priceListConstants.SHOW_CENTRAL_TEMPLATE_MODAL:
			return {
				...state,
				showAddFromCentralTemplateModal: !state.showAddFromCentralTemplateModal,
				priceListRow: action.payload
			};

		case priceListConstants.SHOW_EDIT_IMAGE_MODAL:
			return {
				...state,
				showEditImageModal: !state.showEditImageModal,
				imageIndex: action.imageIndex
			};

		case priceListConstants.GET_PRICELISTS:
			return {
				...state,
				isLoadingPriceLists: true
			};

		case priceListConstants.SET_PRICELISTS:

			return {
				...state,
				isLoadingPriceLists: false,
				priceLists: action.payload
			};

		case priceListConstants.DELETE_PRICELIST:
			// After delete, change pricelist to next in list
			// If last in list, change to previous pricelist
			const index = state.priceLists.findIndex(listItem => listItem.id === action.payload);
			const nextIndex = index !== state.priceLists.length - 1 ? index : state.priceLists.length - 2;
			return {
				...state,
				priceLists: state.priceLists.filter(listItem => action.payload !== listItem.id) || [],
				priceList: {},
				nextPriceListIndexAfterDelete: nextIndex,
			}

		case priceListConstants.COPY_PRICELIST:

			return {
				...state,
				isLoadingPriceLists: true,
			};

		case priceListConstants.COPIED_PRICELIST:

			return {
				...state,
				isLoadingPriceLists: false,
			};

		case priceListConstants.SET_PRICELIST:
			return {
				...state,
				isLoadingPriceLists: false,
				priceList: action.payload
			};

		case priceListConstants.SET_PRICEROWS:
			return {
				...state,
				isLoadingPriceLists: false,
				priceListRows: action.payload,
				filterPriceRows: ''
			};

		case priceListConstants.ADD_PRICEROW:
			return {
				...state,
				isLoadingPriceLists: false,
				priceListRows: [action.payload, ...state.priceListRows]
			}
		case priceListConstants.UPDATE_PRICEROW:
			return {
				...state,
				priceListRows: state.priceListRows.map(item => {
					if (item.id === action.payload.id) {
						return action.payload;
					}
					return item;
				})
			}

		case priceListConstants.SET_PRICELIST_META_DATA:
			return {
				...state,
				metaData: action.payload
			};
		case priceListConstants.SET_PRICELIST_META_DATA_SELECTS:
			return {
				...state,
				metaDataSelects: action.payload
			};
		case priceListConstants.SET_FILTER_PRICEROWS:
			return {
				...state,
				filterPriceRows: action.payload
			}
		case priceListConstants.TOGGLE_FILTER_ADDONS:
			return {
				...state,
				filterPriceRowAddons: !state.filterPriceRowAddons
			}
		case priceListConstants.TOGGLE_FILTER_SPECIAL_ADDONS:
			return {
				...state,
				filterPriceRowSpecialAddons: !state.filterPriceRowSpecialAddons
			}
		case priceListConstants.UPLOADING_FILE:
			return {
				...state,
				isLoadingPriceLists: true,
			};

		case priceListConstants.UPLOADED_FILE:
			return {
				...state,
				isLoadingPriceLists: false,
			};

		case priceListConstants.LOADING_FILES:
			return {
				...state,
				isLoadingPriceListImages: true,
			};

		case priceListConstants.LOADED_FILES:
			return {
				...state,
				isLoadingPriceListImages: false,
			};

		case priceListConstants.DELETE_FILE:
			return {
				...state,
				isLoadingPriceListImages: false,
			};

		default:
			return state;
	}
};

import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BookIcon from '@material-ui/icons/Book';
import DeleteIcon from '@material-ui/icons/Delete';

import { styles } from 'styles/layout/LayoutStyle';

const CommonListItemsBottom = (props) => {

    const { 
        classes,
        closeDrawerIfMobile,
    } = props;

    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>
            <div style={{ marginTop: 'auto' }}>
                <ListItem 
                    button 
                    component={ForwardedNavLink} 
                    to="/arkiv"
                    title="Arkiv"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <BookIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="ARKIV" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem 
                    button 
                    component={ForwardedNavLink} 
                    to="/papirkurv"
                    title="Papirkurv"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="PAPIRKURV" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
            </div>
        </>
    )
}

export default withStyles(styles)(CommonListItemsBottom);

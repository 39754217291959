export const styles = theme => ({
    cardBackground: {
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column'
    },
    cardHeader: {
        height: '230px'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // height: '100%',
        flex: 1,
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
    newsImage: {
        height: 200,
        display: 'flex',
        alignItems: 'flex-end',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    newsTitle: {
        fontWeight: '400',
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            color: theme.palette.common.white,
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);',
            padding: theme.spacing(2, 3.5),
        },

    },
    mobileStepper: {
        marginTop: 'auto',
        background: 'none',
    },
    placeholderLogo: {
		display: 'flex',
        justifyContent: 'center',
        margin: "0 auto",
        width: "275px",
        height: "120px",
        opacity: "0.08",
        marginTop: "65px",
        marginBottom: "47px",
    },
    linkWrapper: {
        display: "flex",
        justifyContent: "flex-end",
    },
    linkText: {
        textDecoration: "none",
        color: "inherit"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "auto"
    },
    button: {
        color: "#fff",
        backgroundColor: "#c30e15",
        '&:hover': {
          backgroundColor: "#af0a11",
        },
    }
});
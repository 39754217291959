import { darken } from '@material-ui/core/styles/colorManipulator';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const colors = {
    brand: {
        red: '#c30e15',
    },
    primary: '#00a2d9',
    success: '#a3c30f',
    warning: '#f1c40f',
    danger: '#b50f16',
    status: {
        // open
        blue: '#00a2d9',
        // control
        yellow: '#fcbb00',
        // for approval
        black: '#292d39',
        // rejected, deleted
        red: '#c30e15',
        // approved
        green: '#a3c30f',
        // completed
        gold: '#a3823b',
        // draft
        grey: '#bfbfbf',
    },
    text: {
        lightestGrey: '#f5f5f5',
        grey: '#454956',
        darkGrey: '#787b84',
        darkestGrey: '#3e3e3e',
        highContrast: '#565656',
        warning: '#ffb822',
    },
    borders: {
        lighterGrey: '#f3f3f3',
        lightGrey: '#e7e0e4',
        grey: '#bfbfbf',
        darkGrey: '#d8d8d8',
        darkestGrey: '#1f2321',
    },
    icons: {
        lightestGrey: '#f5f5f5',
        lightGrey: '#ede7eb',
        grey: '#bfbfbf',
        darkerGrey: '#b2b2b3',
        darkGrey: '#d8d8d8',
        darkestGrey: '#747474',
        yellow: '#f1c40f'
    },
    avatar: {
        lightBlue: '#afe0f9',
    },
    buttons: {
        grey: '#bfbfbf',
    },
    background: {
        darkestGrey: '#11141b',
        darkGrey: '#282c37',
        lightGrey: '#fbfbfb',
        grey: '#eee'
    },
    hover: {
        lightBlue: '#ddeef6',
    },
    thirdParty: {
        word: '#2A579A',
        excel: '#1D6F42'
    },
    banners: {
        orange: amber[700],
        green: green[600],
        blue: darken('#00a2d9', 0.3),
        grey: grey[600],
    },
};

export default colors;

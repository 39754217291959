const fileTypes = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    img: 'image',
    octetStream: 'octet-stream',
    json: 'json',
    txt: 'text/plain'
}

export default fileTypes;
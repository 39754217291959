import { snackbarConstants } from '../constants/SnackbarConstants';

const initialState = {
	snackList: []
};

export const snackbarReducer = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case snackbarConstants.ALERT_SUCCESS:
			return { snackList: [ ...state.snackList, { id: Date.now(), type: 'success', ...payload } ] };

		case snackbarConstants.ALERT_WARNING:
			return { snackList: [ ...state.snackList, { id: Date.now(), type: 'warning', ...payload } ] };

		case snackbarConstants.ALERT_ERROR:
			return { snackList: [ ...state.snackList, { id: Date.now(), type: 'error', ...payload } ] };

		case snackbarConstants.ALERT_INFO:
			return { snackList: [ ...state.snackList, { id: Date.now(), type: 'info', ...payload } ] };

		case snackbarConstants.ALERT_DISMISS:
			return { snackList: state.snackList.filter((snackbar) => snackbar.id !== payload.id) };

		default:
			return state;
	}
};

const hosts = {
  local: 'localhost',
  development: 'akkord-no-frontend-develop.azurewebsites.net',
  systest: 'akkord-no-frontend-systest.azurewebsites.net',
  acctest: 'acctest.akkord.no',
  acctestLong: 'acctest.akkordportalen.no',
  acctestAzure: 'akkord-no-frontend-acctest.azurewebsites.net',
  prodtest: 'prodtest.akkord.no',
  prodtestLong: 'prodtest.akkordportalen.no',
  trening: 'trening.akkord.no',
  treningLong: 'trening.akkordportalen.no',
  prod: 'akkord.no',
  prodLong: 'akkordportalen.no',
};

export const getIdentityServerUrlByHost = (hostName) => {
  switch (hostName) {
    case 'localhost':
      return (
        process.env.REACT_APP_ID_ENDPOINT ||
        window.location.protocol + '//localhost:4998'
      );
    case 'akkord.no':
      return 'https://id.akkord.no';
    case 'akkord-no-frontend-develop.azurewebsites.net':
      return 'https://akkord-no-identity-develop.azurewebsites.net';
    case 'akkord-no-frontend-systest.azurewebsites.net':
      return 'https://akkord-no-identity-systest.azurewebsites.net';
    case 'acctest.akkord.no':
      return 'https://acctest-id.akkord.no';
    case 'prodtest.akkord.no':
      return 'https://prodtest-id.akkord.no';
    case 'trening.akkord.no':
      return 'https://trening-id.akkord.no';
    case 'acctest.akkordportalen.no':
      return 'https://acctest-id.akkord.no';
    case 'prodtest.akkordportalen.no':
      return 'https://prodtest-id.akkord.no';
    case 'trening.akkordportalen.no':
      return 'https://trening-id.akkord.no';
    case "akkord-no-frontend-acctest.azurewebsites.net":
      return "https://akkord-no-identity-acctest.azurewebsites.net";
  
    default:
      return 'https://id.akkord.no';
  }
};

export default hosts;

export const userConstants = {
    GETAUTHUSER: 'GET_AUTHORIZED_USER',
    SETAUTHUSER: 'SET_AUTHORIZED_USER',

    SET_USER_INFO: 'SET_USER_INFO',
    SET_MENU_OPEN: 'SET_MENU_OPEN',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_USER_EMAIL_NOTIFICATION: 'SET_USER_EMAIL_NOTIFICATION',
    SET_USER_EMAIL_SUGGESTION_NOTIFICATION: 'SET_USER_EMAIL_SUGGESTION_NOTIFICATION',


    SHOW_USER_INFO_MODAL: 'SHOW_USER_INFO_MODAL',

    GET_MY_USER_PROFILE: 'GET_MY_USER_PROFILE',
    SET_MY_USER_PROFILE: 'SET_MY_USER_PROFILE',

    SET_MY_STATE: 'SET_MY_STATE',
    ADD_BREADCRUMB: 'ADD_BREADCRUMB',

    LOADING_RESPONSIBLES: 'LOADING_RESPONSIBLES',
    LOADED_RESPONSIBLES: 'LOADED_RESPONSIBLES',
}

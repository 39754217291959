import { timeRegistrationConstants } from 'store/constants/TimeRegistrationConstants';

import { timeRegistrationService } from 'store/services/TimeRegistrationService';
import { commonService } from 'store/services/CommonService';


const showTimeRegistrationModal = (payload, week) => ({
  type: timeRegistrationConstants.SHOW_TIME_REGISTRATION_MODAL,
  payload,
  week,
});

const getWeeks = (fromDate, toDate, pieceWorkId) => async function (dispatch, getState) {
  timeRegistrationService.getWeeks(fromDate, toDate, pieceWorkId).then(
    (response) => {
      dispatch({ type: timeRegistrationConstants.GET_WEEKS, payload: response.data });
    },
    (error) =>
      commonService.handleResponseError(dispatch, error, 'Weeks', {
        type: timeRegistrationConstants.ON_TIME_REGISTRATION_ERROR
      })
  );
};

const getTimeForWorkers = async (workers, year, week, workId) => {
  return await timeRegistrationService.getTimeForWorkers(workers, year, week, workId);
};

const saveTimeForWorkers = async (pieceWorkId, workerTimeObject) => {
  return timeRegistrationService.addTimeRegistration(pieceWorkId, [workerTimeObject]);
};

const deleteTimeRegistration = async (id) => {
  return timeRegistrationService.deleteTimeRegistration(id);
};

const setDetailsSelectedWorkerId = (id) => async function (dispatch, getState) {
  dispatch({ type: timeRegistrationConstants.SET_DETAILS_SELECTED_WORKER, payload: id });
}

const getTimeSummary =  (pieceWorkId, fromYear, fromWeek, toYear, toWeek) => 
  async function (dispatch, getState) {
    timeRegistrationService.getTimeSummary(pieceWorkId, fromYear, fromWeek, toYear, toWeek).then(
      (response) => {
        dispatch({ type: timeRegistrationConstants.GET_TIME_SUMMARY, payload: response.data });
      },
      (error) =>
        commonService.handleResponseError(dispatch, error, 'Oversikt', {
          type: timeRegistrationConstants.ON_TIME_REGISTRATION_ERROR
        })
    );

};

export const TimeRegistrationActions = {
  showTimeRegistrationModal,
  getWeeks,
  getTimeForWorkers,
  saveTimeForWorkers,
  setDetailsSelectedWorkerId,
  getTimeSummary,
  deleteTimeRegistration
}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ReactComponent as Logo } from 'assets/img/Akkordportalen_logo_staende.svg';

import { newsService } from 'components/news/state/NewsService';

import { styles } from 'styles/startpage/NewsStyle';

function News(props) {
    const { className, classes, preview } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [ingressNews, setIngressNews] = useState(null);
    const [maxSteps, setMaxSteps] = useState(0);

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    useEffect(() => {
        const getNews = async () => {
            try {
                let news = await newsService.getIngressNews();
                setIngressNews(news);
                setMaxSteps(news.data.length);
            } catch(err) {
                console.error(err);
            }
        }

        if(preview) {
            setIngressNews(preview);
            setMaxSteps(0);
        } else {
            getNews();
        }
    }, [preview]);

    const renderImage = () => {
        return(
            <>
                <div className={classes.newsImage} style={{ 
                    backgroundImage: `url(${ingressNews.data[activeStep].headerImage})`
                }}>
                    <Hidden smDown>
                        <div style={{width: "100%"}}>
                            <Typography 
                                variant="h1" 
                                component="h2" 
                                className={classes.newsTitle} 
                            >
                                <span>
                                    {ingressNews.data[activeStep].subject}
                                </span>
                            </Typography>
                        </div>
                    </Hidden>
                </div>
            </>
        );
    }

    const renderPlaceholderImage = () => {
        return(<Logo className={classes.placeholderLogo} />);
    }

    return (
        <div>
            {ingressNews ?
                ingressNews.data.length > 0 ?
                <Card className={classNames(className, classes.cardBackground)} >
                        {renderImage()}
                        <CardContent className={classes.cardContent}>
                            <Hidden mdUp>
                                <div>
                                    <Typography 
                                        gutterBottom 
                                        variant="h1" 
                                        component="h2" 
                                        className={classes.newsTitle} 
                                    >
                                        <span>
                                            {ingressNews.data[activeStep].subject}
                                        </span>
                                    </Typography>
                                </div>
                            </Hidden>
                            <Typography paragraph={true} style={{whiteSpace: 'pre-line'}} variant="body2">
                                {ingressNews.data[activeStep].header}
                            </Typography>

                            { !preview ?
                                <div className={classes.buttonContainer} >
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        className={classes.button}
                                        href={`/aktuelt/${ingressNews.data[activeStep].id}`}
                                    >
                                        Les mer
                                    </Button>
                                </div>
                                : null
                            }

                            { !preview ?
                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    className={classes.mobileStepper}
                                    nextButton={
                                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                            
                                        <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            
                                        </Button>
                                    }
                                />
                                : null
                            }
                        </CardContent>
                    </Card>
                :
                <Card className={classNames(className, classes.cardBackground)} >
                    {renderPlaceholderImage()}
                    <CardContent className={classes.cardContent}>
                        <Hidden mdUp>
                            <div>
                                <Typography 
                                    gutterBottom 
                                    variant="h1" 
                                    component="h2" 
                                    className={classes.newsTitle} 
                                >
                                </Typography>
                            </div>
                        </Hidden>
                        <Typography variant="body2">            
                            Det er ingen nyheter akkurat nå
                        </Typography>
                    </CardContent>
                </Card>
            : null}
        </div>
    )
}

News.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(News);
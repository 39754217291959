export const requisitionTypes = {
    all: 'all',
    addition: 0,
    change: 1,
}

export const requisitionTypeTranslations = {
    all: 'Alle jobbtyper',
    0: 'Tilleggsarbeid',
    1: 'Endringarbeid'
}

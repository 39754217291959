import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import hosts from 'lib/helpers/HostsHelper';

import Config from 'Config';

const styles = theme => ({
    text: {
        fontSize: theme.sizes.text.sm,
    },
    orange: {
        backgroundColor: theme.colors.banners.orange,
        color: theme.palette.common.white,
    },
    green: {
        backgroundColor: theme.colors.banners.green,
        color: theme.palette.common.white,
    },
    blue: {
        backgroundColor: theme.colors.banners.blue,
        color: theme.palette.common.white,
    },
    grey: {
        backgroundColor: theme.colors.banners.grey,
        color: theme.palette.common.white,
    },
});

function VersionBanner(props) {
    const { 
        classes,
    } = props;

    const environmentInfo = useRef({
        name: '',
        isSystest: false,
        isAcctest: false,
        isTrening: false,
        isProdtest: false,
    });

    const [showVersionBanner, setShowVersionBanner] = useState(false);

    useEffect(() => {
        const setEnvironmentInfo = () => {
            let hostname = window 
                && window.location 
                && window.location.hostname;

            switch (hostname) {
                case hosts.systest:
                    environmentInfo.current= {
                        ...environmentInfo.current,
                        isSystest: true,
                        name: 'AKKORDPORTALEN-SYSTEST',
                    }
                    break;
                
                case hosts.acctest:
                case hosts.acctestLong:
                case hosts.acctestAzure:
                    environmentInfo.current= {
                        ...environmentInfo.current,
                        isAcctest: true,
                        name: 'AKKORDPORTALEN-ACCTEST',
                    }
                    break;
                
                case hosts.trening:
                case hosts.treningLong:
                    environmentInfo.current= {
                        ...environmentInfo.current,
                        isTrening: true,
                        name: 'AKKORDPORTALEN-TRENING',
                    }
                    break;
                
                case hosts.prodtest:
                case hosts.prodtestLong:
                    environmentInfo.current= {
                        ...environmentInfo.current,
                        isProdtest: true,
                        name: 'AKKORDPORTALEN-PRODTEST',
                    }
                    break;

                default:
                    break;
            }
            setShowVersionBanner(environmentInfo.current.isSystest 
                || environmentInfo.current.isAcctest 
                || environmentInfo.current.isTrening 
                || environmentInfo.current.isProdtest
            );
        }
        setEnvironmentInfo();
    }, []);

    const renderVersionBanner = showVersionBanner 
    ? (
        <Typography align="center" className={classNames(classes.text, {
            [classes.orange]: environmentInfo.current.isSystest,
            [classes.green]: environmentInfo.current.isAcctest,
            [classes.blue]: environmentInfo.current.isTrening,
            [classes.grey]: environmentInfo.current.isProdtest,
        })}>
           Merk: {environmentInfo.current.name} [{Config.version}]
        </Typography>
    ) : '';

    return (<>
        { renderVersionBanner }
    </>);
}

export default withStyles(styles)(React.memo(VersionBanner));

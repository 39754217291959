export const pieceWorkConstants = {
    RESET_PIECEWORK_STATE: 'RESET_PIECEWORK_STATE',

    REQUESSTPIECEWORKLIST: 'REQUEST_PIECEWORKLIST',
    RECIEVEPIECEWORKLIST: 'RECEIVE_PIECEWORKLIST',

    REQUEST_MY_PIECEWORKLIST: 'REQUEST_MY_PIECEWORKLIST',
    RECIEVE_MY_PIECEWORKLIST: 'RECEIVE_MY_PIECEWORKLIST',

    REQUEST_MY_PIECEWORKS_WITH_PRICELISTS: 'REQUEST_MY_PIECEWORKS_WITH_PRICELISTS',
    RECIEVE_MY_PIECEWORKS_WITH_PRICELISTS: 'RECIEVE_MY_PIECEWORKS_WITH_PRICELISTS',

    SET_PIECEWORK_ID: 'SET_PIECEWORK_ID',

    REQUESTPIECEWORK: 'REQUEST_PIECEWORK',
    RECIEVEPIECEWORK: 'RECEIVE_PIECEWORK',
    CHECKLIST_DONE: 'CHECKLIST_DONE',
    CHECKLIST_FORM_DONE: 'CHECKLIST_FORM_DONE',

    SHOW_EDIT_PIECEWORK_MODAL: 'SHOW_EDIT_PIECEWORK_MODAL',
    NEWPIECEWORKMODAL: 'NEW_PIECEWORK_MODAL',
    SAVEPIECEWORK: 'SAVEPIECEWORK',
    SAVEDPIECEWORK: 'SAVEDPIECEWORK',
    DELETEPIECEWORK: 'DELETEPIECEWORK',
    EDITPIECEWORKNOTEMODAL: 'EDIT_PIECEWORK_NOTE_MODAL',
    UPDATE_EMPLOYER_INFO: 'UPDATE_EMPLOYER_INFO',
    REQUESTPIECEWORKRESPONSIBLES: 'REQUEST_PIECEWORK_RESPONSIBLES',
    RECIEVEPIECEWORKRESPONSIBLES: 'RECIEVE_PIECEWORK_RESPONSIBLES',

    EDITPIECEWORKRESPONSIBLESMODAL: 'EDIT_PIECEWORK_RESPONSIBLES_MODAL',

    SAVEPIECEWORKRESPONSIBLES: 'SAVE_PIECEWORK_RESPONSIBLES',
    SAVEDPIECEWORKRESPONSIBLES: 'SAVED_PIECEWORK_RESPONSIBLES',
    REMOVE_PIECEWORK_RESPONSIBLES: 'REMOVE_PIECEWORK_RESPONSIBLES',

    SAVECONTACTPERSON: 'SAVE_CONTACT_PERSON',
    SAVEDCONTACTPERSON: "SAVED_CONTACT_PERSON",

    GET_PIECEWORK_CALCULATION: 'GET_PIECEWORK_CALCULATION',
    SET_PIECEWORK_CALCULATION: 'SET_PIECEWORK_CALCULATION',
    SET_PIECEWORK_CALCULATION_ROWS: 'SET_PIECEWORK_CALCULATION_ROWS',

    SAVE_PIECEWORK_CALCULATION: 'SAVE_PIECEWORK_CALCULATION',
    SAVED_PIECEWORK_CALCULATION: 'SAVED_PIECEWORK_CALCULATION',

    SHOW_SHARE_MODAL: 'SHOW_SHARE_MODAL',
    SET_SHARED_TO: 'SET_SHARED_TO',
    TOGGLE_SHARED_TO_FEDERATION_ROLE: 'TOGGLE_SHARED_TO_FEDERATION_ROLE',
    SET_META_DATA_SELECTS: 'SET_META_DATA_SELECTS',

    ON_PIECEWORK_ERROR: 'ON_PIECEWORK_ERROR',
    REMOVE_PIECEWORK_FILE: 'REMOVE_PIECEWORK_FILE',

    FILTER_COMPLETED_PIECEWORK_LIST: 'FILTER_COMPLETED_PIECEWORK_LIST',
    SET_COMPLETED_PIECEWORK_FILTER_BY: 'SET_COMPLETED_PIECEWORK_FILTER_BY'
}

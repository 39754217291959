import { userConstants } from "store/constants/UserConstants";

const requestUser = "REQUEST_USER";

const initialState = {
  UserInfo: {}, //from token
  User: {}, //from db
  isLoading: false,
  isLoadingResponsibles: false,
  isMenuOpen: false,
  showUserInfoModal: false,
  numberOfUnreadMessages: 0,
  breadcrumbs: sessionStorage.breadcrumbs ? JSON.parse(sessionStorage.breadcrumbs) : [],
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestUser: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case userConstants.SET_USER_INFO: {
      return {
        ...state,
        UserInfo: action.data,
        isLoading: false,
      };
    }

    case userConstants.SET_USER_DATA: {
      return {
        ...state,
        User: { ...state.User, ...action.payLoad },
        isLoading: false,
      };
    }

    case userConstants.SET_USER_EMAIL_NOTIFICATION: {
      return {
        ...state,
        User: {
          ...state.User,
          receiveMessageNotifications: action.payload,
        },
        isLoading: false,
      };
    }

    case userConstants.SET_USER_EMAIL_SUGGESTION_NOTIFICATION: {
      return {
        ...state,
        User: {
          ...state.User,
          receiveSuggestionNotifications: action.payload,
        },
        isLoading: false,
      };
    }

    case userConstants.SET_MENU_OPEN: {
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    }

    case userConstants.SHOW_USER_INFO_MODAL: {
      return {
        ...state,
        showUserInfoModal: !state.showUserInfoModal,
      };
    }

    case userConstants.GET_MY_USER_PROFILE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case userConstants.SET_MY_USER_PROFILE: {
      return {
        ...state,
        User: { ...state.User, ...action.payload },
        isLoading: false,
      };
    }

    case userConstants.LOADING_RESPONSIBLES: {
      return {
        ...state,
        isLoadingResponsibles: true,
      };
    }

    case userConstants.LOADED_RESPONSIBLES: {
      return {
        ...state,
        isLoadingResponsibles: false,
      };
    }
    case userConstants.SET_MY_STATE: {
      return {
        ...state,
        numberOfUnreadMessages: action.payload,
      };
    }

    case userConstants.ADD_BREADCRUMB: {
      let items = state.breadcrumbs;
      if (
        state.breadcrumbs.length === 0 ||
        action.payload.path !==
          state.breadcrumbs[state.breadcrumbs.length - 1].path
      ) {
        items = [...state.breadcrumbs, action.payload];
      }
      if (items.length > 3) {
        items.splice(0, items.length - 3);
      }

      sessionStorage.setItem('breadcrumbs', JSON.stringify(items));
      
      return {
        ...state,
        breadcrumbs: [...items],
      };
    }

    default:
      return state;
  }
};

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import Authenticate from 'Authenticate';
import { userService } from 'store/services/UserService';
import { userConstants } from 'store/constants/UserConstants';

import App from 'App';
import { useNavigate } from 'react-router';

const IdConnect = (props) => {
  const { dispatch } = props;
  const navigate = useNavigate();

  const [user, setUser] = useState({ user: undefined, role: '' });
  const getLoginUserData = useCallback((role = []) => {
    return new Promise((resolve, reject) => {
      if (role) {
        const roles = role.map((c) => c.name);

        resolve(roles.join(','));
      } else {
        resolve('');
      }
    });
  }, []);

  const fetchLoginUser = useCallback(async () => {
    try {
      const user = await userService.getMyProfileData();
      if (!user) return false;
      const data = user.data[0];

      //TODO: If no Roles then logout user?!
      const role = await getLoginUserData(data.roles);
      data.role = role;
      dispatch({ type: userConstants.SET_USER_INFO, data });
      return role;
    } catch (e) {
      //abortLogin.current = true;
      // userManager.logout();
      // localStorage.removeItem('userAuth');
      //userManager.signinSilent();
    }
  }, [dispatch, getLoginUserData]);

  const userLoaded = useCallback(
    async (user) => {
      const r = await fetchLoginUser();
      if (window.location.href.includes('code')) {
        setUser({ user: user, role: r });
        navigate(localStorage['redirectUri']);
      } else {
        setUser({ user: user, role: r });
      }
    },
    [fetchLoginUser, props]
  );

  const userUnLoaded = useCallback(() => {
    setUser({ user: undefined, role: '' });
  }, []);

  const renderApp =
    user.role === '' ? null : <App user={user} history={props.history} />;

  return (
    <Authenticate
      userLoaded={userLoaded}
      userUnLoaded={userUnLoaded}
      renderNotAuthenticated={null}
      fetchLoginUser={fetchLoginUser}
    >
      {renderApp}
    </Authenticate>
  );
};

export default connect()(IdConnect);

import React, { useState } from 'react';
import FileDrop from 'components/common/FileUpload';
import classNames from 'classnames';

import withSnackbar from 'components/common/snackBar/WithSnackbar';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EmailIcon from '@material-ui/icons/Email';

import useForm from 'components/common/useForm';
import Loading from 'lib/helpers/Loading';
import { suggestionService } from 'components/suggestions/state/SuggestionService';

import { styles } from 'styles/startpage/SuggestionFormStyle';

function SuggestionForm(props) {
  const { classes, className, snackbar } = props;
  const { values, setValues, handleChange, handleFiles } = useForm();

  const [removeFiles, setRemoveFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    try {
      setIsLoading(true);
      let id = await suggestionService.createSuggestion(values);
      if (values.files) {
        for (let i = 0; i < values.files.length; i++) {
          try {
            await suggestionService.connectFile(id, values.files[i].data);
          } catch (err) {
            snackbar.showError('' + err + '');
          }
        }
      }
      snackbar.showSuccess('Ditt forslag er mottatt');
      setValues({ subject: '', body: '', files: null });
      setRemoveFiles(true);
    } catch (err) {
      snackbar.showError('' + err + '');
    } finally {
      setIsLoading(false);
    }
    setRemoveFiles(false);
  };

  return (
    <Card className={classNames(className, classes.card)}>
      <CardContent className={classes.cardContent}>
        <form
          id="suggestion-form"
          name="suggestion-form"
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <div className={classes.container}>
            <div className={classes.headerWrapper}>
              <Typography variant="h2" style={{ marginBottom: '12px' }}>
                Send inn forslag
              </Typography>
              <Typography>
                Hvis du har kommentarer eller forslag til forbedringer til
                akkordportalen, kan du sende dem her. Vennligst vedlegg
                skjermbilder. Ved eventuelle feil eller problemer med
                akkordportalen, vennligst send en e-post til
              </Typography>
              <div className={classes.mail}>
                <EmailIcon fontSize="inherit" />
                <Typography
                  component="a"
                  display="inline"
                  href="mailto:akkord@elogit.no"
                  className={classes.link}
                >
                  akkord@elogit.no
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <TextField
              required
              id="subject"
              name="subject"
              autoComplete="off"
              label="Overskriften"
              fullWidth
              disabled={isLoading}
              value={values.subject || ''}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div />
          <div className={classes.container}>
            <TextField
              required
              disabled={isLoading}
              id="body"
              name="body"
              autoComplete="off"
              label="Din beskjed"
              fullWidth
              multiline
              minRows="4"
              value={values.body || ''}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
          </div>

          <div className={classes.container}>
            <div className={classes.dropContainer}>
              <FileDrop
                title="Laste opp"
                handleUpload={handleFiles}
                numFiles={3}
                values={values}
                removeFiles={removeFiles}
              />
            </div>
          </div>
          {isLoading ? <Loading /> : null}

          <div
            className={classes.container}
            style={{ justifyContent: 'flex-end', marginTop: 'auto' }}
          >
            <Button
              variant="contained"
              disabled={isLoading}
              color="primary"
              type="submit"
              form="suggestion-form"
              className={classes.button}
            >
              Send inn
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(withSnackbar()(SuggestionForm));

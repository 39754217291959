import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router';

import NotFound from 'routes/NotFound';
import RouteContainer from 'RouteContainer';
import Logout from 'components/idConnect/Logout';
import Layout from 'components/layout/Layout';

const AsyncEmployerStartpage = (
    lazy(() => (
        import(/* webpackChunkName: 'EmployerStartPage' */ "pages/employer/EmployerStartpage")
    ))
);

const AsyncEmployerPieceWorksOngoing = (
    lazy(() => (
        import(/* webpackChunkName: 'EmployerPieceWorksOngoing' */ "pages/employer/EmployerPieceWorksOngoing")
    ))
);

const AsyncEmployerPieceWorksCompleted = (
    lazy(() => (
        import(/* webpackChunkName: 'EmployerPieceWorksCompleted' */ "pages/employer/EmployerPieceWorksCompleted")
    ))
);

const AsyncEmployerSearchPage = (
    lazy(() => (
        import(/* webpackChunkName: 'EmployerSearchPage' */ "pages/employer/EmployerSearchPage")
    ))
);

const RouteEmployer = () => {

    return (
        <Layout>
            <RouteContainer>
                <Route path="/start" element={<AsyncEmployerStartpage/>} />
                <Route path="/gjeldende" element={<AsyncEmployerPieceWorksOngoing/>} />
                <Route path="/fullforte" element={<AsyncEmployerPieceWorksCompleted/>} />
                <Route path="/sok" element={<AsyncEmployerSearchPage/>} />
                <Route path="/logout" element={<Logout/>} />
               
                <Route path="/" element={<Navigate to="/start" replace />}/>
                <Route path="*" element={<NotFound/>} />
            </RouteContainer>
        </Layout>
    );
};

export default RouteEmployer;

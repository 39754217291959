import { workerRegistryConstants } from 'store/constants/WorkerRegistryConstants';
import { workerStatusFilter } from 'api/Worker/WorkerStatusFilter';

const initialState = {
	editWorkerRegistryWorkerModal: false,
	workerRegistryWorkerList: [],
	workerRegistryWorker: {},
	filterWorkerRegistryWorkerList: '',
	filterWorkerRegistryStatus: workerStatusFilter.all,
	isLoading: false,
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {

		case workerRegistryConstants.EDIT_WORKER_REGISTRY_WORKER_MODAL: {
			return {
				...state,
				editWorkerRegistryWorkerModal: !state.editWorkerRegistryWorkerModal,
				workerRegistryWorker: action.payload
			};
		}

		case workerRegistryConstants.GET_WORKER_REGISTRY_WORKER_LIST: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerRegistryConstants.SET_WORKER_REGISTRY_WORKER_LIST: {
			return {
				...state,
				workerRegistryWorkerList: action.payload,
				isLoading: false
			};
		}

		case workerRegistryConstants.SAVE_WORKER_TO_REGISTRY: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerRegistryConstants.SAVED_WORKER_TO_REGISTRY: {
			return {
				...state,
				workerRegistryWorker: action.data,
				isLoading: false
			};
		}


		case workerRegistryConstants.SET_FILTER_WORKER_REGISTRY_WORKER_LIST: {
			return {
				...state,
				filterWorkerRegistryWorkerList: action.payload,
			};
		}

		case workerRegistryConstants.SET_FILTER_WORKERS_BY_STATUS: {
			return {
				...state,
				filterWorkerRegistryStatus: action.payload,
			};
		}

		case workerRegistryConstants.RESET_FILTERS: {
			return {
				...state,
				filterWorkerRegistryStatus: workerStatusFilter.all,
			};
		}

		case workerRegistryConstants.ON_WORKER_REGISTRY_ERROR: {
			return {
				...state,
				isLoading: false
			};
		}
		default:
			return state;
	}
};

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Container from 'components/layout/Container';
import Grid from '@material-ui/core/Grid';

import PieceWorkCardList from 'components/startpage/pieceWorkCardList/PieceWorkCardList';
import News from 'components/startpage/news/News';
import SuggestionForm from 'components/startpage/suggestion/SuggestionForm';

const styles = theme => ({
    grid: {
        flexGrow: 1,
        minHeight: '600px', 
    },
    gridItemContent: {
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(1),
        }
    }
});

const Startpage = (props) => {
    const { classes } = props;

    return (
        <Container>
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={6} lg={4}>
                    <PieceWorkCardList className={classes.gridItemContent} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <News className={classes.gridItemContent} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SuggestionForm className={classes.gridItemContent} />
                </Grid>
            </Grid>
        </Container>
    )
}

Startpage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Startpage);

import { darken } from '@material-ui/core/styles/colorManipulator';
const drawerWidth = 240;

export const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	toolbar: {
		// paddingRight: theme.spacing.unit, // keep right padding when drawer closed
	},
	toolbarIcon: {
		backgroundColor: theme.colors.brand.red,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 8px',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '0 8px',
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '0 0px',
		// },
		...theme.mixins.toolbar
	},
	menuList: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden auto',
		height: '100%',
		padding: 0,
	},
	menuListItem: {
		padding: '20px 0 20px 24px',
		borderBottom: `1px solid ${theme.colors.borders.darkestGrey} !important`,
		'&:active': {
			backgroundColor: theme.colors.background.darkestGrey,
		},
		'&:focus': {
			backgroundColor: theme.colors.background.darkestGrey,
		},
		'&:hover': {
			backgroundColor: darken(theme.colors.background.darkGrey, 0.25),
		}
	},
	menuListItemActive: {
		backgroundColor: theme.colors.background.darkestGrey,
	},
	menuListItemIcon: {
		color: theme.colors.icons.lightestGrey,
		alignItems: 'center',
	},
	menuListItemText: {
		color: theme.colors.text.lightestGrey,
		fontWeight: 600,
		fontSize: theme.sizes.text.xs,
	},
	appBar: {
		backgroundColor: 'white',
		color: '#000',
		boxShadow: '0 1px 15px 1px rgba(69, 65, 78, 0.11)',
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.down('md')]: {
			position: 'fixed'
		},

	},
	appBarShift: {
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		color: theme.palette.common.white,
	},
	menuButtonHidden: {
		display: 'none'
	},
	menuOpen: {
		[theme.breakpoints.down('xs')]: {
			overflow: 'hidden'
		}
	},
	smallLogo: {
		display: 'flex',
		position: 'relative',
		maxWidth: '36px',
		maxHeight: '36px',
		'& svg': {
			width: 'auto',
			height: 'auto'
		}

	},
	title: {
		paddingLeft: 20,
		flexGrow: 1
	},
	drawerPaper: {
		zIndex: theme.zIndex.drawer + 1,
		position: 'sticky',
		backgroundColor: theme.colors.background.darkGrey,
		//backgroundColor: '#1f2321',
		borderRight: 'none',
		overflow: 'visible',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		maxHeight: '100vh',
		[theme.breakpoints.down('md')]: {
			width: '312px',
			marginLeft: '-72px',
			paddingLeft: '72px'
		},
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100vw + 72px)'
		}
		//transition: theme.transitions.create('width', {
		//    easing: theme.transitions.easing.sharp,
		//    duration: theme.transitions.duration.enteringScreen,
		//}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		//transition: theme.transitions.create('width', {
		//    easing: theme.transitions.easing.sharp,
		//    duration: theme.transitions.duration.leavingScreen,
		//}),
		width: 56,
		[theme.breakpoints.up('sm')]: {
			width: 72
		}
	},
	closeOpenBtn: {
		marginLeft: 'auto'
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		overflow: 'hidden'
	},
	avatar: {
		marginLeft: 5
	},
	mainContent: {
		marginTop: '65px',
		height: '100%'
	},
	h5: {
		marginBottom: theme.spacing(2)
	},
	menuIcon: {
		color: theme.colors.text.darkestGrey
	},
	closeIcon: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	chevronLeftIcon: {
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	customScrollBar: {
		'& ::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
			backgroundColor: '#1f2321',
			borderRadius: '5px'
		},
		'& ::-webkit-scrollbar': {
			width: '6px',
			backgroundColor: '#1f2321'
		},
		'& ::-webkit-scrollbar-thumb': {
			backgroundColor: '#7e8087',
			borderRadius: '5px',
		}
	},
	contentContainer: {
		flex: 1,
		// Menu button offset padding plus matched padding of Container component
		[theme.breakpoints.only('sm')]: {
			paddingLeft: 12 + 8,
		},
		[theme.breakpoints.up('md')]: {
			paddingLeft: 12 + 40,
		},
	},
	contentContainerMenuOpen: {
		// Drawer width minus button width and toolbar padding
		marginLeft: drawerWidth - 36 - 24,
	},
	messagesWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		lineHeight: '20px',
	},
	unreadWrapper: {
		height: '24px',
	},
	unreadDot: {
		display: 'inline-flex',
		'&::after': {
			color: theme.colors.brand.red,
			content: "'•'",
			marginLeft: 6,
			fontSize: '26px',
			lineHeight: '22px',
		}
	},
	unreadChip: {
		backgroundColor: theme.colors.brand.red,
		height: '20px',
		margin: '0px 20px',
	},
	unreadChipLabel: {
		paddingRight: 6,
		paddingLeft: 6,
		color: '#fff',
		fontSize: theme.sizes.text.xs,
		fontWeight: 'bold',
	},
});

import React from 'react';
import PropTypes from 'prop-types';

import { pieceWorkStatus } from 'api/Status/PieceWorkStatus';
import StatusChip from 'components/common/StatusChip';

function PieceWorkStatusChip(props) {
    const { children, status, ...other } = props;

    let statusText = pieceWorkStatus[`${status}`];

    return (
        <StatusChip
            status={statusText}
            {...other}
        >
            {children}
        </StatusChip>
    );
}

PieceWorkStatusChip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    status: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6])
};

export default PieceWorkStatusChip;

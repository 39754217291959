import { userConstants } from 'store/constants/UserConstants';
import { userService } from 'store/services/UserService';
import { commonService } from 'store/services/CommonService';

const parseJwt = (token) => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
};

export const authActionCreators = {
	getLoginUser: () => async (dispatch, getState) => {
		let user = JSON.parse(localStorage.getItem('userAuth'));
		if (user) {
			const decodedToken = parseJwt.decode(user);
			dispatch({ type: userConstants.SET_USER_INFO, decodedToken });
		}
	},
	getUserSetting: (id) => async (dispatch) => {

		try {
			const settings = await userService.getUserSettings(id);
			dispatch({ type: userConstants.SET_USER_DATA, payLoad: settings.data });

		}
		catch (error) {
			commonService.handleResponseError(dispatch, error, '');
		}

	},
	getMyUserProfile: () => async (dispatch, getState) => {
		dispatch({ type: userConstants.GET_MY_USER_PROFILE });
		userService.getMyUserProfile().then(
			(response) => {
				const data = response.data[0];
				dispatch({ type: userConstants.SET_MY_USER_PROFILE, payload: data });
			},
			(error) => commonService.handleResponseError(dispatch, error, 'Profil', null)
		);
	},
};

export const roles = {
    PieceWorkResponsible: 'PieceWorkResponsible',
    Worker: 'PieceWorkResponsible',
    FederationResponsible: 'FederationResponsible',
    DistrictResponsible: 'DistrictResponsible',
    ClubResponsible: 'ClubResponsible',
    Employer: 'Employer',
    Admin: 'Admin',
    SuperAdmin: 'SuperAdmin',
}

export const roleNames = {
    PieceWorkResponsible: 'Akkordtaker',
    Worker: 'Montør',
    FederationResponsible: 'Tillitsvalgte Forbund',
    DistrictResponsible: 'Tillitsvalgte Distrikt',
    ClubResponsible: 'Tillitsvalgte Klubb',
    Employer: 'Arbeidsgivere',
    Admin: 'Admin',
    SuperAdmin: 'SuperAdmin',
}
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import { roles } from 'api/User/Roles';

import { userConstants } from 'store/constants/UserConstants';
import { authActionCreators } from 'store/actions/UserActions';

import combineStyles from 'lib/helpers/CombineStyles';
import Loading from 'lib/helpers/Loading';

import EditDialog from 'components/common/editDialog/EditDialog';

// TODO: Use same component for user and member modals?
import DisplayUserProfile from 'components/userProfile/components/DisplayUserProfile';
import EmailSetting from 'components/common/EmailSetting';

import { styles } from 'styles/modalStyle';

const userInfoModalStyles = (theme) => ({
  messageLabel: {
    fontSize: theme.sizes.text.sm,
    fontWeight: 600,
  }
});

const UserProfileModal = (props) => {
  const {
    classes,
    showUserInfoModal,
    User,
    UserInfo,
    isLoading,
    dispatch,
  } = props;

  const isFederationResponsible = UserInfo.role.includes(roles.FederationResponsible);
  const isAdmin = UserInfo.role.includes(roles.Admin);
  const isSuperAdmin = UserInfo.role.includes(roles.SuperAdmin);
  const isEmployer = UserInfo.role.includes(roles.Employer);

  useEffect(() => {
    dispatch(authActionCreators.getMyUserProfile());
  }, [dispatch]);

  const closeModal = () => {
    dispatch({ type: userConstants.SHOW_USER_INFO_MODAL });
  };

    const renderContent = isLoading
        ? (<Loading />)
        : (<>
      <Card style={{ padding: 10, marginBottom: 10 }}>
        <DisplayUserProfile
          user={User}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          isEmployer={isEmployer}
          isFederationResponsible={isFederationResponsible}
        />
      </Card>

      {!isEmployer && !isSuperAdmin && !isAdmin && (
        <Card style={{ padding: 6, marginBottom: 10 }}>
          <EmailSetting
            labelClass={classes.messageLabel}
            label="E-postvarsel om nye meldinger"
            notificationType="message"
          />
        </Card>
      )}
      {isFederationResponsible && (
        <Card style={{ padding: 6 }}>
          <EmailSetting
            labelClass={classes.messageLabel}
            label="E-postvarsel om nye forslag"
            notificationType="suggestion"
          />
        </Card>
      )}
    </>
  );

  return (
      <EditDialog
          open={showUserInfoModal}
          onClose={closeModal}
      >
          <DialogTitle
              disableTypography
              className={classes.header}
          >
              <Typography variant="h2">
                  MIN INFORMASJON
                  </Typography>
        <CloseIcon
          className={classes.closeIcon}
          onClick={closeModal}
          title="Lukk"
        />
      </DialogTitle>
          <DialogContent>

              <div style={{ marginTop: 8 }}>
                  {renderContent}
              </div>

      </DialogContent>
          <DialogActions
              className={classes.actionButtons}
              style={{ margin: 0 }}
          >
        <Button
          disabled={isLoading}
          onClick={closeModal}
          variant="outlined"
          style={{ marginRight: 'auto' }}
        >
          Avbryt
        </Button>
      </DialogActions>
    </EditDialog>
  );
};

const combinedStyles = combineStyles(styles, userInfoModalStyles);

export default connect(state => state.User)(withStyles(combinedStyles)(UserProfileModal));
export const timeRegistrationConstants = {
    GET_WORKERS: 'GET_WORKERS',
    SHOW_TIME_REGISTRATION_MODAL: 'SHOW_TIME_REGISTRATION_MODAL',
    GET_WEEKS: 'GET_WEEKS',
    GET_TIME_FOR_WORKERS: 'GET_TIME_FOR_WORKERS',
    SET_DETAILS_SELECTED_WORKER: 'SET_DETAILS_SELECTED_WORKER',
    GET_TIME_SUMMARY: 'GET_TIME_SUMMARY',
    ON_TIME_REGISTRATION_ERROR: 'ON_TIME_REGISTRATION_ERROR',
    SET_FILTER_TIME_SUMMARY: 'SET_FILTER_TIME_SUMMARY',
    IS_LOADING: 'IS_LOADING'
}
import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SubjectIcon from '@material-ui/icons/Subject';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { roles } from 'api/User/Roles';

import SuperAdminListItems from "components/menu/superAdminListItems";

import { styles } from 'styles/layout/LayoutStyle';

const AdminListItems = (props) => {
    const {
        classes,
        userRole,
        closeDrawerIfMobile
    } = props;

    const renderSuperAdmin = () => {
        if (userRole.includes(roles.SuperAdmin)) {
            return (
                <div>
                    <SuperAdminListItems closeDrawerIfMobile={closeDrawerIfMobile} />
                    <hr />
                </div>
            );
        } else return null;
    }


    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>
            {renderSuperAdmin()}
            <div>
                <ListItem
                    button
                    title="Akkordliste"
                    component={ForwardedNavLink}
                    /* to="/akkordliste" */
                    to="/NotImplemented/Akkordliste"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <SubjectIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Akkordliste"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Sentrale satser"
                    component={ForwardedNavLink}
                    to="/sentralesatser"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <FormatListNumberedRtlIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Sentrale satser"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Spesielle tillegg"
                    component={ForwardedNavLink}
                    to="/spesielletillegg"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Spesielle tillegg"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Sentrale maler"
                    component={ForwardedNavLink}
                    to="/sentralemaler"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Sentrale maler"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Summering prisliste"
                    component={ForwardedNavLink}
                    /* to="/summeringprisliste" */
                    to="/NotImplemented/Summering prisliste"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Summering prisliste"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Nødmelding"
                    component={ForwardedNavLink}
                    /* to="/nodmelding" */
                    to="/notImplemented/Nødmelding"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Nødmelding"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Hjelpetekster"
                    component={ForwardedNavLink}
                    /* to="/hjelpetekster" */
                    to="/notImplemented/Hjelpetekster"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Hjelpetekster"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Kontoadministrasjon"
                    component={ForwardedNavLink}
                    to="/kontoadministrasjon"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <VerifiedUserIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Kontoadmin"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Akkordunderlag"
                    component={ForwardedNavLink}
                    to="/akkordliste"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Akkordunderlag"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    title="Filbibliotek"
                    component={ForwardedNavLink}
                    to="/filbibliotek"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <FolderOpenIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Filbibliotek"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
            </div>
        </>
    );
};

export default withStyles(styles)(AdminListItems);

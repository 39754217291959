import React, { Suspense } from "react";
import { Routes } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Loading from "lib/helpers/Loading";

const RouteContainer = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <CssBaseline />
      <Routes>{props.children}</Routes>
    </Suspense>
  );
};

export default RouteContainer;

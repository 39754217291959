import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SearchIcon from '@material-ui/icons/Search';

import { styles } from 'styles/layout/LayoutStyle';

const EmployerListItems = (props) => {

    const { 
        classes,
        closeDrawerIfMobile 
    } = props;

    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>

            <div>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/start"
                    title="Forside"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="FORSIDE" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/gjeldende"
                    title="Gjeldende"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="GJELDENDE" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/fullforte"
                    title="Fullførte"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="FULLFØRTE" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/sok"
                    title="Søk"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="SØK" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
            </div>

        </>
    )
}

export default withStyles(styles)(EmployerListItems);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	container: {
		maxWidth: theme.sizes.pageWidth,
	},
	containerMarginX: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(5),
			marginRight: theme.spacing(5),
		}
	},
	containerMarginY: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(5),
		}
	}
});

const Container = (props) => {
    const { 
        classes, 
        className,
        margin,
        children
    } = props;
    return (
        <div className={classNames(
            className, 
            classes.container,
            classes.containerMarginX,
            {
                [classes.containerMarginY]: margin === 'normal',
            }
        )}>
            {children}
        </div>
    );
};

Container.propTypes = {
	margin: PropTypes.oneOf(['normal', 'dense']),
};

Container.defaultProps = {
	margin: 'normal'
};

export default withStyles(styles)(Container)

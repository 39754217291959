import React from 'react';
import PropTypes from 'prop-types';

import InfoChip from 'components/common/InfoChip';

function StatusChip(props) {
  const { children, status, ...other } = props;

  let statusText, color;
  switch (status) {
    case 'draft':
      statusText = 'Utkast';
      color = 'grey';
      break;

    case 'denied':
      statusText = 'Avvist';
      color = 'red';
      break;

    case 'rejected':
      statusText = 'Avvist';
      color = 'red';
      break;
    case 'open':
      statusText = 'Åpen';
      color = 'blue';
      break;

    case 'control':
      statusText = 'Kontroll';
      color = 'yellow';
      break;

    case 'forApproval':
      statusText = 'Til godkjenning';
      color = 'black';
      break;

    case 'approved':
      statusText = 'Godkjent';
      color = 'green';
      break;

    case 'completed':
      statusText = 'Fullført';
      color = 'gold';
      break;

    case 'deleted':
      statusText = 'Slettet';
      color = 'red';
      break;

    default:
      break;
  }

  return (
    <InfoChip color={color} label={statusText} {...other}>
      {children}
    </InfoChip>
  );
}

StatusChip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'draft',
    'denied',
    'open',
    'control',
    'rejected',
    'forApproval',
    'approved',
    'completed',
    'deleted',
  ]),
};

export default StatusChip;

export const priceListConstants = {
	GET_PRICEROWS: 'GET_PRICEROWS',
	SET_PRICEROWS: 'SET_PRICEROWS',
	ADD_PRICEROW: 'ADD_PRICEROW',
	UPDATE_PRICEROW: 'UPDATE_PRICEROW',
	GET_PRICELISTS: 'GET_PRICELISTS',
	GET_PRICELIST: 'GET_PRICELIST',
	SET_PRICELIST: 'SET_PRICELIST',
	DELETE_PRICELIST: 'DELETE_PRICELIST',
	SET_PRICELISTS: 'SET_PRICELISTS',
	COPY_PRICELIST: 'COPY_PRICELIST',
	COPIED_PRICELIST: 'COPIED_PRICELIST',
	SHOW_QUICKADD_MODAL: 'SHOW_QUICK_ADD_MODAL',
	SHOW_ADD_FROM_TARIFF_MODAL: 'SHOW_ADD_FROM_TARIFF_MODAL',
	SHOW_PRICELIST_MODAL: 'SHOW_PRICELIST_MODAL',
	SHOW_TEMPLATE_MODAL: 'SHOW_TEMPLATE_MODAL',
	SHOW_CENTRAL_TEMPLATE_MODAL: 'SHOW_CENTRAL_TEMPLATE_MODAL',
	SHOW_ADD_FROM_TEMPLATE_MODAL: 'SHOW_ADD_FROM_TEMPLATE_MODAL',
	SHOW_EDIT_IMAGE_MODAL: 'SHOW_EDIT_IMAGE_MODAL',
	SEARCH_PROCUCTS: 'SEARCH_PRODUCTS',
	SET_SEARCH_PRODUCT: 'SET_SEARCH_PRODUCT',
	EDIT_PRICELIST: 'EDIT_PRICELIST',
	ON_PRICELIST_ERROR: 'ON_PRICELIST_ERROR',
	SET_PRICELIST_META_DATA: 'SET_PRICELIST_META_DATA',
	SET_PRICELIST_META_DATA_SELECTS: 'SET_PRICELIST_META_DATA_SELECTS',
	SET_FILTER_PRICEROWS: 'SET_FILTER_PRICEROWS',
	TOGGLE_FILTER_ADDONS: 'TOGGLE_FILTER_ADDONS',
	TOGGLE_FILTER_SPECIAL_ADDONS: 'TOGGLE_FILTER_SPECIAL_ADDONS',
	UPLOADING_FILE: 'UPLOADING_FILE',
	UPLOADED_FILE: 'UPLOADED_FILE',
	LOADING_FILES: 'LOADING_FILES',
	LOADED_FILES: 'LOADED_FILES',
	DELETE_FILE: 'DELETE_FILE',
};

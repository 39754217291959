import React, { useEffect, useCallback, useContext } from 'react';
import Loading from 'lib/helpers/Loading';

import { AuthContext } from 'components/idConnect/AuthContext'

const SignIn = (props) => {
    const userManager = useCallback(useContext(AuthContext), []);

    useEffect(() => {
        const signInUser = async () => {
            await userManager.signinRedirect();
        }
        signInUser()
    }, [userManager])

    return (
        <>
            <Loading />
        </>
    )
}

export default SignIn;
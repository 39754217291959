import React, { useEffect, useCallback, useContext } from 'react';
import Loading from 'lib/helpers/Loading';

import { AuthContext } from 'components/idConnect/AuthContext'

const Logout = () => {

    const userManager = useCallback(useContext(AuthContext), []);
    console.log('error handling');
    useEffect(() => {
        const logoutUser = async () => {
            sessionStorage.clear();
            await userManager.logout();
            localStorage.removeItem('userAuth');
        }
        logoutUser()
    }, [userManager])

    return (
        <>
            <Loading />
        </>
    )
}

export default Logout;
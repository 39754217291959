import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BarChartIcon from '@material-ui/icons/BarChart';
import CommonListItemsBottom from './commonListItemsBottom';
import MessageListItem from 'components/menu/MessagesListItem';

import { styles } from 'styles/layout/LayoutStyle';

const FederationListItems = (props) => {

    const {
        classes,
        closeDrawerIfMobile,
    } = props;

    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>
            <div>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/start"
                    title="Akkordunderlag"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="AKKORDUNDERLAG"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <MessageListItem closeDrawerIfMobile={closeDrawerIfMobile} />
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/aktuelt"
                    title="Aktuelt"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AnnouncementIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="AKTUELT"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/forslagskasse"
                    title="Forslagskasse"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <BusinessCenterIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="FORSLAGSKASSE"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/arbeidsgiver"
                    title="Arbeidsgiver"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="ARBEIDSGIVER"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/medlemmer"
                    title="Medlemmer"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="MEDLEMMER"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/kontoadministrasjon"
                    title="Kontoadministrasjon"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <VerifiedUserIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="KONTOADMIN"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/statistikk"
                    title="Statistikk"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="STATISTIKK"
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
            </div>

            <CommonListItemsBottom closeDrawerIfMobile={closeDrawerIfMobile} />

        </>
    )
}

export default withStyles(styles)(FederationListItems);

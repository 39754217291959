import React from 'react';

import Typography from '@material-ui/core/Typography';

function StatusText(props) {
    const { 
        title, 
        text,
        textTypographyProps = {
            variant: 'h1'
        },
    } = props;
    return (
        <>
            <Typography 
                variant="subtitle1" 
                component="label"
            >
                {title}
            </Typography>
            <Typography 
                component="p" 
                {...textTypographyProps}
            >
                {text}
            </Typography>
        </>
    );
};

export default StatusText;

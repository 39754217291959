import * as api from 'lib/helpers/ApiHelper.js';
import { commonService } from 'store/services/CommonService';

const url = api.getUrl();

const createSuggestionUrl = () => url + '/v1/suggestion';
const connectFileUrl = (id) => url + `/v1/suggestion/${id}/files`;
const getFileUrl = (id) => url + `/v1/files/${id}/`;
const getSuggestionsUrl = () => url + '/v1/my/suggestions?pagesize=10000&orderBy=createdAt&desc=true';
const deleteSuggestionUrl = (id) => url + `/v1/suggestion/${id}`;
const toggleUnreadSuggestionUrl = (id) => url + `/v1/suggestion/${id}/toggleread`;

async function createSuggestion(obj) {
	const opt = {
		method: 'POST',
		headers: api.authHeader(),
		body: JSON.stringify(obj)
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(createSuggestionUrl(), opt).then(commonService.handleResponse);
}

async function connectFile(id, file) {
	let formData = new FormData();
	formData.append("file", file);
	const opt = {
		method: 'POST',
		headers: api.authHeader(),
		body: formData
	};
    return fetch(connectFileUrl(id), opt).then(commonService.handleResponse);
}

async function getFile(id) {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
    return fetch(getFileUrl(id), opt).then(commonService.handleResponse);
}

async function getSuggestions() {
	const opt = {
		method: 'GET',
		headers: api.authHeader(),
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(getSuggestionsUrl(), opt).then(commonService.handleResponse);
}

async function deleteSuggestion(id) {
	const opt = {
		method: 'DELETE',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(deleteSuggestionUrl(id), opt).then(commonService.handleResponse);
}


async function toggleUnreadSuggestion(id) {
	const opt = {
		method: 'PATCH',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
    return fetch(toggleUnreadSuggestionUrl(id), opt).then(commonService.handleResponse);
}

export const suggestionService = {
	createSuggestion,
	connectFile,
	getFile,
	getSuggestions,
	deleteSuggestion,
	toggleUnreadSuggestion
}
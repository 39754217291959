export const pieceWorkStatus = {
  0: 'denied',
  1: 'open',
  2: 'control',
  3: 'forApproval',
  4: 'approved',
  5: 'completed',
};

export const pieceWorkStatusConstant = {
  Denied: 0,
  Open: 1,
  Control: 2,
  ForApproval: 3,
  Approved: 4,
  Completed: 5,
};

export const accessLevel = {
  read: 0,
  write: 1,
};

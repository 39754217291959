import { A0507Types } from "api/0507/A0507Types";

import { agreementsConstants } from "store/constants/AgreementsConstants";

const initialState = {
  agreements: [],
  groupsForAgreements: [],
  _05GroupsForAgreements: [],
  _07GroupsForAgreements: [],
  agreedExtensions: {},
  localAdvanceList: [],
  localAdvance: {},
  A0507AgreementsList: [],
  archiveA0507AgreementsList: [],
  A0507AgreementsSelectListFilter: 0,
  A0507RowsList: [],
  A0507Agreement: {},
  A0507Row: {},
  filterPriceRows: "",
  filter0507Type: A0507Types.all,
  filterAddons: false,
  hourlyRate: "",
  showEditAgreedExtensionsModal: false,
  showAdd0507FromArchiveModal: false,
  showEditOtherAgreementModal: false,
  showEditHourlyRateModal: false,
  showEditLocalAdvanceModal: false,
  showEdit0507RowsModal: false,
  showEdit0507AgreementModal: false,
  otherAgreement: {},
  otherAgreementsList: [],
  isLoading: false,
  weeks: [],
  metaData: [],
  metaDataSelects: {
    factor: [],
    inv: [],
    inventorySupplement: [],
    height: [],
    conditions: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case agreementsConstants.GET_AGREED_EXTENSIONS:
      return {
        ...state,
        isLoading: true,
      };
    case agreementsConstants.SET_AGREED_EXTENSIONS:
      return {
        ...state,
        agreedExtensions: action.payload,
        isLoading: false,
      };

    case agreementsConstants.GET_GROUPS_FOR_AGREEMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case agreementsConstants.SET_GROUPS_FOR_AGREEMENTS:
      return {
        ...state,
        groupsForAgreements: action.payload,
        isLoading: false,
      };
    case agreementsConstants.SET_05_GROUPS_FOR_AGREEMENTS:
      return {
        ...state,
        _05GroupsForAgreements: action.payload,
        isLoading: false,
      };
    case agreementsConstants.SET_07_GROUPS_FOR_AGREEMENTS:
      return {
        ...state,
        _07GroupsForAgreements: action.payload,
        isLoading: false,
      };
    case agreementsConstants.SHOW_EDIT_AGREED_EXTENSIONS_MODAL:
      return {
        ...state,
        showEditAgreedExtensionsModal: !state.showEditAgreedExtensionsModal,
      };

    case agreementsConstants.NEW_OTHER_LOCAL_AGREEMENT:
      return {
        ...state,
        showEditOtherAgreementModal: !state.showEditOtherAgreementModal,
        otherAgreement: null,
      };

    case agreementsConstants.SHOW_EDIT_OTHER_AGREEMENT_MODAL:
      return {
        ...state,
        showEditOtherAgreementModal: !state.showEditOtherAgreementModal,
        otherAgreement: action.payload,
      };

    case agreementsConstants.SHOW_EDIT_HOURLY_RATE_MODAL:
      return {
        ...state,
        showEditHourlyRateModal: !state.showEditHourlyRateModal,
      };

    case agreementsConstants.SHOW_EDIT_LOCAL_ADVANCE_MODAL:
      return {
        ...state,
        showEditLocalAdvanceModal: !state.showEditLocalAdvanceModal,
        localAdvance: action.payload,
      };

    case agreementsConstants.SHOW_EDITA0507_AGREEMENT_MODAL:
      return {
        ...state,
        showEdit0507AgreementModal: !state.showEdit0507AgreementModal,
        A0507Agreement: action.payload,
      };

    case agreementsConstants.SHOW_ADDA0507_FROM_ARCHIVE_MODAL:
      return {
        ...state,
        showAdd0507FromArchiveModal: !state.showAdd0507FromArchiveModal,
      };

    case agreementsConstants.GET_HOURLY_RATE:
      return {
        ...state,
        isLoading: true,
      };

    case agreementsConstants.SET_HOURLY_RATE:
      return {
        ...state,
        hourlyRate: action.payload,
        isLoading: false,
      };

    case agreementsConstants.GET_OTHER_AGREEMENTS:
      return {
        ...state,
        isLoading: true,
      };

    case agreementsConstants.SET_OTHER_AGREEMENTS:
      return {
        ...state,
        otherAgreementsList: action.payload,
        isLoading: false,
      };

    case agreementsConstants.LOADING_OTHER_AGREEMENTS_FILES:
      return {
        ...state,
        isLoading: true,
      };

    case agreementsConstants.LOADED_OTHER_AGREEMENTS_FILES:
      return {
        ...state,
        isLoading: false,
      };

    case agreementsConstants.GETA0507_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case agreementsConstants.SETA0507_LIST:
      return {
        ...state,
        A0507AgreementsList: action.payload,
        isLoading: false,
      };
    case agreementsConstants.SET_ARCHIVE_A0507_LIST:
      return {
        ...state,
        archiveA0507AgreementsList: action.payload,
        isLoading: false,
      };
    case agreementsConstants.SET_FILTERA0507_SELECT_LIST:
      return {
        ...state,
        A0507AgreementsSelectListFilter: action.payload,
      };
    case agreementsConstants.SET_FILTER_PRICEROWS:
      return {
        ...state,
        filterPriceRows: action.payload,
      };
    case agreementsConstants.SET_FILTERA0507_TYPE:
      return {
        ...state,
        filter0507Type: action.payload,
      };
    case agreementsConstants.SET_FILTER_0507_ADDONS:
      return {
        ...state,
        filterAddons: !state.filterAddons,
      };
    case agreementsConstants.GETA0507_ROWS:
      return {
        ...state,
        isLoading: true,
      };
    case agreementsConstants.SETA0507_ROWS:
      return {
        ...state,
        A0507RowsList: action.payload,
        isLoading: false,
      };
    case agreementsConstants.ADDA0507_ROW_TO_LIST:
      return {
        ...state,
        A0507RowsList: [action.payload, ...state.A0507RowsList],
      };
    case agreementsConstants.UPDATEA0507_ROW_TO_LIST:
      const row = state.A0507RowsList.find((p) => p.id === action.payload.id);
      return {
        ...state,
        A0507RowsList: [
          ...state.A0507RowsList.filter((r) => r.id !== row.id),
          { ...action.payload },
        ],
      };
    case agreementsConstants.SHOW_EDITA0507_ROWS_MODAL:
      return {
        ...state,
        showEdit0507RowsModal: !state.showEdit0507RowsModal,
        A0507Row: action.payload,
      };

    case agreementsConstants.SETA0507_ROW:
      return {
        ...state,
        A0507Row: action.payload,
      };

    case agreementsConstants.SETTING_AGREEMENT_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case agreementsConstants.SET_AGREEMENT_STATUS:
      return {
        ...state,
        isLoading: false,
      };
    case agreementsConstants.SET_LOCAL_ADVANCES:
      return {
        ...state,
        localAdvanceList: action.payload,
      };
    case agreementsConstants.SET_LOCAL_ADVANCE:
      return {
        ...state,
        localAdvance: action.payload,
      };

    case agreementsConstants.SET_WEEKS:
      return {
        ...state,
        weeks: action.payload,
      };

    case agreementsConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case agreementsConstants.SETA0507_META_DATA:
      return {
        ...state,
        metaData: action.payload,
      };

    case agreementsConstants.SETA0507_META_DATA_SELECTS:
      return {
        ...state,
        metaDataSelects: action.payload,
      };

    case agreementsConstants.ON_AGREEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { pieceWorkConstants } from 'store/constants/PieceWorkConstants';
import { pieceWorkService } from 'store/services/PieceWorkService';
import { snackbarConstants } from 'store/constants/SnackbarConstants';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    checked: {
        padding: '0 8px !important',
        backgroundColor: '#fafafa',
        fontSize: '1rem',
        borderBottom: '1px solid #f0f0f0',
        '&:hover': {
            backgroundColor: '#fafafa',
        }
    },
    listItem: {
        padding: '4px 8px',
        borderBottom: '1px solid #f0f0f0',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#fafafa',
        },
    },
    listItemCheckbox: {
        display: 'flex',
        justifyContent: 'center',
    }
});

const CheckList = (props) => {
    const {
        classes,
        workId,
        dispatch,
        setCountCheckList,
        setTotalCheckList
    } = props;
    const [checkListItems, setCheckListItems] = useState([]);

    const setCheckListItemsCb = useCallback(setCheckListItems, [setCheckListItems]);

    const [isLoading, setIsLoading] = useState(false);

    const getCheckList = useCallback(async () => {
        const list = await pieceWorkService.getPieceWorkCheckList(workId);
        setTotalCheckList(list.data.length);
        setCheckListItemsCb(() => {
            return [...list.data]
        });
    }, [setCheckListItemsCb, setTotalCheckList, workId])

    useEffect(() => {
        getCheckList();
    }, [getCheckList])

    useEffect(() => {
        if(checkListItems.length === 0) {
            return;
        }
        setCountCheckList(checkListItems.filter(x => x.isConfirmed).length);
        if (!checkListItems.find((c) => !c.isConfirmed)) {
            dispatch({ type: pieceWorkConstants.CHECKLIST_DONE, payload: true })
        } else {
            dispatch({ type: pieceWorkConstants.CHECKLIST_DONE, payload: false })
        }
    }, [checkListItems, setCountCheckList, dispatch])

    const toggleItem = async (value) => {
        setCheckListItems(checkListItems.map(item => {
            if (item.id === value.id) {
                return { ...item, isConfirmed: !item.isConfirmed };
            } else {
                return item;
            }
        }));
    }

    const handleToggle = async value => {
        try {
            setIsLoading(true);
            await pieceWorkService.setCheckListItemConfirmed(value.id, !value.isConfirmed);
            dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Sjekkliste ble lagret' } });
        }
        catch (e) {
            dispatch({ type: snackbarConstants.ALERT_ERROR, payload: { message: '' + e + '' } });
            return false;
        }
        finally {
            setIsLoading(false);
        }
        await toggleItem(value);
    };

    return (
        <List className={classes.root}>
            {checkListItems && checkListItems.map(value => {
                const labelId = `checkbox-list-label-${value.id}`;

                return (
                    <ListItem
                        className={value.isConfirmed ? classes.checked : classes.listItem}
                        style={{ transition: 'all .3s' }}
                        key={value.id}
                        role={undefined}
                        dense
                        disabled={isLoading}
                        button
                        onClick={() => handleToggle(value)}>
                        <ListItemIcon className={classes.listItemCheckbox}>
                            <Checkbox
                                edge="start"
                                checked={value.isConfirmed}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} style={{ padding: '0' }} primary={value.subject} />
                    </ListItem>
                );
            })}
        </List>
    );
}

function mapStateToProps(state) {
    const { workId, isLoading, pieceWork } = state.PieceWorkState;
    return {
        isLoading,
        pieceWork,
        workId,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(CheckList));
import React, { useState, useEffect, useCallback } from 'react';
import Dropzone from 'react-dropzone';

import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/NoteAdd';

import { withStyles } from '@material-ui/core/styles';
import { styles, baseStyle, activeStyle, rejectStyle } from 'styles/startpage/SuggestionFormStyle';
import withSnackbar from 'components/common/snackBar/WithSnackbar';
//TODO: Make own style and not using from suggestionformstyle.

const FileDrop = (props) => {
	const { classes, handleUpload, title, numFiles = 3, values, removeFiles } = props;

	const [files, setFiles] = useState([]);

	// export const fileUpload = {
	// 	withPreviews,
	// 	handleDrop
	// };

	const withPreviews = (dropHandler) => (accepted, rejected) => {
		const acceptedWithPreview = accepted.map((file, x) => ({
			...file,
			preview: URL.createObjectURL(file),
			name: file.name,
			index: x,
			data: file
		}));

		if (files.length + acceptedWithPreview.length > numFiles) {
			props.snackbar.showInfo('Kan ikke laste opp flere vedlegg');
			return false;
		}

		dropHandler(acceptedWithPreview, rejected);
	};

	const handleDrop = useCallback((accepted) => { setFiles((files) => [...files, ...accepted]) }, []);

	const removeFile = (img, e) => {
		e.preventDefault();
		e.stopPropagation();
		setFiles(files.filter((item, j) => img.index !== item.index));
	};

	//TODO: Don't add handleUpload to dep list. Figure out a way to cache that function and add it to dep.list. Remember -> Test it.
	useEffect(
		() => {
			handleUpload(files);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[files]
	);

	useEffect(() => {
		if (removeFiles) {
			if (values) {
				if (values.files === null) {
					setFiles([]);
				}
			}
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeFiles])

	const acceptedFiles = {
		'image/*': ['.jpeg', '.jpg', '.png'],
		'application/pdf': ['.pdf'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
	}

	return (
		<div className={classes.dropContainer}>
			<Dropzone
				accept={acceptedFiles}
				maxFiles={numFiles}
				onDrop={withPreviews(handleDrop)}
			>
				{({
					getRootProps,
					getInputProps,
					isDragActive,
					isDragAccept,
					isDragReject,
					acceptedFiles,
					rejectedFiles
				}) => {
					let styles = { ...baseStyle };
					styles = isDragActive ? { ...styles, ...activeStyle } : styles;
					styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

					return (
						<div>
							<div {...getRootProps()} style={styles} className={classes.filesContainer}>
								<input id="files" label={title} className={classes.textField} {...getInputProps()} />
								<div style={{ padding: '16px' }}>
									{files.map((file, x) => (
										<div onClick={(x) => removeFile(file, x)} key={'fileWrapper' + x} className={classes.files}>
											<Typography variant="body2">{file.name} <ClearIcon fontSize="small" /></Typography>
										</div>
									))}
									{files.length === 0 ? (
										<div >
											<Typography variant="body2">{title}</Typography>
										</div>

									) : ('')}
								</div>
								<div className={classes.cameraIcon}>
									<AddIcon />
								</div>
								{isDragReject && <div>Ugyldig filetype</div>}
							</div>
						</div>
					);
					// <input {...getInputProps()} />
				}}
			</Dropzone>
		</div>
	);
};


export default withStyles(styles)(withSnackbar()(FileDrop));

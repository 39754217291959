import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import combineStyles from 'lib/helpers/CombineStyles';

import StatusText from 'components/pieceWork/status/StatusText';

import { styles } from 'styles/modalStyle';

const displayUserProfileStyles = (theme) => ({
	text: {
		color: theme.colors.text.darkestGrey,
		fontWeight: 600,
	},
	textItalic: {
		fontWeight: 600,
		fontStyle: 'italic',
		color: theme.colors.text.darkGrey,
	},
	flexWrapper: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		}
	},
});

function DisplayUserProfile(props) {
	const {
		classes,
		user,
		isFederationResponsible,
		isAdmin,
		isEmployer,
		isSuperAdmin
	} = props;

	const renderName = `${user.memberFirstName || user.firstName} ${user.memberLastName || user.lastName}`;

	return (<>

		<StatusText
			title="E-post"
			text={user.email || '-'}
			textTypographyProps={{
				className: classes.text,
				variant: 'body2',
				gutterBottom: true
			}}
		/>

		<StatusText
			title="Navn"
			text={renderName || '-'}
			textTypographyProps={{
				className: classes.text,
				variant: 'body2',
				gutterBottom: true
			}}
		/>
		{!isAdmin && !isSuperAdmin && !isEmployer ? (<>
			<StatusText
				title="Fødselsnummer"
				text={user.memberPersonNumber || user.personNumber || '-'}
				textTypographyProps={{
					className: classes.text,
					variant: 'body2',
					gutterBottom: true
				}}
			/>

			<StatusText
				title="Medlemsnummer"
				text={user.membershipNumber || '-'}
				textTypographyProps={{
					className: classes.text,
					variant: 'body2',
					gutterBottom: true
				}}
			/>
		</>) : (null)}
		<StatusText
			title="Mobil"
			text={user.memberMobilePhone || user.phoneNumber || '-'}
			textTypographyProps={{
				className: classes.text,
				variant: 'body2',
				gutterBottom: true
			}}
		/>

		<StatusText
			title="Sted"
			text={user.memberCity || user.employerCity || user.city || '-'}
			textTypographyProps={{
				className: classes.text,
				variant: 'body2',
				gutterBottom: true
			}}
		/>

		{
			!isFederationResponsible &&
			<>
				{/* TODO: Update/remove depending on whether club information is included in registry */}
				<StatusText
					title="Klubb"
					text={user.employerOrganizationalBody || '-'}
					textTypographyProps={{
						className: classes.text,
						variant: 'body2',
						gutterBottom: true
					}}
				/>

				<StatusText
					title="Distrikt"
					text={user.employerDistrict || '-'}
					textTypographyProps={{
						className: classes.text,
						variant: 'body2',
						gutterBottom: true
					}}
				/>

				<StatusText
					title="Arbeidsgiver"
					text={user.employerName || '-'}
					textTypographyProps={{
						className: classes.text,
						variant: 'body2',
						gutterBottom: true
					}}
				/>
			</>
		}

	</>);
}

const combinedStyles = combineStyles(styles, displayUserProfileStyles);

export default withStyles(combinedStyles)(DisplayUserProfile);

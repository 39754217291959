import { snackbarConstants } from '../constants/SnackbarConstants';


const showSuccess = (payload) => ({
  type: snackbarConstants.ALERT_SUCCESS,
  payload
});

const showWarning = (payload) => ({
  type: snackbarConstants.ALERT_WARNING,
  payload
});

const showError = (payload) => ({
  type: snackbarConstants.ALERT_WARNING,
  payload
});

const showInfo = (payload) => ({
  type: snackbarConstants.ALERT_INFO,
  payload
});

const dismiss = (payload) => ({
  type: snackbarConstants.ALERT_DISMISS,
  payload
})

export default { dismiss, showSuccess, showWarning, showError, showInfo }

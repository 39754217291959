import { UserManager, Log } from 'oidc-client-ts';
import { getIdentityServerUrlByHost } from 'lib/helpers/HostsHelper';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export default class AuthService {
  UserManager;
  accessToken;

  hostName;
  identityHost;
  host;

  OidcSettings;

  constructor() {
    this.hostName = window && window.location && window.location.hostname;
    this.identityHost = ((hostName) => {
      return getIdentityServerUrlByHost(hostName);
    })(this.hostName);

    this.host = ((hostName) => {
      if (process.env.NODE_ENV === 'development') {
        return window.location.protocol + '//localhost:3000';
      } else {
        return `https://${hostName}`;
      }
    })(this.hostName);

    this.OidcSettings = {
      authority: this.identityHost,
      client_id: 'NawClient',
      redirect_uri: this.host,
      post_logout_redirect_uri: this.host,
      response_type: 'code',
      scope: 'openid profile naw offline_access',
      automaticSilentRenew: true,
    };

    this.UserManager = new UserManager({
      ...this.OidcSettings,
    });

    // Logger
    Log.logger = console;
    if (process.env.NODE_ENV === 'development') {
      //Log.level = Log.DEBUG;
      Log.level = Log.NONE;
    } else {
      Log.level = Log.NONE;
    }

    this.UserManager.events.addUserLoaded((user) => {
      this.accessToken = user.access_token;
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('id_token', user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      const token = user.access_token;
      localStorage.setItem('userAuth', JSON.stringify(token));
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
      this.logout();
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinSilent();
    });

    this.UserManager.events.addUserSignedOut(() => {
      console.log('user has signed out');
      this.logout();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      '';
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  setUserInfo = (authResult) => {
    const data = parseJwt(this.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
    return localStorage.getItem('id_token'); //new
  };

  setUser = (data) => {
    localStorage.setItem('userId', data.sub);
  };

  setSessionInfo(authResult) {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  }

  isAuthenticated = () => {
    const access_token = localStorage.getItem('access_token');
    return !!access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
        this.logout();
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.removeUser();
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    root: {
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: theme.sizes.text.xxs,
        padding: '4px 2px',
        height: 'auto',
    }, 
    blue: {
        backgroundColor: theme.colors.status.blue,
    },
    yellow: {
        backgroundColor: theme.colors.status.yellow,
    },
    black: {
        backgroundColor: theme.colors.status.black,
    },
    red: {
        backgroundColor: theme.colors.status.red,
    },
    green: {
        backgroundColor: theme.colors.status.green,
    },
    gold: {
        backgroundColor: theme.colors.status.gold,
    },
    grey: {
        backgroundColor: theme.colors.status.grey,
    },
});

function InfoChip(props) {
    const { 
        classes, 
        children, 
        className, 
        label, 
        color, 
        ...other 
    } = props;

    return (
        <Chip className={classNames(classes.root, 
                className,
                {
                    [classes.blue]: color === "blue",
                    [classes.yellow]: color === "yellow",
                    [classes.black]: color === "black",
                    [classes.red]: color === "red",
                    [classes.green]: color === "green",
                    [classes.gold]: color === "gold",
                    [classes.grey]: color === "grey",
                },
            )}
            label={label}
            {...other}
        >
            {children}
        </Chip>
    );
}

InfoChip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "default",
        "blue", 
        "yellow", 
        "black",
        "red",
        "green",
        "gold",
        "grey",
    ])
};

InfoChip.defaultProps = {
    color: "default",
}

export default withStyles(styles)(InfoChip);

import React from 'react';
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SearchIcon from '@material-ui/icons/Search';

import CommonResponsibleListItems from 'components/menu/commonResponsibleListItems';
import CommonListItemsBottom from 'components/menu/commonListItemsBottom';

import { styles } from 'styles/layout/LayoutStyle';

const MainListItems = (props) => {

    const { 
        classes,
        closeDrawerIfMobile 
    } = props;

    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (
        <>
            <CommonResponsibleListItems closeDrawerIfMobile={closeDrawerIfMobile} />

            <div>
                <ListItem
                    button
                    component={ForwardedNavLink}
                    to="/sok"
                    title="Søk"
                    onClick={closeDrawerIfMobile}
                    className={classes.menuListItem}
                    activeclassname={classes.menuListItemActive}
                >
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary="SØK" 
                        primaryTypographyProps={{
                            classes: {
                                root: classes.menuListItemText,
                            }
                        }}
                    />
                </ListItem>
            </div>

            <CommonListItemsBottom closeDrawerIfMobile={closeDrawerIfMobile} />

        </>
    )
}

export default withStyles(styles)(MainListItems);

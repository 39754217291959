import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { pieceWorkActionCreators } from 'store/actions/PieceWorkActions';
import PieceWorkCard from 'components/startpage/PieceWorkOngoingCard';
import Loading from 'lib/helpers/Loading';

const styles = (theme) => ({
    cardList: {
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    cardHeader: {
        alignItems: 'flex-start',
        backgroundColor: theme.colors.brand.red,
        height: '200px',
        paddingTop: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        backgroundPosition: '150px',
        backgroundImage: 'url(/waves_elogit_two.png)'
    },
    cardTitle: {
        color: '#fff',
    },
    cardContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        '&:last-child': {
            paddingBottom: theme.spacing(4)
        }
    },
    grid: {
        marginTop: "-154px",
        flexWrap: 'wrap'
    },
    textItalic: {
        fontWeight: 600,
        fontSize: theme.sizes.text.xs,
        fontStyle: 'italic',
    },
    paper: {
        padding: theme.spacing(2.5),
        marginTop: 109,
        width: '100%',
    },
});

function PieceWorkCardList(props) {
   
    const { 
        className, 
        classes, 
        myPieceworks, 
        isLoading, 
        dispatch, 
    } = props;

    useEffect(() => {
        dispatch(pieceWorkActionCreators.requestMyPieceWorkList());
    }, [dispatch]);


    const renderCards = !myPieceworks ?
        <Loading /> :

        (
            <Card className={classNames(className, classes.cardList)}>
                <CardHeader
                    title="Gjeldende akkordunderlag"
                    titleTypographyProps={{ variant: 'h2', className: classes.cardTitle }}
                    component="div"
                    className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container className={classes.grid} spacing={2}>
                    {isLoading ? <Loading /> : (myPieceworks.length === 0 && !isLoading) &&
                            <Paper className={classes.paper} elevation={2}>
                                <Typography className={classes.textItalic}>
                                    Mangler akkordunderlag
                                </Typography>
                            </Paper>
                        }
                        {myPieceworks.length > 0 &&
                            myPieceworks.slice(0, 2).map((item, x) => {
                                return (
                                    <Grid item xs={12} sm={6} md={12} key={item.pieceWorkNumber}>
                                        <PieceWorkCard piecework={item} size="small" elevation={2} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
        );



    return <>{ renderCards }</>
}

PieceWorkCardList.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { myPieceworks, isLoading } = state.PieceWorkState;
    return {
        myPieceworks,
        isLoading
    };
}

export default connect(mapStateToProps)(withStyles(styles)(PieceWorkCardList));

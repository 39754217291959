import * as api from 'api/Api';
import { commonService } from 'store/services/CommonService';

export const pieceWorkService = {
  getPieceWorkCompletedList,
  getMyPieceWorkList,
  getMyPieceWorksWithPricelists,
  getPieceWork,
  searchPieceWork,
  savePieceWork,
  saveFileToPieceWork,
  getPieceWorkResponsibles,
  savePieceWorkResponsibles,
  removePieceWorkResponsibles,
  saveContactPerson,
  getPieceWorkFile,
  deletePieceWorkFile,
  deletePieceWork,
  sharePieceWork,
  removeSharePieceWork,
  getPieceWorkMetaData,
  getPieceWorkTimeSummaryFor,
  getPieceWorkTime,
  getPieceWorkTimeSummary,
  getPieceWorkCheckList,
  getPieceWorkCheckListForm,
  setPieceWorkToClosed,
  setPieceWorkToSentIn,
  setCheckListItemConfirmed,
  setCheckListFormItemConfirmed,
  updatePieceWorkEmployer,
  getPieceWorkPriceListRows,
  getPieceWorkPriceListSummary,
  getApprovedAgreementsByPieceWorkId,
  getApprovedRequisitionsByPieceWorkId,
  getSpecialAdditions,
  setPieceWorkToReopened,
  setPieceWorkToApproved,
  toggleShareFederationPieceWork,
  uploadDistributionFile,
  getLatestDistribution,
  getRowsForDistribution,
  setPieceWorkToOpen,
  getDistributionFile,
  deleteDistributionRow,
  getDistributionList,
  setPieceWorkToFinished,
  setPieceWorkSalaryPayment,
  createDistributionEdition,
  getDistrictList,
  getExcelFromList,
};

function getPieceWorkCompletedList() {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getFetchPieceWorkListUrl(5);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getMyPieceWorkList() {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getFetchPieceWorkListUrl('ongoing');
  return fetch(url, opt).then(commonService.handleResponse);
}

function getMyPieceWorksWithPricelists() {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getMyPieceWorksWithPricelistsUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWork(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getFetchPieceWorkUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function searchPieceWork(query) {
  const opt = {
    method: 'POST',
    headers: api.authHeader(),
    body: JSON.stringify(query),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSearchPieceWorkUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

async function savePieceWork(pw) {
  const opt = {
    method: 'POST',
    headers: api.authHeader(),
  };

  if (pw.id) {
    opt['method'] = 'PUT';
  }

  opt['body'] = JSON.stringify(pw);
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getFetchSaveNewPieceWorkUrl();
  return await fetch(url, opt).then(commonService.handleResponse);
}

async function updatePieceWorkEmployer(workId, employerId) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getUpdatePieceWorkEmployerUrl(workId, employerId);
  return await fetch(url, opt).then(commonService.handleResponse);
}

async function saveFileToPieceWork(workId, file) {
  var formData = new FormData();
  formData.append('workId', workId);
  if (file) formData.append('file', file.data);

  //TODO: Validation on input params and throw error?
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: formData,
  };
  const url = api.getFileUploadUrl(workId);
  return await fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkResponsibles(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getFetchPieceWorkResponsiblesUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function savePieceWorkResponsibles(workId, pieceWorkResponsibles) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(pieceWorkResponsibles),
  };
  opt.headers['Content-Type'] = 'application/json';
  const url = api.savePieceWorkResponsiblesUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function removePieceWorkResponsibles(workId, pieceWorkResponsible) {
  const opt = {
    method: 'DELETE',
    headers: api.authHeader(),
  };
  const url = api.removePieceWorkResponsiblesUrl(
    workId,
    pieceWorkResponsible.userId
  );
  return fetch(url, opt).then(commonService.handleResponse);
}

function saveContactPerson(pieceWorkResponsible) {
  var pw = {
    workId: pieceWorkResponsible.workId,
    userId: pieceWorkResponsible.userId,
  };

  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(pw),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getToggleContactPersonUrl(pw.workId, pw.userId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkFile(workId, fileId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  const url = api.getPieceWorkFileDownloadUrl(workId, fileId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function deletePieceWorkFile(workId, fileId) {
  const opt = {
    method: 'DELETE',
    headers: api.authHeader(),
  };
  const url = api.getDeleteFileFromPieceWorkUrl(workId, fileId);
  return await fetch(url, opt).then(commonService.handleResponse);
}

function deletePieceWork(workId) {
  const opt = {
    method: 'DELETE',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getDeletePieceWorkUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function sharePieceWork(workId, userIds) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(userIds),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSharePieceWorkUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function removeSharePieceWork(workId, userIds) {
  const opt = {
    method: 'DELETE',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  let url = api.getRemoveSharePieceWorkUrl(workId);
  url += '?userIds=' + userIds.toString();
  return fetch(url, opt).then(commonService.handleResponse);
}

async function toggleShareFederationPieceWork(
  workId,
  shareToFederation,
  userIds = []
) {
  const opt = {
    method: shareToFederation ? 'PATCH' : 'DELETE',
    headers: api.authHeader(),
    body: JSON.stringify(userIds),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getToggleShareFederationPieceWorkUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkMetaData(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkMetaDataUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkTimeSummaryFor(workId, workerIds) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkTimeSummaryForUrl(workId, workerIds);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkTime(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkTimeUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getPieceWorkTimeSummary(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkTimeSummaryUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getPieceWorkCheckList(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkCheckListUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getPieceWorkCheckListForm(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkCheckListFormUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToOpen(workId) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkStatusToOpenUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToClosed(workId) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkStatusToClosedUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToReopened(workId, reason = '', admin = false) {
  const body = {
    pieceWorkId: workId,
    rejectedCasue: reason,
  };
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(body),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = admin
    ? api.getSetPieceWorkStatusToReopenedAdminUrl(workId, reason)
    : api.getSetPieceWorkStatusToReopenedUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToApproved(workId) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkStatusToApprovedUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToFinished(workId) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkStatusToFinishedUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkSalaryPayment(workId, paymentDate) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkSalaryPaymentUrl(workId, paymentDate);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function createDistributionEdition(workId) {
  const opt = {
    method: 'POST',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getCreateEmptyDistributionUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setPieceWorkToSentIn(
  workId,
  htmlReports = {},
  distributionId = 0
) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(htmlReports),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetPieceWorkStatusToSentInUrl(workId, distributionId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setCheckListItemConfirmed(checkListItemId, isConfirmed) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetCheckListItemConfirmedUrl(checkListItemId, isConfirmed);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function setCheckListFormItemConfirmed(checkListForm) {
  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: JSON.stringify(checkListForm),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSetCheckListFormItemConfirmedUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getPieceWorkPriceListRows(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkPriceListRowsUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getPieceWorkPriceListSummary(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getPieceWorkPriceListSummaryUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}
async function getApprovedAgreementsByPieceWorkId(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getApprovedAgreementsByPieceWorkIdUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getApprovedRequisitionsByPieceWorkId(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getApprovedRequisitionsByPieceWorkIdUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getSpecialAdditions() {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getSpecialAdditionsUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

async function uploadDistributionFile(distributionId, distributionType, file) {
  var formData = new FormData();
  if (file) formData.append('file', file.data);

  const opt = {
    method: 'PATCH',
    headers: api.authHeader(),
    body: formData,
  };
  const url = api.getUploadDistributionFileUrl(
    distributionId,
    distributionType
  );
  return await fetch(url, opt).then(commonService.handleResponse);
}

async function getDistributionFile(rowId, fileId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  const url = api.getDistributionFileUrl(rowId, fileId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function deleteDistributionRow(rowId) {
  const opt = {
    method: 'DELETE',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getDeleteDistributionRowUrl(rowId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getLatestDistribution(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getLatestDistributionUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getRowsForDistribution(distributionId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getRowsForDistributionUrl(distributionId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getDistributionList(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getDistributionListUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getDistrictList() {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getDistrictListUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

async function getExcelFromList(list) {
  const opt = {
    method: 'POST',
    headers: api.authHeader(),
    body: JSON.stringify(list),
  };
  opt.headers = api.addJsonHeader(opt.headers);
  const url = api.getExcelFromListUrl();
  return fetch(url, opt).then(commonService.handleResponse);
}

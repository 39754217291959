export const requisitionsConstants = {
    SHOW_EDIT_REQUISITION_MODAL: 'SHOW_EDIT_REQUISITION_MODAL',
    SHOW_EDIT_REQUISITION_ROW_MODAL: 'SHOW_EDIT_REQUISITION_ROW_MODAL',

    GET_REQUISITION_LIST: 'GET_REQUISITION_LIST',
    SET_REQUISITION_LIST: 'SET_REQUISITION_LIST',
    SET_APPROVED_REQUISITIONS_LIST: 'SET_APPROVED_REQUISITIONS_LIST',

    SET_META_DATA_STATUS_AND_TYPES: 'SET_META_DATA_STATUS_AND_TYPES',
    SET_FILTER_REQUISITION_TYPE: 'SET_FILTER_REQUISITION_TYPE',

    GET_REQUISITION_ROWS: 'GET_REQUISITION_ROWS',
    SET_REQUISITION_ROWS: 'SET_REQUISITION_ROWS',
    SET_FILTER_PRICEROWS: 'SET_FILTER_PRICEROWS',
    ADD_REQUISITION_ROW_TO_LIST: 'ADD_REQUISITION_ROW_TO_LIST',
    UPDATE_REQUISITION_ROW_TO_LIST: 'UPDATE_REQUISITION_ROW_TO_LIST',

    GET_REQUISITION: 'GET_REQUISITION',
    SET_REQUISITION: 'SET_REQUISITION',

    SETTING_REQUISITION_STATUS: 'SETTING_REQUISITION_STATUS',
    SET_REQUISITION_STATUS: 'SET_REQUISITION_STATUS',

    SET_IS_LOADING: 'SET_IS_LOADING',

    SET_REQUISITION_META_DATA_SELECTS: 'SET_REQUISITION_META_DATA_SELECTS',
    SET_REQUISITION_META_DATA: 'SET_REQUISITION_META_DATA',


    ON_REQUISITIONS_ERROR: 'ON_REQUISITIONS_ERROR',
}

import { workerConstants } from 'store/constants/WorkerConstants';
import { workerStatusFilter } from 'api/Worker/WorkerStatusFilter';

const initialState = {
	workers: [],
	worker: {},
	isLoading: false,
	editWorkerModal: false,
	filterIsBorrowed: false,
	filterSkillLevel: workerStatusFilter.all,
	sortOrder: 'createdAt',
	addWorkersToPieceWorkModal: false,
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {

		case workerConstants.EDIT_WORKER_MODAL: {
			return {
				...state,
				editWorkerModal: !state.editWorkerModal,
				worker: action.worker
			};
		}
		case workerConstants.ADD_WORKERS_TO_PIECEWORK_MODAL: {
			return {
				...state,
				addWorkersToPieceWorkModal: !state.addWorkersToPieceWorkModal,
			};
		}

		case workerConstants.RECIEVE_WORKERS_BY_PIECEWORK: {
			return {
				...state,
				workers: action.data,
				isLoading: false
			};
		}

		case workerConstants.GET_WORKERS_BY_PIECEWORK: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerConstants.SAVE_WORKER: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerConstants.SAVED_WORKER: {
			return {
				...state,
				worker: action.data,
				isLoading: false
			};
		}

		case workerConstants.ADD_WORKERS_TO_PIECEWORK: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerConstants.ADDED_WORKERS_TO_PIECEWORK: {
			return {
				...state,
				isLoading: false
			};
		}

		case workerConstants.TOGGLE_ACTIVE_WORKER: {
			return {
				...state,
				isLoading: false
			};
		}
		case workerConstants.FILTER_WORKER_LIST: {
			return {
				...state,
				isLoading: true
			};
		}

		case workerConstants.FILTER_IS_BORROWED: {
			return {
				...state,
				filterIsBorrowed: action.payload
			}
		}

		case workerConstants.FILTER_SKILL_LEVEL: {
			return {
				...state,
				filterSkillLevel: action.payload,
				filterIsBorrowed: false,
			}
		}		

		case workerConstants.SET_SORT_ORDER: {
			return {
				...state,
				sortOrder: action.payload
			}
		}

		case workerConstants.ON_WORKER_ERROR: {
			return {
				...state,
				isLoading: false
			};
		}


		default:
			return state;
	}

};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import EmailIcon from '@material-ui/icons/Email';

import { styles } from 'styles/layout/LayoutStyle';

import { userConstants } from 'store/constants/UserConstants';
import { userService } from 'store/services/UserService';

import useInterval from 'components/common/useInterval';

const MessageListItem = (props) => {

    const { classes,
        dispatch,
        numberOfUnreadMessages,
        closeDrawerIfMobile } = props;


    useEffect(() => {
        const getState = async () => {
            try {
                const list = await userService.getMyState();
                dispatch({ type: userConstants.SET_MY_STATE, payload: list.data[0].numberOfUnreadMessages });
            }
            catch (e) {
                return false;
            }
        }
        getState();
    }, [dispatch])


    useInterval(async () => {
        try {
            const list = await userService.getMyState();
            dispatch({ type: userConstants.SET_MY_STATE, payload: list.data[0].numberOfUnreadMessages });
        }
        catch (e) {
            return false;
        }
    }, 900000);

    
    const ForwardedNavLink = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);

    return (<ListItem
        button
        component={ForwardedNavLink}
        to="/meldinger"
        title="Meldinger"
        onClick={closeDrawerIfMobile}
        className={classes.menuListItem}
        activeclassname={classes.menuListItemActive}
    >
        <ListItemIcon
            className={classes.menuListItemIcon}
        >
            <div className={classNames(classes.unreadWrapper, {
                [classes.unreadDot]: numberOfUnreadMessages > 0,
            })}>
                <EmailIcon />
                
            </div>
            
        </ListItemIcon>
        <div className={classes.messagesWrapper}>
            <Typography 
                className={classes.menuListItemText}
            >
                MELDINGER
            {
                numberOfUnreadMessages > 0 &&
                    <Chip 
                        classes={{ 
                            root: classes.unreadChip, 
                            label: classes.unreadChipLabel,
                        }} 
                        component="span"
                        label={numberOfUnreadMessages} 
                    />
            }
            </Typography>
        </div>
    </ListItem>)
}

const mapStateToProps = (state) => {
    const { numberOfUnreadMessages } = state.User;
    return {
        numberOfUnreadMessages: numberOfUnreadMessages,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MessageListItem));
import { pieceWorkConstants } from 'store/constants/PieceWorkConstants';

const initialState = {
	workId: null,
	pieceworks: [],
	myPieceworks: [],
	myPieceWorksWithPricelists: [],
	isLoading: false,
	isLoadingCalculation: false,
	showEditPieceWorkModal: false,
	pieceWork: null,
	checkListDone: false,
	checkListFormDone: false,
	editPieceWorkNoteModal: false,
	showEditPieceWorkResponsiblesModal: false,
	pieceWorkResponsibles: [],
	pieceWorkCalculation: null,
	pieceWorkCalculationRows: [],
	metaDataSelects: [],
	showShareModal: false,
	sharedTo: [],
	toFederationResponsible: false,
	filterCompletedPieceWorks: '',
	filterCompletedPieceWorksBy: 'all'
};

export const reducer = (state = initialState, action) => {

	switch (action.type) {

		case pieceWorkConstants.REQUESSTPIECEWORKLIST: {
			return {
				...state,
				isLoading: true
			};
		}

		case pieceWorkConstants.RECIEVEPIECEWORKLIST: {
			return {
				...state,
				pieceworks: action.pieceworks,
				isLoading: false
			};
		}

		case pieceWorkConstants.REQUEST_MY_PIECEWORKLIST: {
			return {
				...state,
				isLoading: true
			};
		}

		case pieceWorkConstants.RECIEVE_MY_PIECEWORKLIST: {
			return {
				...state,
				myPieceworks: action.pieceworks,
				isLoading: false
			};
		}

		case pieceWorkConstants.REQUEST_MY_PIECEWORKS_WITH_PRICELISTS: {
			return {
				...state,
				isLoading: true
			};
		}

		case pieceWorkConstants.RECIEVE_MY_PIECEWORKS_WITH_PRICELISTS: {
			return {
				...state,
				myPieceWorksWithPricelists: action.payload,
				isLoading: false
			};
		}

		case pieceWorkConstants.SET_PIECEWORK_ID: {
			return {
				...state,
				workId: action.payload,
			};
		}

		case pieceWorkConstants.REQUESTPIECEWORK: {
			return {
				...state,
				isLoading: true,
			};
		}

		case pieceWorkConstants.RECIEVEPIECEWORK: {
			return {
				...state,
				pieceWork: action.piecework,
				isLoading: false
			};
		}
		case pieceWorkConstants.SAVEPIECEWORK: {
			return {
				...state,
				isLoading: true
			};
		}
		case pieceWorkConstants.SAVEDPIECEWORK: {
			return {
				...state,
				isLoading: false
			};
		}

		case pieceWorkConstants.UPDATE_EMPLOYER_INFO: {
			return {
				...state,
				pieceWork: {
					...state.pieceWork,
					companyId: action.payload.id,
					companyName: action.payload.name,
					companyCity: action.payload.city,
					companyAddress: action.payload.address,
					companyPostalCode: action.payload.postalCode
				}
			}
		}

		case pieceWorkConstants.REMOVE_PIECEWORK_FILE: {
			return {
				...state,
				pieceWork: {
					...state.pieceWork,
					files: state.pieceWork.files.filter(listItem => action.payload !== listItem.id)
				},
			}
		}

		case pieceWorkConstants.SHOW_EDIT_PIECEWORK_MODAL: {
			return {
				...state,
				showEditPieceWorkModal: !state.showEditPieceWorkModal,
			};
		}

		case pieceWorkConstants.NEWPIECEWORKMODAL: {
			return {
				...state,
				showEditPieceWorkModal: !state.showEditPieceWorkModal,
				pieceWork: null
			};
		}

		case pieceWorkConstants.EDITPIECEWORKNOTEMODAL: {
			return {
				...state,
				editPieceWorkNoteModal: !state.editPieceWorkNoteModal,
			};
		}

		case pieceWorkConstants.REQUESTPIECEWORKRESPONSIBLES: {
			return {
				...state,
				isLoading: true,
			};
		}

		case pieceWorkConstants.RECIEVEPIECEWORKRESPONSIBLES: {
			return {
				...state,
				pieceWorkResponsibles: action.pieceWorkResponsibles,
				isLoading: false,
			};
		}

		case pieceWorkConstants.EDITPIECEWORKRESPONSIBLESMODAL: {
			return {
				...state,
				showEditPieceWorkResponsiblesModal: !state.showEditPieceWorkResponsiblesModal,
			};
		}

		case pieceWorkConstants.SAVEPIECEWORKRESPONSIBLES: {
			return {
				...state,
				isLoading: true
			};
		}

		case pieceWorkConstants.SAVEDPIECEWORKRESPONSIBLES: {
			return {
				...state,
				isLoading: false
			};
		}

		case pieceWorkConstants.REMOVE_PIECEWORK_RESPONSIBLES: {
			return {
				...state,
				isLoading: true
			};
		}

		case pieceWorkConstants.SAVECONTACTPERSON: {
			return {
				...state,
				isLoading: true
			};
		}
		case pieceWorkConstants.SAVEDCONTACTPERSON: {
			return {
				...state,
				isLoading: false
			};
		}
		case pieceWorkConstants.GET_PIECEWORK_CALCULATION: {
			return {
				...state,
				isLoadingCalculation: true
			};
		}
		case pieceWorkConstants.SET_PIECEWORK_CALCULATION: {
			return {
				...state,
				pieceWorkCalculation: action.payload,
				isLoadingCalculation: false
			};
		}
		case pieceWorkConstants.SET_PIECEWORK_CALCULATION_ROWS: {
			return {
				...state,
				pieceWorkCalculationRows: action.payload.map(row => {
					row.name = `${row.employeeFirstName} ${row.employeeLastName}`;
					row.totalTime = row.daytimeHours + row.pieceworkHours + row.travelHours;
					return row;
				}),
				isLoading: false
			};
		}
		case pieceWorkConstants.SAVE_PIECEWORK_CALCULATION: {
			return {
				...state,
				isLoadingCalculation: true
			};
		}
		case pieceWorkConstants.SAVED_PIECEWORK_CALCULATION: {
			return {
				...state,
				isLoadingCalculation: false
			};
		}

		case pieceWorkConstants.SHOW_SHARE_MODAL: {
			return {
				...state,
				showShareModal: !state.showShareModal,
			};
		}

		case pieceWorkConstants.SET_SHARED_TO: {
			return {
				...state,
				sharedTo: action.payload,
			};
		}
		case pieceWorkConstants.TOGGLE_SHARED_TO_FEDERATION_ROLE: {
			return {
				...state,
				toFederationResponsible: action.payload
			}
		}

		case pieceWorkConstants.SET_META_DATA_SELECTS: {
			return {
				...state,
				metaDataSelects: action.payload,
				isLoading: false
			};
		}

		case pieceWorkConstants.CHECKLIST_DONE: {
			return {
				...state,
				checkListDone: action.payload
			}
		}

		case pieceWorkConstants.CHECKLIST_FORM_DONE: {
			return {
				...state,
				checkListFormDone: action.payload
			}
		}

		case pieceWorkConstants.ON_PIECEWORK_ERROR: {
			return {
				...state,
				isLoading: false
			}
		}

		case pieceWorkConstants.FILTER_COMPLETED_PIECEWORK_LIST: {
			return {
				...state,
				filterCompletedPieceWorks: action.payload
			}
		}

		case pieceWorkConstants.SET_COMPLETED_PIECEWORK_FILTER_BY: {
			return {
				...state,
				filterCompletedPieceWorksBy: action.payload
			}
		}

		default:
			return state;
	}
};

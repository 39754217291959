export const A0507Types = {
    all: 'all',
    zeroFive: 5,
    zeroSeven: 7,
}

export const A0507TypeTranslations = {
    all: 'Alle avtaler',
    5: '05',
    7: '07'
}

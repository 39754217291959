import * as api from 'api/Api';
import { commonService } from 'store/services/CommonService';

export const timeRegistrationService = {
	getWorkersByPieceWork,
	getWeeks,
	getTimeForWorkers,
	//getTimeForAllWorkers,
	deleteTimeRegistration,
	addTimeRegistration,
	getTimeSummary
}

function getWorkersByPieceWork(workId) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getWorkersByPieceWorkUrl(workId);
	return fetch(url, opt).then(commonService.handleResponse);
}


function getWeeks(fromDate, toDate, workId, numberOfWeeks = null) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getWeeksUrl(fromDate, toDate, workId, numberOfWeeks);
	return fetch(url, opt).then(commonService.handleResponse);
}


async function getTimeForWorkers(workerIds = [], year = '', week = '', workId) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getTimeForWorkersUrl(workerIds, year, week, workId);
	return await fetch(url, opt).then(commonService.handleResponse);
}
/*
function getTimeForAllWorkers(pieceWorkId, year, week) {
	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getTimeForAllWorkersUrl(pieceWorkId, year, week);
	return fetch(url, opt).then(commonService.handleResponse);
}
*/

async function deleteTimeRegistration(id) {
	const opt = {
		method: 'DELETE',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getDeleteTimeRegistrationByIdUrl(id);
	return await fetch(url, opt).then(commonService.handleResponse);
}

async function addTimeRegistration(pieceWorkId, timeObject) {
	const opt = {
		method: 'PATCH',
		headers: api.authHeader(),
		body: JSON.stringify(timeObject)
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getAddTimeRegistrationUrl(pieceWorkId);
	return await fetch(url, opt).then(commonService.handleResponse);
}

function getTimeSummary(pieceWorkId, fromYear, fromWeek, toYear, toWeek) {

	if (typeof fromYear === 'undefined') fromYear = '';
	if (typeof fromWeek === 'undefined') fromWeek = '';
	if (typeof toYear === 'undefined') toYear = '';
	if (typeof toWeek === 'undefined') toWeek = '';

	const opt = {
		method: 'GET',
		headers: api.authHeader()
	};
	opt.headers = api.addJsonHeader(opt.headers);
	const url = api.getTimeSummaryUrl(pieceWorkId, fromYear, fromWeek, toYear, toWeek);
	return fetch(url, opt).then(commonService.handleResponse);
}

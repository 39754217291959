import React from 'react';
import { SnackbarContext } from './SnackbarContext';

function withSnackbar() {
	return (Component) => {
		const snackBarComponent = (props, { snackbar }) => (
			<SnackbarContext.Consumer>
				{(context) => <Component snackbar={context.snackbar} {...props} />}
			</SnackbarContext.Consumer>
		);
		return snackBarComponent;
	};
}

export default withSnackbar

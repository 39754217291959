import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import ShareIcon from '@material-ui/icons/Share';

const styles = (theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.text.grey,
    },
    text: {
        marginLeft: theme.spacing(0.5),
        textTransform: 'uppercase'
    }
});

function ShareInfo(props) {
    const { classes, style } = props;

    const text = 'DELT'


    return (<>
        <div
            className={classes.container}
            style={style}
        >
            <ShareIcon
                color="primary"
                fontSize="inherit"
            />
            <Typography
                variant="subtitle1"
                component="label"
                color="primary"
                className={classes.text}
            >
                {text}
            </Typography>
        </div>
    </>);
}

export default connect(state => state.User)(withStyles(styles)(ShareInfo));

import * as api from 'api/Api';
import { commonService } from 'store/services/CommonService';
import ReactDOMServer from 'react-dom/server';
import { ReportFooterWithPages } from 'components/reports/components/ReportFooter';

function getCalculationByPieceWorkId(workId) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);

  const url = api.getCalculationByPieceWorkIdUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

function getCalculationNumberOfPages(distributionId, distributionType) {
  const opt = {
    method: 'GET',
    headers: api.authHeader(),
  };
  opt.headers = api.addJsonHeader(opt.headers);

  const url = api.getCalcutionNumberOfPagesUrl(
    distributionId,
    distributionType
  );
  return fetch(url, opt).then(commonService.handleResponse);
}

function getCalculationReportPages(calculationReport, workId) {
  const convertedHtml = ReactDOMServer.renderToStaticMarkup(calculationReport);
  const footerWithPages = ReportFooterWithPages.footer;

  const opt = {
    method: 'POST',
    headers: api.authHeader(),
    body: JSON.stringify({
      html: convertedHtml,
      options: {
        landscape: true,
        displayHeaderFooter: true,
        footerTemplate: footerWithPages,
        marginTop: 0.8,
        marginBottom: 1,
        marginLeft: 0.4,
        marginRight: 0.4,
        printBackground: true,
      },
    }),
  };
  opt.headers = api.addJsonHeader(opt.headers);

  const url = api.getCalculationReportPagesUrl(workId);
  return fetch(url, opt).then(commonService.handleResponse);
}

// function saveCalculationToPieceWork(workId) {
// 	const opt = {
// 		method: 'POST',
// 		headers: api.authHeader()
// 	};
// 	opt.headers = api.addJsonHeader(opt.headers);

// 	const url = api.getSaveCalculationToPieceWorkUrl(workId);
// 	return fetch(url, opt).then(commonService.handleResponse);
// }

export const calculationService = {
  getCalculationByPieceWorkId,
  // saveCalculationToPieceWork,
  getCalculationReportPages,
  getCalculationNumberOfPages,
};

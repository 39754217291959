import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmailIcon from '@material-ui/icons/Email';

import { userConstants } from 'store/constants/UserConstants';
import { userService } from 'store/services/UserService';
import { snackbarConstants } from 'store/constants/SnackbarConstants';
import Tooltip from '@material-ui/core/Tooltip';

const EmailSetting = (props) => {
    const { dispatch,
        userId,
        labelClass,
        label,
        notificationType = "",
        User } = props;
    const [values, setValues] = useState({ emailNotification: false });

    const handleCheckboxChange = async (event) => {
        event.persist();
        setValues((values) => ({ ...values, [event.target.name]: event.target.checked }));

        try {
            await userService.updateEmailSetting(event.target.checked, notificationType);
            if (notificationType === "suggestion") {
                dispatch({ type: userConstants.SET_USER_EMAIL_SUGGESTION_NOTIFICATION, payload: event.target.checked });
            } else if (notificationType === "message") {
                dispatch({ type: userConstants.SET_USER_EMAIL_NOTIFICATION, payload: event.target.checked });
            }

            dispatch({ type: snackbarConstants.ALERT_SUCCESS, payload: { message: 'Lagret' } });
        }
        catch (e) {
            dispatch({
                type: snackbarConstants.ALERT_ERROR,
                payload: { message: e }
            });
            return false;
        }

    };

    const setValuesCb = useCallback(
        (value) => {
            setValues({ emailNotification: value });
        }, []
    )

    useEffect(() => {
        const getUserSetting = async () => {
            try {
                const list = await userService.getUserSettings(userId);

                dispatch({ type: userConstants.SET_USER_DATA, payLoad: list.data[0] });
                if (notificationType === "suggestion") {
                    setValuesCb(list.data[0].receiveSuggestionNotifications)
                } else if (notificationType === "message") {
                    setValuesCb(list.data[0].receiveMessageNotifications)
                }
            }
            catch (e) {
                dispatch({
                    type: snackbarConstants.ALERT_ERROR,
                    payload: { message: e }
                });
                return false;
            }
        }
        if (userId) getUserSetting();
    }, [dispatch, userId, setValuesCb, notificationType])

    useEffect(() => {
        if (notificationType === "suggestion") {
            setValuesCb(User["receiveSuggestionNotifications"]);
        } else if (notificationType === "message") {
            setValuesCb(User["receiveMessageNotifications"]);
        }
    }, [User, notificationType, setValuesCb])

    return (
        <Tooltip title="Klikk for å aktivere / deaktivere" placement="bottom-start" arrow>
            <FormControlLabel
                control={
                    <Checkbox
                        id="emailNotification"
                        name="emailNotification"
                        value="emailNotification"
                        checked={values.emailNotification || false}
                        style={{ marginLeft: '4px' }}
                        onChange={handleCheckboxChange}
                        icon={<MailOutlineIcon fontSize="small" />}
                        checkedIcon={<EmailIcon fontSize="small" />}
                    />
                }
                label={label ? label : "E-postvarsel"}
                classes={{
                    label: labelClass
                }}
            />
        </Tooltip>
    )

}

const mapStateToProps = (state) => {
    const { UserInfo, User } = state.User;
    return {
        userId: UserInfo.id,
        User
    }
}

export default connect(mapStateToProps)(EmailSetting);